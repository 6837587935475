/* tslint:disable */
/* eslint-disable */
/**
 * API Reference
 * バックエンドAPI仕様書
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';


/**
 * AjaxApi - axios parameter creator
 * @export
 */
export const AjaxApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary ajax/allstar2021_vote/reset_vote
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxAllstar2021VoteResetVotePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/allstar2021_vote/reset_vote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/allstar2021_vote/vote
         * @param {string} [cardIds] 
         * @param {string} [deckId] 
         * @param {string} [voteIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxAllstar2021VoteVotePost: async (cardIds?: string, deckId?: string, voteIndex?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/allstar2021_vote/vote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (cardIds !== undefined) { 
                localVarFormParams.append('card_ids', cardIds as any);
            }
    
            if (deckId !== undefined) { 
                localVarFormParams.append('deck_id', deckId as any);
            }
    
            if (voteIndex !== undefined) { 
                localVarFormParams.append('vote_index', voteIndex as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/allstar_vote/reset_vote
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxAllstarVoteResetVotePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/allstar_vote/reset_vote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/allstar_vote/set_read_display_dialog
         * @param {string} [isReadDisplayDialog] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxAllstarVoteSetReadDisplayDialogPost: async (isReadDisplayDialog?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/allstar_vote/set_read_display_dialog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (isReadDisplayDialog !== undefined) { 
                localVarFormParams.append('is_read_display_dialog', isReadDisplayDialog as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/allstar_vote/vote
         * @param {string} [cardIds] 
         * @param {string} [deckId] 
         * @param {string} [voteIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxAllstarVoteVotePost: async (cardIds?: string, deckId?: string, voteIndex?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/allstar_vote/vote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (cardIds !== undefined) { 
                localVarFormParams.append('card_ids', cardIds as any);
            }
    
            if (deckId !== undefined) { 
                localVarFormParams.append('deck_id', deckId as any);
            }
    
            if (voteIndex !== undefined) { 
                localVarFormParams.append('vote_index', voteIndex as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/altair_vote/vote
         * @param {number} [cardIds1] 
         * @param {number} [deckId] 
         * @param {number} [voteIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxAltairVoteVotePost: async (cardIds1?: number, deckId?: number, voteIndex?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/altair_vote/vote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (cardIds1 !== undefined) { 
                localVarFormParams.append('card_ids[1]', cardIds1 as any);
            }
    
            if (deckId !== undefined) { 
                localVarFormParams.append('deck_id', deckId as any);
            }
    
            if (voteIndex !== undefined) { 
                localVarFormParams.append('vote_index', voteIndex as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/auth
         * @param {string} [id] 
         * @param {string} [pw] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxAuthPost: async (id?: string, pw?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (id !== undefined) { 
                localVarFormParams.append('id', id as any);
            }
    
            if (pw !== undefined) { 
                localVarFormParams.append('pw', pw as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/block
         * @param {string} [userId] 
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxBlockPost: async (userId?: string, limit?: string, offset?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/block`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (userId !== undefined) { 
                localVarFormParams.append('user_id', userId as any);
            }
    
            if (limit !== undefined) { 
                localVarFormParams.append('limit', limit as any);
            }
    
            if (offset !== undefined) { 
                localVarFormParams.append('offset', offset as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/card_series_vote/vote
         * @param {string} [cardSeriesId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxCardSeriesVoteVotePost: async (cardSeriesId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/card_series_vote/vote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (cardSeriesId !== undefined) { 
                localVarFormParams.append('card_series_id', cardSeriesId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/check_purchasable
         * @param {string} [purchaseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxCheckPurchasablePost: async (purchaseId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/check_purchasable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (purchaseId !== undefined) { 
                localVarFormParams.append('purchase_id', purchaseId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/debug_terminal/replace
         * @param {string} [debugFlg] 0&#x3D;disable, 1&#x3D;enabled
         * @param {string} [debugDate] travel time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxDebugTerminalReplacePost: async (debugFlg?: string, debugDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/debug_terminal/replace`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (debugFlg !== undefined) { 
                localVarFormParams.append('debug_flg', debugFlg as any);
            }
    
            if (debugDate !== undefined) { 
                localVarFormParams.append('debug_date', debugDate as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/dreamteam/set_deck
         * @param {string} [deckName] 
         * @param {string} [deckId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxDreamteamSetDeckPost: async (deckName?: string, deckId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/dreamteam/set_deck`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (deckName !== undefined) { 
                localVarFormParams.append('deck_name', deckName as any);
            }
    
            if (deckId !== undefined) { 
                localVarFormParams.append('deck_id', deckId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/dreamteam/set
         * @param {string} [cardId] 
         * @param {string} [orderNo] 
         * @param {string} [deckId] 
         * @param {string} [checkMission] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxDreamteamSetPost: async (cardId?: string, orderNo?: string, deckId?: string, checkMission?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/dreamteam/set`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (cardId !== undefined) { 
                localVarFormParams.append('card_id', cardId as any);
            }
    
            if (orderNo !== undefined) { 
                localVarFormParams.append('order_no', orderNo as any);
            }
    
            if (deckId !== undefined) { 
                localVarFormParams.append('deck_id', deckId as any);
            }
    
            if (checkMission !== undefined) { 
                localVarFormParams.append('check_mission', checkMission as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/dreamteam_vote/vote
         * @param {number} [cardIds1] 
         * @param {number} [cardIds2] 
         * @param {number} [deckId] 
         * @param {number} [voteIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxDreamteamVoteVotePost: async (cardIds1?: number, cardIds2?: number, deckId?: number, voteIndex?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/dreamteam_vote/vote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (cardIds1 !== undefined) { 
                localVarFormParams.append('card_ids[1][]', cardIds1 as any);
            }
    
            if (cardIds2 !== undefined) { 
                localVarFormParams.append('card_ids[2][]', cardIds2 as any);
            }
    
            if (deckId !== undefined) { 
                localVarFormParams.append('deck_id', deckId as any);
            }
    
            if (voteIndex !== undefined) { 
                localVarFormParams.append('vote_index', voteIndex as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/mascot_vote/vote
         * @param {number} [cardIds] 
         * @param {number} [deckId] 
         * @param {number} [voteIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxMascotVoteVotePost: async (cardIds?: number, deckId?: number, voteIndex?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/mascot_vote/vote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (cardIds !== undefined) { 
                localVarFormParams.append('card_ids', cardIds as any);
            }
    
            if (deckId !== undefined) { 
                localVarFormParams.append('deck_id', deckId as any);
            }
    
            if (voteIndex !== undefined) { 
                localVarFormParams.append('vote_index', voteIndex as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/minimail/block
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxMinimailBlockPost: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/minimail/block`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (id !== undefined) { 
                localVarFormParams.append('id', id as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/minimail/pull/(:any)
         * @param {string} [uid] 
         * @param {string} [messageId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxMinimailPullAnyPost: async (uid?: string, messageId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/minimail/pull/(:any)`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (uid !== undefined) { 
                localVarFormParams.append('uid', uid as any);
            }
    
            if (messageId !== undefined) { 
                localVarFormParams.append('message_id', messageId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/notice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxNoticePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/notice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax_notice_set_my_club
         * @param {string} [param] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxNoticeSetMyClubPost: async (param?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax_notice_set_my_club`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (param !== undefined) { 
                localVarFormParams.append('param', param as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax_pull_pointhistory
         * @param {string} [lastPointId] 
         * @param {string} [lastId] 
         * @param {string} [intDown] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxPullPointhistoryPost: async (lastPointId?: string, lastId?: string, intDown?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax_pull_pointhistory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (lastPointId !== undefined) { 
                localVarFormParams.append('last_point_id', lastPointId as any);
            }
    
            if (lastId !== undefined) { 
                localVarFormParams.append('last_id', lastId as any);
            }
    
            if (intDown !== undefined) { 
                localVarFormParams.append('int_down', intDown as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax_pull_user_notice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxPullUserNoticePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax_pull_user_notice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/purchase_items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxPurchaseItemsPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/purchase_items`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax_push_card_new_read
         * @param {string} [cardId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxPushCardNewReadPost: async (cardId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax_push_card_new_read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (cardId !== undefined) { 
                localVarFormParams.append('card_id', cardId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax_push_oshirase_ok
         * @param {string} [oshiraseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxPushOshiraseOkPost: async (oshiraseId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax_push_oshirase_ok`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (oshiraseId !== undefined) { 
                localVarFormParams.append('oshirase_id', oshiraseId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax_push_oshirase_read
         * @param {string} [oshiraseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxPushOshiraseReadPost: async (oshiraseId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax_push_oshirase_read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (oshiraseId !== undefined) { 
                localVarFormParams.append('oshirase_id', oshiraseId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax_push_user_notice_read
         * @param {string} [userNoticeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxPushUserNoticeReadPost: async (userNoticeId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax_push_user_notice_read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (userNoticeId !== undefined) { 
                localVarFormParams.append('user_notice_id', userNoticeId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax_push_user_oshirases_read
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxPushUserOshirasesReadPost: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax_push_user_oshirases_read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (id !== undefined) { 
                localVarFormParams.append('id', id as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax_save_having_filter
         * @param {string} [value] 
         * @param {string} [scene] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxSaveHavingFilterPost: async (value?: string, scene?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax_save_having_filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (value !== undefined) { 
                localVarFormParams.append('value', value as any);
            }
    
            if (scene !== undefined) { 
                localVarFormParams.append('scene', scene as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax_save_holder_sort
         * @param {string} [value] 
         * @param {string} [scene] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxSaveHolderSortPost: async (value?: string, scene?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax_save_holder_sort`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (value !== undefined) { 
                localVarFormParams.append('value', value as any);
            }
    
            if (scene !== undefined) { 
                localVarFormParams.append('scene', scene as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/search_uid
         * @param {string} [kwd] 
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxSearchUidPost: async (kwd?: string, limit?: string, offset?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/search_uid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (kwd !== undefined) { 
                localVarFormParams.append('kwd', kwd as any);
            }
    
            if (limit !== undefined) { 
                localVarFormParams.append('limit', limit as any);
            }
    
            if (offset !== undefined) { 
                localVarFormParams.append('offset', offset as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax_set_is_addhome
         * @param {string} [isAddhome] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxSetIsAddhomePost: async (isAddhome?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax_set_is_addhome`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (isAddhome !== undefined) { 
                localVarFormParams.append('is_addhome', isAddhome as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax_show_addhome_notice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxShowAddhomeNoticePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax_show_addhome_notice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/valentine2021_vote/vote
         * @param {string} [cardIds0] 
         * @param {number} [cardIds1] 
         * @param {number} [deckId] 
         * @param {number} [voteIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxValentine2021VoteVotePost: async (cardIds0?: string, cardIds1?: number, deckId?: number, voteIndex?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/valentine2021_vote/vote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (cardIds0 !== undefined) { 
                localVarFormParams.append('card_ids[0]', cardIds0 as any);
            }
    
            if (cardIds1 !== undefined) { 
                localVarFormParams.append('card_ids[1]', cardIds1 as any);
            }
    
            if (deckId !== undefined) { 
                localVarFormParams.append('deck_id', deckId as any);
            }
    
            if (voteIndex !== undefined) { 
                localVarFormParams.append('vote_index', voteIndex as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/valentine_vote/set_read_display_dialog
         * @param {string} [isReadDisplayDialog] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxValentineVoteSetReadDisplayDialogPost: async (isReadDisplayDialog?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/valentine_vote/set_read_display_dialog`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (isReadDisplayDialog !== undefined) { 
                localVarFormParams.append('is_read_display_dialog', isReadDisplayDialog as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/valentine_vote/vote
         * @param {string} [cardIds] 
         * @param {string} [deckId] 
         * @param {string} [voteIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxValentineVoteVotePost: async (cardIds?: string, deckId?: string, voteIndex?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/valentine_vote/vote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (cardIds !== undefined) { 
                localVarFormParams.append('card_ids', cardIds as any);
            }
    
            if (deckId !== undefined) { 
                localVarFormParams.append('deck_id', deckId as any);
            }
    
            if (voteIndex !== undefined) { 
                localVarFormParams.append('vote_index', voteIndex as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/minimail/list
         * @param {number} [userId] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        minimailAjaxListPost: async (userId?: number, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/minimail/ajax_list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (userId !== undefined) { 
                localVarFormParams.append('user_id', userId as any);
            }
    
            if (limit !== undefined) { 
                localVarFormParams.append('limit', limit as any);
            }
    
            if (offset !== undefined) { 
                localVarFormParams.append('offset', offset as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/activities/pull
         * @param {string} [type] 
         * @param {string} [lastId] 
         * @param {string} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAjaxActivitiesPullPost: async (type?: string, lastId?: string, limit?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/ajax_activities_pull`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (type !== undefined) { 
                localVarFormParams.append('type', type as any);
            }
    
            if (lastId !== undefined) { 
                localVarFormParams.append('last_id', lastId as any);
            }
    
            if (limit !== undefined) { 
                localVarFormParams.append('limit', limit as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AjaxApi - functional programming interface
 * @export
 */
export const AjaxApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AjaxApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary ajax/allstar2021_vote/reset_vote
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxAllstar2021VoteResetVotePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxAllstar2021VoteResetVotePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/allstar2021_vote/vote
         * @param {string} [cardIds] 
         * @param {string} [deckId] 
         * @param {string} [voteIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxAllstar2021VoteVotePost(cardIds?: string, deckId?: string, voteIndex?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxAllstar2021VoteVotePost(cardIds, deckId, voteIndex, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/allstar_vote/reset_vote
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxAllstarVoteResetVotePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxAllstarVoteResetVotePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/allstar_vote/set_read_display_dialog
         * @param {string} [isReadDisplayDialog] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxAllstarVoteSetReadDisplayDialogPost(isReadDisplayDialog?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxAllstarVoteSetReadDisplayDialogPost(isReadDisplayDialog, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/allstar_vote/vote
         * @param {string} [cardIds] 
         * @param {string} [deckId] 
         * @param {string} [voteIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxAllstarVoteVotePost(cardIds?: string, deckId?: string, voteIndex?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxAllstarVoteVotePost(cardIds, deckId, voteIndex, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/altair_vote/vote
         * @param {number} [cardIds1] 
         * @param {number} [deckId] 
         * @param {number} [voteIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxAltairVoteVotePost(cardIds1?: number, deckId?: number, voteIndex?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxAltairVoteVotePost(cardIds1, deckId, voteIndex, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/auth
         * @param {string} [id] 
         * @param {string} [pw] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxAuthPost(id?: string, pw?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxAuthPost(id, pw, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/block
         * @param {string} [userId] 
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxBlockPost(userId?: string, limit?: string, offset?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxBlockPost(userId, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/card_series_vote/vote
         * @param {string} [cardSeriesId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxCardSeriesVoteVotePost(cardSeriesId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxCardSeriesVoteVotePost(cardSeriesId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/check_purchasable
         * @param {string} [purchaseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxCheckPurchasablePost(purchaseId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxCheckPurchasablePost(purchaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/debug_terminal/replace
         * @param {string} [debugFlg] 0&#x3D;disable, 1&#x3D;enabled
         * @param {string} [debugDate] travel time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxDebugTerminalReplacePost(debugFlg?: string, debugDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxDebugTerminalReplacePost(debugFlg, debugDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/dreamteam/set_deck
         * @param {string} [deckName] 
         * @param {string} [deckId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxDreamteamSetDeckPost(deckName?: string, deckId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxDreamteamSetDeckPost(deckName, deckId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/dreamteam/set
         * @param {string} [cardId] 
         * @param {string} [orderNo] 
         * @param {string} [deckId] 
         * @param {string} [checkMission] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxDreamteamSetPost(cardId?: string, orderNo?: string, deckId?: string, checkMission?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxDreamteamSetPost(cardId, orderNo, deckId, checkMission, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/dreamteam_vote/vote
         * @param {number} [cardIds1] 
         * @param {number} [cardIds2] 
         * @param {number} [deckId] 
         * @param {number} [voteIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxDreamteamVoteVotePost(cardIds1?: number, cardIds2?: number, deckId?: number, voteIndex?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxDreamteamVoteVotePost(cardIds1, cardIds2, deckId, voteIndex, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/mascot_vote/vote
         * @param {number} [cardIds] 
         * @param {number} [deckId] 
         * @param {number} [voteIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxMascotVoteVotePost(cardIds?: number, deckId?: number, voteIndex?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxMascotVoteVotePost(cardIds, deckId, voteIndex, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/minimail/block
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxMinimailBlockPost(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxMinimailBlockPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/minimail/pull/(:any)
         * @param {string} [uid] 
         * @param {string} [messageId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxMinimailPullAnyPost(uid?: string, messageId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxMinimailPullAnyPost(uid, messageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/notice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxNoticePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxNoticePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax_notice_set_my_club
         * @param {string} [param] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxNoticeSetMyClubPost(param?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxNoticeSetMyClubPost(param, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax_pull_pointhistory
         * @param {string} [lastPointId] 
         * @param {string} [lastId] 
         * @param {string} [intDown] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxPullPointhistoryPost(lastPointId?: string, lastId?: string, intDown?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxPullPointhistoryPost(lastPointId, lastId, intDown, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax_pull_user_notice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxPullUserNoticePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxPullUserNoticePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/purchase_items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxPurchaseItemsPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxPurchaseItemsPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax_push_card_new_read
         * @param {string} [cardId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxPushCardNewReadPost(cardId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxPushCardNewReadPost(cardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax_push_oshirase_ok
         * @param {string} [oshiraseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxPushOshiraseOkPost(oshiraseId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxPushOshiraseOkPost(oshiraseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax_push_oshirase_read
         * @param {string} [oshiraseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxPushOshiraseReadPost(oshiraseId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxPushOshiraseReadPost(oshiraseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax_push_user_notice_read
         * @param {string} [userNoticeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxPushUserNoticeReadPost(userNoticeId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxPushUserNoticeReadPost(userNoticeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax_push_user_oshirases_read
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxPushUserOshirasesReadPost(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxPushUserOshirasesReadPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax_save_having_filter
         * @param {string} [value] 
         * @param {string} [scene] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxSaveHavingFilterPost(value?: string, scene?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxSaveHavingFilterPost(value, scene, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax_save_holder_sort
         * @param {string} [value] 
         * @param {string} [scene] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxSaveHolderSortPost(value?: string, scene?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxSaveHolderSortPost(value, scene, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/search_uid
         * @param {string} [kwd] 
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxSearchUidPost(kwd?: string, limit?: string, offset?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxSearchUidPost(kwd, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax_set_is_addhome
         * @param {string} [isAddhome] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxSetIsAddhomePost(isAddhome?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxSetIsAddhomePost(isAddhome, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax_show_addhome_notice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxShowAddhomeNoticePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxShowAddhomeNoticePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/valentine2021_vote/vote
         * @param {string} [cardIds0] 
         * @param {number} [cardIds1] 
         * @param {number} [deckId] 
         * @param {number} [voteIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxValentine2021VoteVotePost(cardIds0?: string, cardIds1?: number, deckId?: number, voteIndex?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxValentine2021VoteVotePost(cardIds0, cardIds1, deckId, voteIndex, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/valentine_vote/set_read_display_dialog
         * @param {string} [isReadDisplayDialog] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxValentineVoteSetReadDisplayDialogPost(isReadDisplayDialog?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxValentineVoteSetReadDisplayDialogPost(isReadDisplayDialog, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/valentine_vote/vote
         * @param {string} [cardIds] 
         * @param {string} [deckId] 
         * @param {string} [voteIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxValentineVoteVotePost(cardIds?: string, deckId?: string, voteIndex?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxValentineVoteVotePost(cardIds, deckId, voteIndex, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/minimail/list
         * @param {number} [userId] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async minimailAjaxListPost(userId?: number, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.minimailAjaxListPost(userId, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/activities/pull
         * @param {string} [type] 
         * @param {string} [lastId] 
         * @param {string} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAjaxActivitiesPullPost(type?: string, lastId?: string, limit?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAjaxActivitiesPullPost(type, lastId, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AjaxApi - factory interface
 * @export
 */
export const AjaxApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AjaxApiFp(configuration)
    return {
        /**
         * 
         * @summary ajax/allstar2021_vote/reset_vote
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxAllstar2021VoteResetVotePost(options?: any): AxiosPromise<void> {
            return localVarFp.ajaxAllstar2021VoteResetVotePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/allstar2021_vote/vote
         * @param {string} [cardIds] 
         * @param {string} [deckId] 
         * @param {string} [voteIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxAllstar2021VoteVotePost(cardIds?: string, deckId?: string, voteIndex?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxAllstar2021VoteVotePost(cardIds, deckId, voteIndex, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/allstar_vote/reset_vote
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxAllstarVoteResetVotePost(options?: any): AxiosPromise<void> {
            return localVarFp.ajaxAllstarVoteResetVotePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/allstar_vote/set_read_display_dialog
         * @param {string} [isReadDisplayDialog] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxAllstarVoteSetReadDisplayDialogPost(isReadDisplayDialog?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxAllstarVoteSetReadDisplayDialogPost(isReadDisplayDialog, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/allstar_vote/vote
         * @param {string} [cardIds] 
         * @param {string} [deckId] 
         * @param {string} [voteIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxAllstarVoteVotePost(cardIds?: string, deckId?: string, voteIndex?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxAllstarVoteVotePost(cardIds, deckId, voteIndex, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/altair_vote/vote
         * @param {number} [cardIds1] 
         * @param {number} [deckId] 
         * @param {number} [voteIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxAltairVoteVotePost(cardIds1?: number, deckId?: number, voteIndex?: number, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxAltairVoteVotePost(cardIds1, deckId, voteIndex, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/auth
         * @param {string} [id] 
         * @param {string} [pw] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxAuthPost(id?: string, pw?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxAuthPost(id, pw, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/block
         * @param {string} [userId] 
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxBlockPost(userId?: string, limit?: string, offset?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxBlockPost(userId, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/card_series_vote/vote
         * @param {string} [cardSeriesId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxCardSeriesVoteVotePost(cardSeriesId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxCardSeriesVoteVotePost(cardSeriesId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/check_purchasable
         * @param {string} [purchaseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxCheckPurchasablePost(purchaseId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxCheckPurchasablePost(purchaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/debug_terminal/replace
         * @param {string} [debugFlg] 0&#x3D;disable, 1&#x3D;enabled
         * @param {string} [debugDate] travel time
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxDebugTerminalReplacePost(debugFlg?: string, debugDate?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxDebugTerminalReplacePost(debugFlg, debugDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/dreamteam/set_deck
         * @param {string} [deckName] 
         * @param {string} [deckId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxDreamteamSetDeckPost(deckName?: string, deckId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxDreamteamSetDeckPost(deckName, deckId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/dreamteam/set
         * @param {string} [cardId] 
         * @param {string} [orderNo] 
         * @param {string} [deckId] 
         * @param {string} [checkMission] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxDreamteamSetPost(cardId?: string, orderNo?: string, deckId?: string, checkMission?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxDreamteamSetPost(cardId, orderNo, deckId, checkMission, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/dreamteam_vote/vote
         * @param {number} [cardIds1] 
         * @param {number} [cardIds2] 
         * @param {number} [deckId] 
         * @param {number} [voteIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxDreamteamVoteVotePost(cardIds1?: number, cardIds2?: number, deckId?: number, voteIndex?: number, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxDreamteamVoteVotePost(cardIds1, cardIds2, deckId, voteIndex, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/mascot_vote/vote
         * @param {number} [cardIds] 
         * @param {number} [deckId] 
         * @param {number} [voteIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxMascotVoteVotePost(cardIds?: number, deckId?: number, voteIndex?: number, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxMascotVoteVotePost(cardIds, deckId, voteIndex, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/minimail/block
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxMinimailBlockPost(id?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxMinimailBlockPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/minimail/pull/(:any)
         * @param {string} [uid] 
         * @param {string} [messageId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxMinimailPullAnyPost(uid?: string, messageId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxMinimailPullAnyPost(uid, messageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/notice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxNoticePost(options?: any): AxiosPromise<void> {
            return localVarFp.ajaxNoticePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax_notice_set_my_club
         * @param {string} [param] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxNoticeSetMyClubPost(param?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxNoticeSetMyClubPost(param, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax_pull_pointhistory
         * @param {string} [lastPointId] 
         * @param {string} [lastId] 
         * @param {string} [intDown] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxPullPointhistoryPost(lastPointId?: string, lastId?: string, intDown?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxPullPointhistoryPost(lastPointId, lastId, intDown, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax_pull_user_notice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxPullUserNoticePost(options?: any): AxiosPromise<void> {
            return localVarFp.ajaxPullUserNoticePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/purchase_items
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxPurchaseItemsPost(options?: any): AxiosPromise<void> {
            return localVarFp.ajaxPurchaseItemsPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax_push_card_new_read
         * @param {string} [cardId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxPushCardNewReadPost(cardId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxPushCardNewReadPost(cardId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax_push_oshirase_ok
         * @param {string} [oshiraseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxPushOshiraseOkPost(oshiraseId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxPushOshiraseOkPost(oshiraseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax_push_oshirase_read
         * @param {string} [oshiraseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxPushOshiraseReadPost(oshiraseId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxPushOshiraseReadPost(oshiraseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax_push_user_notice_read
         * @param {string} [userNoticeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxPushUserNoticeReadPost(userNoticeId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxPushUserNoticeReadPost(userNoticeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax_push_user_oshirases_read
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxPushUserOshirasesReadPost(id?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxPushUserOshirasesReadPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax_save_having_filter
         * @param {string} [value] 
         * @param {string} [scene] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxSaveHavingFilterPost(value?: string, scene?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxSaveHavingFilterPost(value, scene, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax_save_holder_sort
         * @param {string} [value] 
         * @param {string} [scene] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxSaveHolderSortPost(value?: string, scene?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxSaveHolderSortPost(value, scene, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/search_uid
         * @param {string} [kwd] 
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxSearchUidPost(kwd?: string, limit?: string, offset?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxSearchUidPost(kwd, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax_set_is_addhome
         * @param {string} [isAddhome] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxSetIsAddhomePost(isAddhome?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxSetIsAddhomePost(isAddhome, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax_show_addhome_notice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxShowAddhomeNoticePost(options?: any): AxiosPromise<void> {
            return localVarFp.ajaxShowAddhomeNoticePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/valentine2021_vote/vote
         * @param {string} [cardIds0] 
         * @param {number} [cardIds1] 
         * @param {number} [deckId] 
         * @param {number} [voteIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxValentine2021VoteVotePost(cardIds0?: string, cardIds1?: number, deckId?: number, voteIndex?: number, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxValentine2021VoteVotePost(cardIds0, cardIds1, deckId, voteIndex, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/valentine_vote/set_read_display_dialog
         * @param {string} [isReadDisplayDialog] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxValentineVoteSetReadDisplayDialogPost(isReadDisplayDialog?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxValentineVoteSetReadDisplayDialogPost(isReadDisplayDialog, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/valentine_vote/vote
         * @param {string} [cardIds] 
         * @param {string} [deckId] 
         * @param {string} [voteIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxValentineVoteVotePost(cardIds?: string, deckId?: string, voteIndex?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxValentineVoteVotePost(cardIds, deckId, voteIndex, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/minimail/list
         * @param {number} [userId] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        minimailAjaxListPost(userId?: number, limit?: number, offset?: number, options?: any): AxiosPromise<void> {
            return localVarFp.minimailAjaxListPost(userId, limit, offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/activities/pull
         * @param {string} [type] 
         * @param {string} [lastId] 
         * @param {string} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAjaxActivitiesPullPost(type?: string, lastId?: string, limit?: string, options?: any): AxiosPromise<void> {
            return localVarFp.userAjaxActivitiesPullPost(type, lastId, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AjaxApi - object-oriented interface
 * @export
 * @class AjaxApi
 * @extends {BaseAPI}
 */
export class AjaxApi extends BaseAPI {
    /**
     * 
     * @summary ajax/allstar2021_vote/reset_vote
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AjaxApi
     */
    public ajaxAllstar2021VoteResetVotePost(options?: AxiosRequestConfig) {
        return AjaxApiFp(this.configuration).ajaxAllstar2021VoteResetVotePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/allstar2021_vote/vote
     * @param {string} [cardIds] 
     * @param {string} [deckId] 
     * @param {string} [voteIndex] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AjaxApi
     */
    public ajaxAllstar2021VoteVotePost(cardIds?: string, deckId?: string, voteIndex?: string, options?: AxiosRequestConfig) {
        return AjaxApiFp(this.configuration).ajaxAllstar2021VoteVotePost(cardIds, deckId, voteIndex, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/allstar_vote/reset_vote
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AjaxApi
     */
    public ajaxAllstarVoteResetVotePost(options?: AxiosRequestConfig) {
        return AjaxApiFp(this.configuration).ajaxAllstarVoteResetVotePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/allstar_vote/set_read_display_dialog
     * @param {string} [isReadDisplayDialog] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AjaxApi
     */
    public ajaxAllstarVoteSetReadDisplayDialogPost(isReadDisplayDialog?: string, options?: AxiosRequestConfig) {
        return AjaxApiFp(this.configuration).ajaxAllstarVoteSetReadDisplayDialogPost(isReadDisplayDialog, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/allstar_vote/vote
     * @param {string} [cardIds] 
     * @param {string} [deckId] 
     * @param {string} [voteIndex] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AjaxApi
     */
    public ajaxAllstarVoteVotePost(cardIds?: string, deckId?: string, voteIndex?: string, options?: AxiosRequestConfig) {
        return AjaxApiFp(this.configuration).ajaxAllstarVoteVotePost(cardIds, deckId, voteIndex, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/altair_vote/vote
     * @param {number} [cardIds1] 
     * @param {number} [deckId] 
     * @param {number} [voteIndex] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AjaxApi
     */
    public ajaxAltairVoteVotePost(cardIds1?: number, deckId?: number, voteIndex?: number, options?: AxiosRequestConfig) {
        return AjaxApiFp(this.configuration).ajaxAltairVoteVotePost(cardIds1, deckId, voteIndex, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/auth
     * @param {string} [id] 
     * @param {string} [pw] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AjaxApi
     */
    public ajaxAuthPost(id?: string, pw?: string, options?: AxiosRequestConfig) {
        return AjaxApiFp(this.configuration).ajaxAuthPost(id, pw, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/block
     * @param {string} [userId] 
     * @param {string} [limit] 
     * @param {string} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AjaxApi
     */
    public ajaxBlockPost(userId?: string, limit?: string, offset?: string, options?: AxiosRequestConfig) {
        return AjaxApiFp(this.configuration).ajaxBlockPost(userId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/card_series_vote/vote
     * @param {string} [cardSeriesId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AjaxApi
     */
    public ajaxCardSeriesVoteVotePost(cardSeriesId?: string, options?: AxiosRequestConfig) {
        return AjaxApiFp(this.configuration).ajaxCardSeriesVoteVotePost(cardSeriesId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/check_purchasable
     * @param {string} [purchaseId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AjaxApi
     */
    public ajaxCheckPurchasablePost(purchaseId?: string, options?: AxiosRequestConfig) {
        return AjaxApiFp(this.configuration).ajaxCheckPurchasablePost(purchaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/debug_terminal/replace
     * @param {string} [debugFlg] 0&#x3D;disable, 1&#x3D;enabled
     * @param {string} [debugDate] travel time
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AjaxApi
     */
    public ajaxDebugTerminalReplacePost(debugFlg?: string, debugDate?: string, options?: AxiosRequestConfig) {
        return AjaxApiFp(this.configuration).ajaxDebugTerminalReplacePost(debugFlg, debugDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/dreamteam/set_deck
     * @param {string} [deckName] 
     * @param {string} [deckId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AjaxApi
     */
    public ajaxDreamteamSetDeckPost(deckName?: string, deckId?: string, options?: AxiosRequestConfig) {
        return AjaxApiFp(this.configuration).ajaxDreamteamSetDeckPost(deckName, deckId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/dreamteam/set
     * @param {string} [cardId] 
     * @param {string} [orderNo] 
     * @param {string} [deckId] 
     * @param {string} [checkMission] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AjaxApi
     */
    public ajaxDreamteamSetPost(cardId?: string, orderNo?: string, deckId?: string, checkMission?: string, options?: AxiosRequestConfig) {
        return AjaxApiFp(this.configuration).ajaxDreamteamSetPost(cardId, orderNo, deckId, checkMission, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/dreamteam_vote/vote
     * @param {number} [cardIds1] 
     * @param {number} [cardIds2] 
     * @param {number} [deckId] 
     * @param {number} [voteIndex] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AjaxApi
     */
    public ajaxDreamteamVoteVotePost(cardIds1?: number, cardIds2?: number, deckId?: number, voteIndex?: number, options?: AxiosRequestConfig) {
        return AjaxApiFp(this.configuration).ajaxDreamteamVoteVotePost(cardIds1, cardIds2, deckId, voteIndex, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/mascot_vote/vote
     * @param {number} [cardIds] 
     * @param {number} [deckId] 
     * @param {number} [voteIndex] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AjaxApi
     */
    public ajaxMascotVoteVotePost(cardIds?: number, deckId?: number, voteIndex?: number, options?: AxiosRequestConfig) {
        return AjaxApiFp(this.configuration).ajaxMascotVoteVotePost(cardIds, deckId, voteIndex, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/minimail/block
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AjaxApi
     */
    public ajaxMinimailBlockPost(id?: string, options?: AxiosRequestConfig) {
        return AjaxApiFp(this.configuration).ajaxMinimailBlockPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/minimail/pull/(:any)
     * @param {string} [uid] 
     * @param {string} [messageId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AjaxApi
     */
    public ajaxMinimailPullAnyPost(uid?: string, messageId?: string, options?: AxiosRequestConfig) {
        return AjaxApiFp(this.configuration).ajaxMinimailPullAnyPost(uid, messageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/notice
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AjaxApi
     */
    public ajaxNoticePost(options?: AxiosRequestConfig) {
        return AjaxApiFp(this.configuration).ajaxNoticePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax_notice_set_my_club
     * @param {string} [param] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AjaxApi
     */
    public ajaxNoticeSetMyClubPost(param?: string, options?: AxiosRequestConfig) {
        return AjaxApiFp(this.configuration).ajaxNoticeSetMyClubPost(param, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax_pull_pointhistory
     * @param {string} [lastPointId] 
     * @param {string} [lastId] 
     * @param {string} [intDown] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AjaxApi
     */
    public ajaxPullPointhistoryPost(lastPointId?: string, lastId?: string, intDown?: string, options?: AxiosRequestConfig) {
        return AjaxApiFp(this.configuration).ajaxPullPointhistoryPost(lastPointId, lastId, intDown, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax_pull_user_notice
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AjaxApi
     */
    public ajaxPullUserNoticePost(options?: AxiosRequestConfig) {
        return AjaxApiFp(this.configuration).ajaxPullUserNoticePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/purchase_items
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AjaxApi
     */
    public ajaxPurchaseItemsPost(options?: AxiosRequestConfig) {
        return AjaxApiFp(this.configuration).ajaxPurchaseItemsPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax_push_card_new_read
     * @param {string} [cardId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AjaxApi
     */
    public ajaxPushCardNewReadPost(cardId?: string, options?: AxiosRequestConfig) {
        return AjaxApiFp(this.configuration).ajaxPushCardNewReadPost(cardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax_push_oshirase_ok
     * @param {string} [oshiraseId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AjaxApi
     */
    public ajaxPushOshiraseOkPost(oshiraseId?: string, options?: AxiosRequestConfig) {
        return AjaxApiFp(this.configuration).ajaxPushOshiraseOkPost(oshiraseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax_push_oshirase_read
     * @param {string} [oshiraseId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AjaxApi
     */
    public ajaxPushOshiraseReadPost(oshiraseId?: string, options?: AxiosRequestConfig) {
        return AjaxApiFp(this.configuration).ajaxPushOshiraseReadPost(oshiraseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax_push_user_notice_read
     * @param {string} [userNoticeId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AjaxApi
     */
    public ajaxPushUserNoticeReadPost(userNoticeId?: string, options?: AxiosRequestConfig) {
        return AjaxApiFp(this.configuration).ajaxPushUserNoticeReadPost(userNoticeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax_push_user_oshirases_read
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AjaxApi
     */
    public ajaxPushUserOshirasesReadPost(id?: string, options?: AxiosRequestConfig) {
        return AjaxApiFp(this.configuration).ajaxPushUserOshirasesReadPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax_save_having_filter
     * @param {string} [value] 
     * @param {string} [scene] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AjaxApi
     */
    public ajaxSaveHavingFilterPost(value?: string, scene?: string, options?: AxiosRequestConfig) {
        return AjaxApiFp(this.configuration).ajaxSaveHavingFilterPost(value, scene, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax_save_holder_sort
     * @param {string} [value] 
     * @param {string} [scene] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AjaxApi
     */
    public ajaxSaveHolderSortPost(value?: string, scene?: string, options?: AxiosRequestConfig) {
        return AjaxApiFp(this.configuration).ajaxSaveHolderSortPost(value, scene, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/search_uid
     * @param {string} [kwd] 
     * @param {string} [limit] 
     * @param {string} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AjaxApi
     */
    public ajaxSearchUidPost(kwd?: string, limit?: string, offset?: string, options?: AxiosRequestConfig) {
        return AjaxApiFp(this.configuration).ajaxSearchUidPost(kwd, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax_set_is_addhome
     * @param {string} [isAddhome] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AjaxApi
     */
    public ajaxSetIsAddhomePost(isAddhome?: string, options?: AxiosRequestConfig) {
        return AjaxApiFp(this.configuration).ajaxSetIsAddhomePost(isAddhome, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax_show_addhome_notice
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AjaxApi
     */
    public ajaxShowAddhomeNoticePost(options?: AxiosRequestConfig) {
        return AjaxApiFp(this.configuration).ajaxShowAddhomeNoticePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/valentine2021_vote/vote
     * @param {string} [cardIds0] 
     * @param {number} [cardIds1] 
     * @param {number} [deckId] 
     * @param {number} [voteIndex] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AjaxApi
     */
    public ajaxValentine2021VoteVotePost(cardIds0?: string, cardIds1?: number, deckId?: number, voteIndex?: number, options?: AxiosRequestConfig) {
        return AjaxApiFp(this.configuration).ajaxValentine2021VoteVotePost(cardIds0, cardIds1, deckId, voteIndex, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/valentine_vote/set_read_display_dialog
     * @param {string} [isReadDisplayDialog] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AjaxApi
     */
    public ajaxValentineVoteSetReadDisplayDialogPost(isReadDisplayDialog?: string, options?: AxiosRequestConfig) {
        return AjaxApiFp(this.configuration).ajaxValentineVoteSetReadDisplayDialogPost(isReadDisplayDialog, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/valentine_vote/vote
     * @param {string} [cardIds] 
     * @param {string} [deckId] 
     * @param {string} [voteIndex] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AjaxApi
     */
    public ajaxValentineVoteVotePost(cardIds?: string, deckId?: string, voteIndex?: string, options?: AxiosRequestConfig) {
        return AjaxApiFp(this.configuration).ajaxValentineVoteVotePost(cardIds, deckId, voteIndex, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/minimail/list
     * @param {number} [userId] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AjaxApi
     */
    public minimailAjaxListPost(userId?: number, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return AjaxApiFp(this.configuration).minimailAjaxListPost(userId, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/activities/pull
     * @param {string} [type] 
     * @param {string} [lastId] 
     * @param {string} [limit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AjaxApi
     */
    public userAjaxActivitiesPullPost(type?: string, lastId?: string, limit?: string, options?: AxiosRequestConfig) {
        return AjaxApiFp(this.configuration).userAjaxActivitiesPullPost(type, lastId, limit, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * Allstar2021VoteApi - axios parameter creator
 * @export
 */
export const Allstar2021VoteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary allstar2021_vote/ajax_vote_api
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allstar2021VoteAjaxVoteApiPost: async (body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/allstar2021_vote/ajax_vote_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary allstar2021_vote/index_api
         * @param {string} [targetYear] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allstar2021VoteIndexApiPost: async (targetYear?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/allstar2021_vote/index_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (targetYear !== undefined) {
                localVarQueryParameter['target_year'] = targetYear;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Allstar2021VoteApi - functional programming interface
 * @export
 */
export const Allstar2021VoteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = Allstar2021VoteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary allstar2021_vote/ajax_vote_api
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allstar2021VoteAjaxVoteApiPost(body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allstar2021VoteAjaxVoteApiPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary allstar2021_vote/index_api
         * @param {string} [targetYear] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allstar2021VoteIndexApiPost(targetYear?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allstar2021VoteIndexApiPost(targetYear, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * Allstar2021VoteApi - factory interface
 * @export
 */
export const Allstar2021VoteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = Allstar2021VoteApiFp(configuration)
    return {
        /**
         * 
         * @summary allstar2021_vote/ajax_vote_api
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allstar2021VoteAjaxVoteApiPost(body?: object, options?: any): AxiosPromise<void> {
            return localVarFp.allstar2021VoteAjaxVoteApiPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary allstar2021_vote/index_api
         * @param {string} [targetYear] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allstar2021VoteIndexApiPost(targetYear?: string, options?: any): AxiosPromise<void> {
            return localVarFp.allstar2021VoteIndexApiPost(targetYear, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Allstar2021VoteApi - object-oriented interface
 * @export
 * @class Allstar2021VoteApi
 * @extends {BaseAPI}
 */
export class Allstar2021VoteApi extends BaseAPI {
    /**
     * 
     * @summary allstar2021_vote/ajax_vote_api
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Allstar2021VoteApi
     */
    public allstar2021VoteAjaxVoteApiPost(body?: object, options?: AxiosRequestConfig) {
        return Allstar2021VoteApiFp(this.configuration).allstar2021VoteAjaxVoteApiPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary allstar2021_vote/index_api
     * @param {string} [targetYear] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Allstar2021VoteApi
     */
    public allstar2021VoteIndexApiPost(targetYear?: string, options?: AxiosRequestConfig) {
        return Allstar2021VoteApiFp(this.configuration).allstar2021VoteIndexApiPost(targetYear, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AllstarVoteApi - axios parameter creator
 * @export
 */
export const AllstarVoteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary allstar_vote/index_api
         * @param {string} [targetYear] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allstarVoteIndexApiPost: async (targetYear?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/allstar_vote/index_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (targetYear !== undefined) {
                localVarQueryParameter['target_year'] = targetYear;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AllstarVoteApi - functional programming interface
 * @export
 */
export const AllstarVoteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AllstarVoteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary allstar_vote/index_api
         * @param {string} [targetYear] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allstarVoteIndexApiPost(targetYear?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allstarVoteIndexApiPost(targetYear, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AllstarVoteApi - factory interface
 * @export
 */
export const AllstarVoteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AllstarVoteApiFp(configuration)
    return {
        /**
         * 
         * @summary allstar_vote/index_api
         * @param {string} [targetYear] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allstarVoteIndexApiPost(targetYear?: string, options?: any): AxiosPromise<void> {
            return localVarFp.allstarVoteIndexApiPost(targetYear, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AllstarVoteApi - object-oriented interface
 * @export
 * @class AllstarVoteApi
 * @extends {BaseAPI}
 */
export class AllstarVoteApi extends BaseAPI {
    /**
     * 
     * @summary allstar_vote/index_api
     * @param {string} [targetYear] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllstarVoteApi
     */
    public allstarVoteIndexApiPost(targetYear?: string, options?: AxiosRequestConfig) {
        return AllstarVoteApiFp(this.configuration).allstarVoteIndexApiPost(targetYear, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AltairVoteApi - axios parameter creator
 * @export
 */
export const AltairVoteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary altair_vote/ajax_vote_api
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        altairVoteAjaxVoteApiPost: async (body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/altair_vote/ajax_vote_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary altair_vote/index_api
         * @param {string} [targetYear] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        altairVoteIndexApiPost: async (targetYear?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/altair_vote/index_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (targetYear !== undefined) {
                localVarQueryParameter['target_year'] = targetYear;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AltairVoteApi - functional programming interface
 * @export
 */
export const AltairVoteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AltairVoteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary altair_vote/ajax_vote_api
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async altairVoteAjaxVoteApiPost(body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.altairVoteAjaxVoteApiPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary altair_vote/index_api
         * @param {string} [targetYear] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async altairVoteIndexApiPost(targetYear?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.altairVoteIndexApiPost(targetYear, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AltairVoteApi - factory interface
 * @export
 */
export const AltairVoteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AltairVoteApiFp(configuration)
    return {
        /**
         * 
         * @summary altair_vote/ajax_vote_api
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        altairVoteAjaxVoteApiPost(body?: object, options?: any): AxiosPromise<void> {
            return localVarFp.altairVoteAjaxVoteApiPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary altair_vote/index_api
         * @param {string} [targetYear] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        altairVoteIndexApiPost(targetYear?: string, options?: any): AxiosPromise<void> {
            return localVarFp.altairVoteIndexApiPost(targetYear, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AltairVoteApi - object-oriented interface
 * @export
 * @class AltairVoteApi
 * @extends {BaseAPI}
 */
export class AltairVoteApi extends BaseAPI {
    /**
     * 
     * @summary altair_vote/ajax_vote_api
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AltairVoteApi
     */
    public altairVoteAjaxVoteApiPost(body?: object, options?: AxiosRequestConfig) {
        return AltairVoteApiFp(this.configuration).altairVoteAjaxVoteApiPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary altair_vote/index_api
     * @param {string} [targetYear] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AltairVoteApi
     */
    public altairVoteIndexApiPost(targetYear?: string, options?: AxiosRequestConfig) {
        return AltairVoteApiFp(this.configuration).altairVoteIndexApiPost(targetYear, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ApiApi - axios parameter creator
 * @export
 */
export const ApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary api/touch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTouchGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/touch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary api/touch
         * @param {string} [c] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTouchPost: async (c?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/touch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (c !== undefined) { 
                localVarFormParams.append('c', c as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiApi - functional programming interface
 * @export
 */
export const ApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary api/touch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTouchGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTouchGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary api/touch
         * @param {string} [c] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTouchPost(c?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTouchPost(c, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiApi - factory interface
 * @export
 */
export const ApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiApiFp(configuration)
    return {
        /**
         * 
         * @summary api/touch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTouchGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiTouchGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary api/touch
         * @param {string} [c] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTouchPost(c?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiTouchPost(c, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiApi - object-oriented interface
 * @export
 * @class ApiApi
 * @extends {BaseAPI}
 */
export class ApiApi extends BaseAPI {
    /**
     * 
     * @summary api/touch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiTouchGet(options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).apiTouchGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary api/touch
     * @param {string} [c] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiApi
     */
    public apiTouchPost(c?: string, options?: AxiosRequestConfig) {
        return ApiApiFp(this.configuration).apiTouchPost(c, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary auth/index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authIndexPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/index`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary auth/index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authIndexPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authIndexPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @summary auth/index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authIndexPost(options?: any): AxiosPromise<void> {
            return localVarFp.authIndexPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary auth/index
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authIndexPost(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authIndexPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BLeagueAuthApi - axios parameter creator
 * @export
 */
export const BLeagueAuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary check_user_register
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bLeagueAuthCheckUserRegisterPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/b_league_auth/check_user_register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary link_account
         * @param {string} [email] 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bLeagueAuthLinkAccountPost: async (email?: string, password?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/b_league_auth/link_account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (email !== undefined) { 
                localVarFormParams.append('email', email as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.append('password', password as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary login
         * @param {string} [email] 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bLeagueAuthLoginPost: async (email?: string, password?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/b_league_auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (email !== undefined) { 
                localVarFormParams.append('email', email as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.append('password', password as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BLeagueAuthApi - functional programming interface
 * @export
 */
export const BLeagueAuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BLeagueAuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary check_user_register
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bLeagueAuthCheckUserRegisterPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bLeagueAuthCheckUserRegisterPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary link_account
         * @param {string} [email] 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bLeagueAuthLinkAccountPost(email?: string, password?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bLeagueAuthLinkAccountPost(email, password, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary login
         * @param {string} [email] 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bLeagueAuthLoginPost(email?: string, password?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bLeagueAuthLoginPost(email, password, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BLeagueAuthApi - factory interface
 * @export
 */
export const BLeagueAuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BLeagueAuthApiFp(configuration)
    return {
        /**
         * 
         * @summary check_user_register
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bLeagueAuthCheckUserRegisterPost(options?: any): AxiosPromise<void> {
            return localVarFp.bLeagueAuthCheckUserRegisterPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary link_account
         * @param {string} [email] 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bLeagueAuthLinkAccountPost(email?: string, password?: string, options?: any): AxiosPromise<void> {
            return localVarFp.bLeagueAuthLinkAccountPost(email, password, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary login
         * @param {string} [email] 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bLeagueAuthLoginPost(email?: string, password?: string, options?: any): AxiosPromise<void> {
            return localVarFp.bLeagueAuthLoginPost(email, password, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BLeagueAuthApi - object-oriented interface
 * @export
 * @class BLeagueAuthApi
 * @extends {BaseAPI}
 */
export class BLeagueAuthApi extends BaseAPI {
    /**
     * 
     * @summary check_user_register
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BLeagueAuthApi
     */
    public bLeagueAuthCheckUserRegisterPost(options?: AxiosRequestConfig) {
        return BLeagueAuthApiFp(this.configuration).bLeagueAuthCheckUserRegisterPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary link_account
     * @param {string} [email] 
     * @param {string} [password] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BLeagueAuthApi
     */
    public bLeagueAuthLinkAccountPost(email?: string, password?: string, options?: AxiosRequestConfig) {
        return BLeagueAuthApiFp(this.configuration).bLeagueAuthLinkAccountPost(email, password, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary login
     * @param {string} [email] 
     * @param {string} [password] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BLeagueAuthApi
     */
    public bLeagueAuthLoginPost(email?: string, password?: string, options?: AxiosRequestConfig) {
        return BLeagueAuthApiFp(this.configuration).bLeagueAuthLoginPost(email, password, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CampaignApi - axios parameter creator
 * @export
 */
export const CampaignApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary campaign/ajax_subscribe
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignAjaxSubscribeIdPost: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('campaignAjaxSubscribeIdPost', 'id', id)
            const localVarPath = `/campaign/ajax_subscribe/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary campaign/ajax_subscribe_with_data
         * @param {number} id 
         * @param {number} [skills] 
         * @param {number} [threePoint] 
         * @param {number} [dunk] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignAjaxSubscribeWithDataIdPost: async (id: number, skills?: number, threePoint?: number, dunk?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('campaignAjaxSubscribeWithDataIdPost', 'id', id)
            const localVarPath = `/campaign/ajax_subscribe_with_data/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (skills !== undefined) { 
                localVarFormParams.append('skills', skills as any);
            }
    
            if (threePoint !== undefined) { 
                localVarFormParams.append('three_point', threePoint as any);
            }
    
            if (dunk !== undefined) { 
                localVarFormParams.append('dunk', dunk as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary campaign/index_api/:id
         * @param {string} id イベントID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignIndexApiIdPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('campaignIndexApiIdPost', 'id', id)
            const localVarPath = `/campaign/index_api/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary campaign/to_backup_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignToBackupApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/campaign/to_backup_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CampaignApi - functional programming interface
 * @export
 */
export const CampaignApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CampaignApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary campaign/ajax_subscribe
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignAjaxSubscribeIdPost(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignAjaxSubscribeIdPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary campaign/ajax_subscribe_with_data
         * @param {number} id 
         * @param {number} [skills] 
         * @param {number} [threePoint] 
         * @param {number} [dunk] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignAjaxSubscribeWithDataIdPost(id: number, skills?: number, threePoint?: number, dunk?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignAjaxSubscribeWithDataIdPost(id, skills, threePoint, dunk, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary campaign/index_api/:id
         * @param {string} id イベントID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignIndexApiIdPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignIndexApiIdPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary campaign/to_backup_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignToBackupApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignToBackupApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CampaignApi - factory interface
 * @export
 */
export const CampaignApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CampaignApiFp(configuration)
    return {
        /**
         * 
         * @summary campaign/ajax_subscribe
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignAjaxSubscribeIdPost(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.campaignAjaxSubscribeIdPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary campaign/ajax_subscribe_with_data
         * @param {number} id 
         * @param {number} [skills] 
         * @param {number} [threePoint] 
         * @param {number} [dunk] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignAjaxSubscribeWithDataIdPost(id: number, skills?: number, threePoint?: number, dunk?: number, options?: any): AxiosPromise<void> {
            return localVarFp.campaignAjaxSubscribeWithDataIdPost(id, skills, threePoint, dunk, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary campaign/index_api/:id
         * @param {string} id イベントID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignIndexApiIdPost(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.campaignIndexApiIdPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary campaign/to_backup_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignToBackupApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.campaignToBackupApiPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CampaignApi - object-oriented interface
 * @export
 * @class CampaignApi
 * @extends {BaseAPI}
 */
export class CampaignApi extends BaseAPI {
    /**
     * 
     * @summary campaign/ajax_subscribe
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignAjaxSubscribeIdPost(id: number, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).campaignAjaxSubscribeIdPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary campaign/ajax_subscribe_with_data
     * @param {number} id 
     * @param {number} [skills] 
     * @param {number} [threePoint] 
     * @param {number} [dunk] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignAjaxSubscribeWithDataIdPost(id: number, skills?: number, threePoint?: number, dunk?: number, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).campaignAjaxSubscribeWithDataIdPost(id, skills, threePoint, dunk, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary campaign/index_api/:id
     * @param {string} id イベントID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignIndexApiIdPost(id: string, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).campaignIndexApiIdPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary campaign/to_backup_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignToBackupApiPost(options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).campaignToBackupApiPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CardSeriesVoteApi - axios parameter creator
 * @export
 */
export const CardSeriesVoteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary card_series_vote/index_api
         * @param {string} [targetYear] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cardSeriesVoteIndexApiPost: async (targetYear?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/card_series_vote/index_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (targetYear !== undefined) {
                localVarQueryParameter['target_year'] = targetYear;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CardSeriesVoteApi - functional programming interface
 * @export
 */
export const CardSeriesVoteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CardSeriesVoteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary card_series_vote/index_api
         * @param {string} [targetYear] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cardSeriesVoteIndexApiPost(targetYear?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cardSeriesVoteIndexApiPost(targetYear, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CardSeriesVoteApi - factory interface
 * @export
 */
export const CardSeriesVoteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CardSeriesVoteApiFp(configuration)
    return {
        /**
         * 
         * @summary card_series_vote/index_api
         * @param {string} [targetYear] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cardSeriesVoteIndexApiPost(targetYear?: string, options?: any): AxiosPromise<void> {
            return localVarFp.cardSeriesVoteIndexApiPost(targetYear, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CardSeriesVoteApi - object-oriented interface
 * @export
 * @class CardSeriesVoteApi
 * @extends {BaseAPI}
 */
export class CardSeriesVoteApi extends BaseAPI {
    /**
     * 
     * @summary card_series_vote/index_api
     * @param {string} [targetYear] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CardSeriesVoteApi
     */
    public cardSeriesVoteIndexApiPost(targetYear?: string, options?: AxiosRequestConfig) {
        return CardSeriesVoteApiFp(this.configuration).cardSeriesVoteIndexApiPost(targetYear, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ClassMissionApi - axios parameter creator
 * @export
 */
export const ClassMissionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary class_mission/index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        classMissionIndexApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/class_mission/index_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClassMissionApi - functional programming interface
 * @export
 */
export const ClassMissionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClassMissionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary class_mission/index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async classMissionIndexApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.classMissionIndexApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClassMissionApi - factory interface
 * @export
 */
export const ClassMissionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClassMissionApiFp(configuration)
    return {
        /**
         * 
         * @summary class_mission/index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        classMissionIndexApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.classMissionIndexApiPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClassMissionApi - object-oriented interface
 * @export
 * @class ClassMissionApi
 * @extends {BaseAPI}
 */
export class ClassMissionApi extends BaseAPI {
    /**
     * 
     * @summary class_mission/index_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClassMissionApi
     */
    public classMissionIndexApiPost(options?: AxiosRequestConfig) {
        return ClassMissionApiFp(this.configuration).classMissionIndexApiPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CouponApi - axios parameter creator
 * @export
 */
export const CouponApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary coupon/ajax_consume
         * @param {string} [id] 
         * @param {string} [passcode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponAjaxConsumePost: async (id?: string, passcode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/coupon/ajax_consume`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (id !== undefined) { 
                localVarFormParams.append('id', id as any);
            }
    
            if (passcode !== undefined) { 
                localVarFormParams.append('passcode', passcode as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary coupon/index_api/:id
         * @param {string} id イベントID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponIndexApiIdPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('couponIndexApiIdPost', 'id', id)
            const localVarPath = `/coupon/index_api/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary coupon/log_session_api/:id
         * @param {string} id イベントID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponLogSessionApiIdPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('couponLogSessionApiIdPost', 'id', id)
            const localVarPath = `/coupon/log_session_api/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CouponApi - functional programming interface
 * @export
 */
export const CouponApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CouponApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary coupon/ajax_consume
         * @param {string} [id] 
         * @param {string} [passcode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async couponAjaxConsumePost(id?: string, passcode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.couponAjaxConsumePost(id, passcode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary coupon/index_api/:id
         * @param {string} id イベントID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async couponIndexApiIdPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.couponIndexApiIdPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary coupon/log_session_api/:id
         * @param {string} id イベントID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async couponLogSessionApiIdPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.couponLogSessionApiIdPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CouponApi - factory interface
 * @export
 */
export const CouponApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CouponApiFp(configuration)
    return {
        /**
         * 
         * @summary coupon/ajax_consume
         * @param {string} [id] 
         * @param {string} [passcode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponAjaxConsumePost(id?: string, passcode?: string, options?: any): AxiosPromise<void> {
            return localVarFp.couponAjaxConsumePost(id, passcode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary coupon/index_api/:id
         * @param {string} id イベントID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponIndexApiIdPost(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.couponIndexApiIdPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary coupon/log_session_api/:id
         * @param {string} id イベントID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponLogSessionApiIdPost(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.couponLogSessionApiIdPost(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CouponApi - object-oriented interface
 * @export
 * @class CouponApi
 * @extends {BaseAPI}
 */
export class CouponApi extends BaseAPI {
    /**
     * 
     * @summary coupon/ajax_consume
     * @param {string} [id] 
     * @param {string} [passcode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponApi
     */
    public couponAjaxConsumePost(id?: string, passcode?: string, options?: AxiosRequestConfig) {
        return CouponApiFp(this.configuration).couponAjaxConsumePost(id, passcode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary coupon/index_api/:id
     * @param {string} id イベントID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponApi
     */
    public couponIndexApiIdPost(id: string, options?: AxiosRequestConfig) {
        return CouponApiFp(this.configuration).couponIndexApiIdPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary coupon/log_session_api/:id
     * @param {string} id イベントID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponApi
     */
    public couponLogSessionApiIdPost(id: string, options?: AxiosRequestConfig) {
        return CouponApiFp(this.configuration).couponLogSessionApiIdPost(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary root/index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/index`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary root/index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async indexGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.indexGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary root/index
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indexGet(options?: any): AxiosPromise<void> {
            return localVarFp.indexGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary root/index
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public indexGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).indexGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DreamteamApi - axios parameter creator
 * @export
 */
export const DreamteamApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary dreamteam/index_api
         * @param {string} [targetYear] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dreamteamIndexApiPost: async (targetYear?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dreamteam/index_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (targetYear !== undefined) {
                localVarQueryParameter['target_year'] = targetYear;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DreamteamApi - functional programming interface
 * @export
 */
export const DreamteamApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DreamteamApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary dreamteam/index_api
         * @param {string} [targetYear] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dreamteamIndexApiPost(targetYear?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dreamteamIndexApiPost(targetYear, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DreamteamApi - factory interface
 * @export
 */
export const DreamteamApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DreamteamApiFp(configuration)
    return {
        /**
         * 
         * @summary dreamteam/index_api
         * @param {string} [targetYear] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dreamteamIndexApiPost(targetYear?: string, options?: any): AxiosPromise<void> {
            return localVarFp.dreamteamIndexApiPost(targetYear, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DreamteamApi - object-oriented interface
 * @export
 * @class DreamteamApi
 * @extends {BaseAPI}
 */
export class DreamteamApi extends BaseAPI {
    /**
     * 
     * @summary dreamteam/index_api
     * @param {string} [targetYear] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DreamteamApi
     */
    public dreamteamIndexApiPost(targetYear?: string, options?: AxiosRequestConfig) {
        return DreamteamApiFp(this.configuration).dreamteamIndexApiPost(targetYear, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DreamteamVoteApi - axios parameter creator
 * @export
 */
export const DreamteamVoteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary dreamteam_vote/ajax_vote_api
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dreamteamVoteAjaxVoteApiPost: async (body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dreamteam_vote/ajax_vote_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary dreamteam_vote/index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dreamteamVoteIndexApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dreamteam_vote/index_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DreamteamVoteApi - functional programming interface
 * @export
 */
export const DreamteamVoteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DreamteamVoteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary dreamteam_vote/ajax_vote_api
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dreamteamVoteAjaxVoteApiPost(body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dreamteamVoteAjaxVoteApiPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary dreamteam_vote/index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dreamteamVoteIndexApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dreamteamVoteIndexApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DreamteamVoteApi - factory interface
 * @export
 */
export const DreamteamVoteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DreamteamVoteApiFp(configuration)
    return {
        /**
         * 
         * @summary dreamteam_vote/ajax_vote_api
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dreamteamVoteAjaxVoteApiPost(body?: object, options?: any): AxiosPromise<void> {
            return localVarFp.dreamteamVoteAjaxVoteApiPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary dreamteam_vote/index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dreamteamVoteIndexApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.dreamteamVoteIndexApiPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DreamteamVoteApi - object-oriented interface
 * @export
 * @class DreamteamVoteApi
 * @extends {BaseAPI}
 */
export class DreamteamVoteApi extends BaseAPI {
    /**
     * 
     * @summary dreamteam_vote/ajax_vote_api
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DreamteamVoteApi
     */
    public dreamteamVoteAjaxVoteApiPost(body?: object, options?: AxiosRequestConfig) {
        return DreamteamVoteApiFp(this.configuration).dreamteamVoteAjaxVoteApiPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary dreamteam_vote/index_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DreamteamVoteApi
     */
    public dreamteamVoteIndexApiPost(options?: AxiosRequestConfig) {
        return DreamteamVoteApiFp(this.configuration).dreamteamVoteIndexApiPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ErrorApi - axios parameter creator
 * @export
 */
export const ErrorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary error/not_found_user_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorNotFoundUserApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/error/not_found_user_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ErrorApi - functional programming interface
 * @export
 */
export const ErrorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ErrorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary error/not_found_user_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async errorNotFoundUserApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.errorNotFoundUserApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ErrorApi - factory interface
 * @export
 */
export const ErrorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ErrorApiFp(configuration)
    return {
        /**
         * 
         * @summary error/not_found_user_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorNotFoundUserApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.errorNotFoundUserApiPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ErrorApi - object-oriented interface
 * @export
 * @class ErrorApi
 * @extends {BaseAPI}
 */
export class ErrorApi extends BaseAPI {
    /**
     * 
     * @summary error/not_found_user_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ErrorApi
     */
    public errorNotFoundUserApiPost(options?: AxiosRequestConfig) {
        return ErrorApiFp(this.configuration).errorNotFoundUserApiPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FolderApi - axios parameter creator
 * @export
 */
export const FolderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary folder_api
         * @param {number} [targetYear] カードの年度
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        folderApiPost: async (targetYear?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/folder_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (targetYear !== undefined) {
                localVarQueryParameter['target_year'] = targetYear;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FolderApi - functional programming interface
 * @export
 */
export const FolderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FolderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary folder_api
         * @param {number} [targetYear] カードの年度
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async folderApiPost(targetYear?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.folderApiPost(targetYear, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FolderApi - factory interface
 * @export
 */
export const FolderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FolderApiFp(configuration)
    return {
        /**
         * 
         * @summary folder_api
         * @param {number} [targetYear] カードの年度
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        folderApiPost(targetYear?: number, options?: any): AxiosPromise<void> {
            return localVarFp.folderApiPost(targetYear, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FolderApi - object-oriented interface
 * @export
 * @class FolderApi
 * @extends {BaseAPI}
 */
export class FolderApi extends BaseAPI {
    /**
     * 
     * @summary folder_api
     * @param {number} [targetYear] カードの年度
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FolderApi
     */
    public folderApiPost(targetYear?: number, options?: AxiosRequestConfig) {
        return FolderApiFp(this.configuration).folderApiPost(targetYear, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ForecastApi - axios parameter creator
 * @export
 */
export const ForecastApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary ajax/forecast/clear_card
         * @param {string} [slot] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxForecastClearCardPost: async (slot?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/forecast/clear_card`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (slot !== undefined) { 
                localVarFormParams.append('slot', slot as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/forecast/exchange_reward
         * @param {string} [rewardItemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxForecastExchangeRewardPost: async (rewardItemId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/forecast/exchange_reward`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (rewardItemId !== undefined) { 
                localVarFormParams.append('reward_item_id', rewardItemId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/forecast/exec_auto_card_set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxForecastExecAutoCardSetPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/forecast/exec_auto_card_set`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/forecast/get_badge_setting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxForecastGetBadgeSettingPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/forecast/get_badge_setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/forecast/get_game_schedule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxForecastGetGameSchedulePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/forecast/get_game_schedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/forecast/get_season_ranks
         * @param {string} [currentId] 
         * @param {string} [isGetUpper] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxForecastGetSeasonRanksPost: async (currentId?: string, isGetUpper?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/forecast/get_season_ranks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (currentId !== undefined) { 
                localVarFormParams.append('current_id', currentId as any);
            }
    
            if (isGetUpper !== undefined) { 
                localVarFormParams.append('is_get_upper', isGetUpper as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/forecast/get_section_ranks
         * @param {string} [currentId] 
         * @param {string} [isGetUpper] 
         * @param {string} [section] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxForecastGetSectionRanksPost: async (currentId?: string, isGetUpper?: string, section?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/forecast/get_section_ranks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (currentId !== undefined) { 
                localVarFormParams.append('current_id', currentId as any);
            }
    
            if (isGetUpper !== undefined) { 
                localVarFormParams.append('is_get_upper', isGetUpper as any);
            }
    
            if (section !== undefined) { 
                localVarFormParams.append('section', section as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/forecast/is_slot_editable
         * @param {string} [slot] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxForecastIsSlotEditablePost: async (slot?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/forecast/is_slot_editable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (slot !== undefined) { 
                localVarFormParams.append('slot', slot as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/forecast/save_cards
         * @param {string} [isForce] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxForecastSaveCardsPost: async (isForce?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/forecast/save_cards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (isForce !== undefined) { 
                localVarFormParams.append('is_force', isForce as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/forecast/select_card
         * @param {string} [cardId] 
         * @param {string} [slot] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxForecastSelectCardPost: async (cardId?: string, slot?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/forecast/select_card`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (cardId !== undefined) { 
                localVarFormParams.append('card_id', cardId as any);
            }
    
            if (slot !== undefined) { 
                localVarFormParams.append('slot', slot as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/forecast/set_badge
         * @param {string} [slotBadges] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxForecastSetBadgePost: async (slotBadges?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/forecast/set_badge`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (slotBadges !== undefined) { 
                localVarFormParams.append('slot_badges', slotBadges as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary forecast/basic_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forecastBasicApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/forecast/basic_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary forecast/bonus_detail_api
         * @param {string} [section] 
         * @param {string} [uid] 
         * @param {string} [isRefSeason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forecastBonusDetailApiPost: async (section?: string, uid?: string, isRefSeason?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/forecast/bonus_detail_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (section !== undefined) {
                localVarQueryParameter['section'] = section;
            }

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (isRefSeason !== undefined) {
                localVarQueryParameter['is_ref_season'] = isRefSeason;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary forecast/exchange_effect_api
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forecastExchangeEffectApiPost: async (id?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/forecast/exchange_effect_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary forecast/history_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forecastHistoryApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/forecast/history_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary forecast/history_detail_api
         * @param {number} [section] ${value}節のデータを取得する
         * @param {string} [uid] 
         * @param {string} [isRefReason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forecastHistoryDetailApiPost: async (section?: number, uid?: string, isRefReason?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/forecast/history_detail_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (section !== undefined) {
                localVarQueryParameter['section'] = section;
            }

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (isRefReason !== undefined) {
                localVarQueryParameter['is_ref_reason'] = isRefReason;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary forecast/index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forecastIndexApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/forecast/index_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary forecast/ranking_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forecastRankingApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/forecast/ranking_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary forecast/result_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forecastResultApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/forecast/result_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary forecast/result_effect_api
         * @param {string} [r] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forecastResultEffectApiPost: async (r?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/forecast/result_effect_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (r !== undefined) {
                localVarQueryParameter['r'] = r;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary forecast/reward_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forecastRewardApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/forecast/reward_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary forecast/score_detail_api
         * @param {number} [slot] 
         * @param {number} [section] 節
         * @param {string} [uid] 
         * @param {string} [isRefSeason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forecastScoreDetailApiPost: async (slot?: number, section?: number, uid?: string, isRefSeason?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/forecast/score_detail_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (slot !== undefined) {
                localVarQueryParameter['slot'] = slot;
            }

            if (section !== undefined) {
                localVarQueryParameter['section'] = section;
            }

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (isRefSeason !== undefined) {
                localVarQueryParameter['is_ref_season'] = isRefSeason;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary forecast/score_list_api
         * @param {string} [section] 
         * @param {string} [uid] 
         * @param {string} [isRefSeason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forecastScoreListApiPost: async (section?: string, uid?: string, isRefSeason?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/forecast/score_list_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (section !== undefined) {
                localVarQueryParameter['section'] = section;
            }

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (isRefSeason !== undefined) {
                localVarQueryParameter['is_ref_season'] = isRefSeason;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary forecast/season_ranking_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forecastSeasonRankingApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/forecast/season_ranking_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary forecast/select_card_api
         * @param {number} [slot] 1, 2, 3, 4, 5(PG, SG, SF, PF, C)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forecastSelectCardApiPost: async (slot?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/forecast/select_card_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (slot !== undefined) {
                localVarQueryParameter['slot'] = slot;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary forecast/slide_tutorial_api
         * @param {string} [r] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forecastSlideTutorialApiPost: async (r?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/forecast/slide_tutorial_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (r !== undefined) {
                localVarQueryParameter['r'] = r;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary forecast/special_bonus_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forecastSpecialBonusApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/forecast/special_bonus_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForecastApi - functional programming interface
 * @export
 */
export const ForecastApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForecastApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary ajax/forecast/clear_card
         * @param {string} [slot] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxForecastClearCardPost(slot?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxForecastClearCardPost(slot, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/forecast/exchange_reward
         * @param {string} [rewardItemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxForecastExchangeRewardPost(rewardItemId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxForecastExchangeRewardPost(rewardItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/forecast/exec_auto_card_set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxForecastExecAutoCardSetPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxForecastExecAutoCardSetPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/forecast/get_badge_setting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxForecastGetBadgeSettingPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxForecastGetBadgeSettingPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/forecast/get_game_schedule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxForecastGetGameSchedulePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxForecastGetGameSchedulePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/forecast/get_season_ranks
         * @param {string} [currentId] 
         * @param {string} [isGetUpper] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxForecastGetSeasonRanksPost(currentId?: string, isGetUpper?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxForecastGetSeasonRanksPost(currentId, isGetUpper, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/forecast/get_section_ranks
         * @param {string} [currentId] 
         * @param {string} [isGetUpper] 
         * @param {string} [section] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxForecastGetSectionRanksPost(currentId?: string, isGetUpper?: string, section?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxForecastGetSectionRanksPost(currentId, isGetUpper, section, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/forecast/is_slot_editable
         * @param {string} [slot] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxForecastIsSlotEditablePost(slot?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxForecastIsSlotEditablePost(slot, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/forecast/save_cards
         * @param {string} [isForce] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxForecastSaveCardsPost(isForce?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxForecastSaveCardsPost(isForce, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/forecast/select_card
         * @param {string} [cardId] 
         * @param {string} [slot] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxForecastSelectCardPost(cardId?: string, slot?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxForecastSelectCardPost(cardId, slot, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/forecast/set_badge
         * @param {string} [slotBadges] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxForecastSetBadgePost(slotBadges?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxForecastSetBadgePost(slotBadges, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary forecast/basic_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forecastBasicApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forecastBasicApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary forecast/bonus_detail_api
         * @param {string} [section] 
         * @param {string} [uid] 
         * @param {string} [isRefSeason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forecastBonusDetailApiPost(section?: string, uid?: string, isRefSeason?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forecastBonusDetailApiPost(section, uid, isRefSeason, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary forecast/exchange_effect_api
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forecastExchangeEffectApiPost(id?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forecastExchangeEffectApiPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary forecast/history_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forecastHistoryApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forecastHistoryApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary forecast/history_detail_api
         * @param {number} [section] ${value}節のデータを取得する
         * @param {string} [uid] 
         * @param {string} [isRefReason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forecastHistoryDetailApiPost(section?: number, uid?: string, isRefReason?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forecastHistoryDetailApiPost(section, uid, isRefReason, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary forecast/index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forecastIndexApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forecastIndexApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary forecast/ranking_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forecastRankingApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forecastRankingApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary forecast/result_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forecastResultApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forecastResultApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary forecast/result_effect_api
         * @param {string} [r] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forecastResultEffectApiPost(r?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forecastResultEffectApiPost(r, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary forecast/reward_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forecastRewardApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forecastRewardApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary forecast/score_detail_api
         * @param {number} [slot] 
         * @param {number} [section] 節
         * @param {string} [uid] 
         * @param {string} [isRefSeason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forecastScoreDetailApiPost(slot?: number, section?: number, uid?: string, isRefSeason?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forecastScoreDetailApiPost(slot, section, uid, isRefSeason, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary forecast/score_list_api
         * @param {string} [section] 
         * @param {string} [uid] 
         * @param {string} [isRefSeason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forecastScoreListApiPost(section?: string, uid?: string, isRefSeason?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forecastScoreListApiPost(section, uid, isRefSeason, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary forecast/season_ranking_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forecastSeasonRankingApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forecastSeasonRankingApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary forecast/select_card_api
         * @param {number} [slot] 1, 2, 3, 4, 5(PG, SG, SF, PF, C)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forecastSelectCardApiPost(slot?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forecastSelectCardApiPost(slot, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary forecast/slide_tutorial_api
         * @param {string} [r] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forecastSlideTutorialApiPost(r?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forecastSlideTutorialApiPost(r, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary forecast/special_bonus_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forecastSpecialBonusApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forecastSpecialBonusApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForecastApi - factory interface
 * @export
 */
export const ForecastApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForecastApiFp(configuration)
    return {
        /**
         * 
         * @summary ajax/forecast/clear_card
         * @param {string} [slot] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxForecastClearCardPost(slot?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxForecastClearCardPost(slot, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/forecast/exchange_reward
         * @param {string} [rewardItemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxForecastExchangeRewardPost(rewardItemId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxForecastExchangeRewardPost(rewardItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/forecast/exec_auto_card_set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxForecastExecAutoCardSetPost(options?: any): AxiosPromise<void> {
            return localVarFp.ajaxForecastExecAutoCardSetPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/forecast/get_badge_setting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxForecastGetBadgeSettingPost(options?: any): AxiosPromise<void> {
            return localVarFp.ajaxForecastGetBadgeSettingPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/forecast/get_game_schedule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxForecastGetGameSchedulePost(options?: any): AxiosPromise<void> {
            return localVarFp.ajaxForecastGetGameSchedulePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/forecast/get_season_ranks
         * @param {string} [currentId] 
         * @param {string} [isGetUpper] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxForecastGetSeasonRanksPost(currentId?: string, isGetUpper?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxForecastGetSeasonRanksPost(currentId, isGetUpper, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/forecast/get_section_ranks
         * @param {string} [currentId] 
         * @param {string} [isGetUpper] 
         * @param {string} [section] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxForecastGetSectionRanksPost(currentId?: string, isGetUpper?: string, section?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxForecastGetSectionRanksPost(currentId, isGetUpper, section, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/forecast/is_slot_editable
         * @param {string} [slot] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxForecastIsSlotEditablePost(slot?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxForecastIsSlotEditablePost(slot, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/forecast/save_cards
         * @param {string} [isForce] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxForecastSaveCardsPost(isForce?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxForecastSaveCardsPost(isForce, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/forecast/select_card
         * @param {string} [cardId] 
         * @param {string} [slot] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxForecastSelectCardPost(cardId?: string, slot?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxForecastSelectCardPost(cardId, slot, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/forecast/set_badge
         * @param {string} [slotBadges] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxForecastSetBadgePost(slotBadges?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxForecastSetBadgePost(slotBadges, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary forecast/basic_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forecastBasicApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.forecastBasicApiPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary forecast/bonus_detail_api
         * @param {string} [section] 
         * @param {string} [uid] 
         * @param {string} [isRefSeason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forecastBonusDetailApiPost(section?: string, uid?: string, isRefSeason?: string, options?: any): AxiosPromise<void> {
            return localVarFp.forecastBonusDetailApiPost(section, uid, isRefSeason, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary forecast/exchange_effect_api
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forecastExchangeEffectApiPost(id?: number, options?: any): AxiosPromise<void> {
            return localVarFp.forecastExchangeEffectApiPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary forecast/history_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forecastHistoryApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.forecastHistoryApiPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary forecast/history_detail_api
         * @param {number} [section] ${value}節のデータを取得する
         * @param {string} [uid] 
         * @param {string} [isRefReason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forecastHistoryDetailApiPost(section?: number, uid?: string, isRefReason?: string, options?: any): AxiosPromise<void> {
            return localVarFp.forecastHistoryDetailApiPost(section, uid, isRefReason, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary forecast/index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forecastIndexApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.forecastIndexApiPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary forecast/ranking_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forecastRankingApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.forecastRankingApiPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary forecast/result_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forecastResultApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.forecastResultApiPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary forecast/result_effect_api
         * @param {string} [r] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forecastResultEffectApiPost(r?: string, options?: any): AxiosPromise<void> {
            return localVarFp.forecastResultEffectApiPost(r, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary forecast/reward_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forecastRewardApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.forecastRewardApiPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary forecast/score_detail_api
         * @param {number} [slot] 
         * @param {number} [section] 節
         * @param {string} [uid] 
         * @param {string} [isRefSeason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forecastScoreDetailApiPost(slot?: number, section?: number, uid?: string, isRefSeason?: string, options?: any): AxiosPromise<void> {
            return localVarFp.forecastScoreDetailApiPost(slot, section, uid, isRefSeason, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary forecast/score_list_api
         * @param {string} [section] 
         * @param {string} [uid] 
         * @param {string} [isRefSeason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forecastScoreListApiPost(section?: string, uid?: string, isRefSeason?: string, options?: any): AxiosPromise<void> {
            return localVarFp.forecastScoreListApiPost(section, uid, isRefSeason, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary forecast/season_ranking_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forecastSeasonRankingApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.forecastSeasonRankingApiPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary forecast/select_card_api
         * @param {number} [slot] 1, 2, 3, 4, 5(PG, SG, SF, PF, C)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forecastSelectCardApiPost(slot?: number, options?: any): AxiosPromise<void> {
            return localVarFp.forecastSelectCardApiPost(slot, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary forecast/slide_tutorial_api
         * @param {string} [r] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forecastSlideTutorialApiPost(r?: string, options?: any): AxiosPromise<void> {
            return localVarFp.forecastSlideTutorialApiPost(r, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary forecast/special_bonus_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forecastSpecialBonusApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.forecastSpecialBonusApiPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ForecastApi - object-oriented interface
 * @export
 * @class ForecastApi
 * @extends {BaseAPI}
 */
export class ForecastApi extends BaseAPI {
    /**
     * 
     * @summary ajax/forecast/clear_card
     * @param {string} [slot] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForecastApi
     */
    public ajaxForecastClearCardPost(slot?: string, options?: AxiosRequestConfig) {
        return ForecastApiFp(this.configuration).ajaxForecastClearCardPost(slot, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/forecast/exchange_reward
     * @param {string} [rewardItemId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForecastApi
     */
    public ajaxForecastExchangeRewardPost(rewardItemId?: string, options?: AxiosRequestConfig) {
        return ForecastApiFp(this.configuration).ajaxForecastExchangeRewardPost(rewardItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/forecast/exec_auto_card_set
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForecastApi
     */
    public ajaxForecastExecAutoCardSetPost(options?: AxiosRequestConfig) {
        return ForecastApiFp(this.configuration).ajaxForecastExecAutoCardSetPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/forecast/get_badge_setting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForecastApi
     */
    public ajaxForecastGetBadgeSettingPost(options?: AxiosRequestConfig) {
        return ForecastApiFp(this.configuration).ajaxForecastGetBadgeSettingPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/forecast/get_game_schedule
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForecastApi
     */
    public ajaxForecastGetGameSchedulePost(options?: AxiosRequestConfig) {
        return ForecastApiFp(this.configuration).ajaxForecastGetGameSchedulePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/forecast/get_season_ranks
     * @param {string} [currentId] 
     * @param {string} [isGetUpper] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForecastApi
     */
    public ajaxForecastGetSeasonRanksPost(currentId?: string, isGetUpper?: string, options?: AxiosRequestConfig) {
        return ForecastApiFp(this.configuration).ajaxForecastGetSeasonRanksPost(currentId, isGetUpper, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/forecast/get_section_ranks
     * @param {string} [currentId] 
     * @param {string} [isGetUpper] 
     * @param {string} [section] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForecastApi
     */
    public ajaxForecastGetSectionRanksPost(currentId?: string, isGetUpper?: string, section?: string, options?: AxiosRequestConfig) {
        return ForecastApiFp(this.configuration).ajaxForecastGetSectionRanksPost(currentId, isGetUpper, section, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/forecast/is_slot_editable
     * @param {string} [slot] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForecastApi
     */
    public ajaxForecastIsSlotEditablePost(slot?: string, options?: AxiosRequestConfig) {
        return ForecastApiFp(this.configuration).ajaxForecastIsSlotEditablePost(slot, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/forecast/save_cards
     * @param {string} [isForce] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForecastApi
     */
    public ajaxForecastSaveCardsPost(isForce?: string, options?: AxiosRequestConfig) {
        return ForecastApiFp(this.configuration).ajaxForecastSaveCardsPost(isForce, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/forecast/select_card
     * @param {string} [cardId] 
     * @param {string} [slot] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForecastApi
     */
    public ajaxForecastSelectCardPost(cardId?: string, slot?: string, options?: AxiosRequestConfig) {
        return ForecastApiFp(this.configuration).ajaxForecastSelectCardPost(cardId, slot, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/forecast/set_badge
     * @param {string} [slotBadges] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForecastApi
     */
    public ajaxForecastSetBadgePost(slotBadges?: string, options?: AxiosRequestConfig) {
        return ForecastApiFp(this.configuration).ajaxForecastSetBadgePost(slotBadges, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary forecast/basic_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForecastApi
     */
    public forecastBasicApiPost(options?: AxiosRequestConfig) {
        return ForecastApiFp(this.configuration).forecastBasicApiPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary forecast/bonus_detail_api
     * @param {string} [section] 
     * @param {string} [uid] 
     * @param {string} [isRefSeason] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForecastApi
     */
    public forecastBonusDetailApiPost(section?: string, uid?: string, isRefSeason?: string, options?: AxiosRequestConfig) {
        return ForecastApiFp(this.configuration).forecastBonusDetailApiPost(section, uid, isRefSeason, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary forecast/exchange_effect_api
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForecastApi
     */
    public forecastExchangeEffectApiPost(id?: number, options?: AxiosRequestConfig) {
        return ForecastApiFp(this.configuration).forecastExchangeEffectApiPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary forecast/history_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForecastApi
     */
    public forecastHistoryApiPost(options?: AxiosRequestConfig) {
        return ForecastApiFp(this.configuration).forecastHistoryApiPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary forecast/history_detail_api
     * @param {number} [section] ${value}節のデータを取得する
     * @param {string} [uid] 
     * @param {string} [isRefReason] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForecastApi
     */
    public forecastHistoryDetailApiPost(section?: number, uid?: string, isRefReason?: string, options?: AxiosRequestConfig) {
        return ForecastApiFp(this.configuration).forecastHistoryDetailApiPost(section, uid, isRefReason, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary forecast/index_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForecastApi
     */
    public forecastIndexApiPost(options?: AxiosRequestConfig) {
        return ForecastApiFp(this.configuration).forecastIndexApiPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary forecast/ranking_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForecastApi
     */
    public forecastRankingApiPost(options?: AxiosRequestConfig) {
        return ForecastApiFp(this.configuration).forecastRankingApiPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary forecast/result_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForecastApi
     */
    public forecastResultApiPost(options?: AxiosRequestConfig) {
        return ForecastApiFp(this.configuration).forecastResultApiPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary forecast/result_effect_api
     * @param {string} [r] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForecastApi
     */
    public forecastResultEffectApiPost(r?: string, options?: AxiosRequestConfig) {
        return ForecastApiFp(this.configuration).forecastResultEffectApiPost(r, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary forecast/reward_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForecastApi
     */
    public forecastRewardApiPost(options?: AxiosRequestConfig) {
        return ForecastApiFp(this.configuration).forecastRewardApiPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary forecast/score_detail_api
     * @param {number} [slot] 
     * @param {number} [section] 節
     * @param {string} [uid] 
     * @param {string} [isRefSeason] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForecastApi
     */
    public forecastScoreDetailApiPost(slot?: number, section?: number, uid?: string, isRefSeason?: string, options?: AxiosRequestConfig) {
        return ForecastApiFp(this.configuration).forecastScoreDetailApiPost(slot, section, uid, isRefSeason, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary forecast/score_list_api
     * @param {string} [section] 
     * @param {string} [uid] 
     * @param {string} [isRefSeason] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForecastApi
     */
    public forecastScoreListApiPost(section?: string, uid?: string, isRefSeason?: string, options?: AxiosRequestConfig) {
        return ForecastApiFp(this.configuration).forecastScoreListApiPost(section, uid, isRefSeason, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary forecast/season_ranking_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForecastApi
     */
    public forecastSeasonRankingApiPost(options?: AxiosRequestConfig) {
        return ForecastApiFp(this.configuration).forecastSeasonRankingApiPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary forecast/select_card_api
     * @param {number} [slot] 1, 2, 3, 4, 5(PG, SG, SF, PF, C)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForecastApi
     */
    public forecastSelectCardApiPost(slot?: number, options?: AxiosRequestConfig) {
        return ForecastApiFp(this.configuration).forecastSelectCardApiPost(slot, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary forecast/slide_tutorial_api
     * @param {string} [r] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForecastApi
     */
    public forecastSlideTutorialApiPost(r?: string, options?: AxiosRequestConfig) {
        return ForecastApiFp(this.configuration).forecastSlideTutorialApiPost(r, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary forecast/special_bonus_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForecastApi
     */
    public forecastSpecialBonusApiPost(options?: AxiosRequestConfig) {
        return ForecastApiFp(this.configuration).forecastSpecialBonusApiPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FriendApi - axios parameter creator
 * @export
 */
export const FriendApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary friend_api
         * @param {string} [view] 
         * @param {number} [keyword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        friendApiPost: async (view?: string, keyword?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/friend_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (view !== undefined) {
                localVarQueryParameter['view'] = view;
            }

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FriendApi - functional programming interface
 * @export
 */
export const FriendApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FriendApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary friend_api
         * @param {string} [view] 
         * @param {number} [keyword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async friendApiPost(view?: string, keyword?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.friendApiPost(view, keyword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FriendApi - factory interface
 * @export
 */
export const FriendApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FriendApiFp(configuration)
    return {
        /**
         * 
         * @summary friend_api
         * @param {string} [view] 
         * @param {number} [keyword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        friendApiPost(view?: string, keyword?: number, options?: any): AxiosPromise<void> {
            return localVarFp.friendApiPost(view, keyword, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FriendApi - object-oriented interface
 * @export
 * @class FriendApi
 * @extends {BaseAPI}
 */
export class FriendApi extends BaseAPI {
    /**
     * 
     * @summary friend_api
     * @param {string} [view] 
     * @param {number} [keyword] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FriendApi
     */
    public friendApiPost(view?: string, keyword?: number, options?: AxiosRequestConfig) {
        return FriendApiFp(this.configuration).friendApiPost(view, keyword, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GachaApi - axios parameter creator
 * @export
 */
export const GachaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary gacha/ajax_gacha_exec_api
         * @param {number} [gachaId] 
         * @param {number} [gachaBtnId] 
         * @param {number} [playCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gachaAjaxGachaExecApiPost: async (gachaId?: number, gachaBtnId?: number, playCount?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/gacha/ajax_gacha_exec_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (gachaId !== undefined) { 
                localVarFormParams.append('gacha_id', gachaId as any);
            }
    
            if (gachaBtnId !== undefined) { 
                localVarFormParams.append('gacha_btn_id', gachaBtnId as any);
            }
    
            if (playCount !== undefined) { 
                localVarFormParams.append('play_count', playCount as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary gacha/ajax_gacha_exec
         * @param {number} [gachaId] 
         * @param {number} [gachaBtnId] 
         * @param {number} [playCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gachaAjaxGachaExecPost: async (gachaId?: number, gachaBtnId?: number, playCount?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/gacha/ajax_gacha_exec`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (gachaId !== undefined) { 
                localVarFormParams.append('gacha_id', gachaId as any);
            }
    
            if (gachaBtnId !== undefined) { 
                localVarFormParams.append('gacha_btn_id', gachaBtnId as any);
            }
    
            if (playCount !== undefined) { 
                localVarFormParams.append('play_count', playCount as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary gacha/contents_api/:cacha_id
         * @param {string} gachaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gachaContentsApiGachaIdPost: async (gachaId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gachaId' is not null or undefined
            assertParamExists('gachaContentsApiGachaIdPost', 'gachaId', gachaId)
            const localVarPath = `/gacha/contents_api/{gacha_id}`
                .replace(`{${"gacha_id"}}`, encodeURIComponent(String(gachaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary gacha/detail_api/:gacha_id
         * @param {string} gachaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gachaDetailApiGachaIdPost: async (gachaId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gachaId' is not null or undefined
            assertParamExists('gachaDetailApiGachaIdPost', 'gachaId', gachaId)
            const localVarPath = `/gacha/detail_api/{gacha_id}`
                .replace(`{${"gacha_id"}}`, encodeURIComponent(String(gachaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary gacha/index_api
         * @param {string} [id] 
         * @param {string} [gachaId] 
         * @param {string} [tabIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gachaIndexApiPost: async (id?: string, gachaId?: string, tabIndex?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/gacha/index_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (gachaId !== undefined) {
                localVarQueryParameter['gacha_id'] = gachaId;
            }

            if (tabIndex !== undefined) {
                localVarQueryParameter['tab_index'] = tabIndex;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary gacha/register_skip_flag
         * @param {number} [skipFlag] 0&#x3D;アニメーションをスキップする、1&#x3D;アニメーションをスキップしない
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gachaRegisterSkipFlagPost: async (skipFlag?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/gacha/register_skip_flag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (skipFlag !== undefined) { 
                localVarFormParams.append('skip_flag', skipFlag as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary gacha/result_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gachaResultApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/gacha/result_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary gacha/result_payment_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gachaResultPaymentApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/gacha/result_payment_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GachaApi - functional programming interface
 * @export
 */
export const GachaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GachaApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary gacha/ajax_gacha_exec_api
         * @param {number} [gachaId] 
         * @param {number} [gachaBtnId] 
         * @param {number} [playCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gachaAjaxGachaExecApiPost(gachaId?: number, gachaBtnId?: number, playCount?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gachaAjaxGachaExecApiPost(gachaId, gachaBtnId, playCount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary gacha/ajax_gacha_exec
         * @param {number} [gachaId] 
         * @param {number} [gachaBtnId] 
         * @param {number} [playCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gachaAjaxGachaExecPost(gachaId?: number, gachaBtnId?: number, playCount?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gachaAjaxGachaExecPost(gachaId, gachaBtnId, playCount, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary gacha/contents_api/:cacha_id
         * @param {string} gachaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gachaContentsApiGachaIdPost(gachaId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gachaContentsApiGachaIdPost(gachaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary gacha/detail_api/:gacha_id
         * @param {string} gachaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gachaDetailApiGachaIdPost(gachaId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gachaDetailApiGachaIdPost(gachaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary gacha/index_api
         * @param {string} [id] 
         * @param {string} [gachaId] 
         * @param {string} [tabIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gachaIndexApiPost(id?: string, gachaId?: string, tabIndex?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gachaIndexApiPost(id, gachaId, tabIndex, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary gacha/register_skip_flag
         * @param {number} [skipFlag] 0&#x3D;アニメーションをスキップする、1&#x3D;アニメーションをスキップしない
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gachaRegisterSkipFlagPost(skipFlag?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gachaRegisterSkipFlagPost(skipFlag, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary gacha/result_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gachaResultApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gachaResultApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary gacha/result_payment_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gachaResultPaymentApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gachaResultPaymentApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GachaApi - factory interface
 * @export
 */
export const GachaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GachaApiFp(configuration)
    return {
        /**
         * 
         * @summary gacha/ajax_gacha_exec_api
         * @param {number} [gachaId] 
         * @param {number} [gachaBtnId] 
         * @param {number} [playCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gachaAjaxGachaExecApiPost(gachaId?: number, gachaBtnId?: number, playCount?: number, options?: any): AxiosPromise<void> {
            return localVarFp.gachaAjaxGachaExecApiPost(gachaId, gachaBtnId, playCount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary gacha/ajax_gacha_exec
         * @param {number} [gachaId] 
         * @param {number} [gachaBtnId] 
         * @param {number} [playCount] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gachaAjaxGachaExecPost(gachaId?: number, gachaBtnId?: number, playCount?: number, options?: any): AxiosPromise<void> {
            return localVarFp.gachaAjaxGachaExecPost(gachaId, gachaBtnId, playCount, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary gacha/contents_api/:cacha_id
         * @param {string} gachaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gachaContentsApiGachaIdPost(gachaId: string, options?: any): AxiosPromise<void> {
            return localVarFp.gachaContentsApiGachaIdPost(gachaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary gacha/detail_api/:gacha_id
         * @param {string} gachaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gachaDetailApiGachaIdPost(gachaId: string, options?: any): AxiosPromise<void> {
            return localVarFp.gachaDetailApiGachaIdPost(gachaId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary gacha/index_api
         * @param {string} [id] 
         * @param {string} [gachaId] 
         * @param {string} [tabIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gachaIndexApiPost(id?: string, gachaId?: string, tabIndex?: string, options?: any): AxiosPromise<void> {
            return localVarFp.gachaIndexApiPost(id, gachaId, tabIndex, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary gacha/register_skip_flag
         * @param {number} [skipFlag] 0&#x3D;アニメーションをスキップする、1&#x3D;アニメーションをスキップしない
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gachaRegisterSkipFlagPost(skipFlag?: number, options?: any): AxiosPromise<void> {
            return localVarFp.gachaRegisterSkipFlagPost(skipFlag, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary gacha/result_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gachaResultApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.gachaResultApiPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary gacha/result_payment_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gachaResultPaymentApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.gachaResultPaymentApiPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GachaApi - object-oriented interface
 * @export
 * @class GachaApi
 * @extends {BaseAPI}
 */
export class GachaApi extends BaseAPI {
    /**
     * 
     * @summary gacha/ajax_gacha_exec_api
     * @param {number} [gachaId] 
     * @param {number} [gachaBtnId] 
     * @param {number} [playCount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GachaApi
     */
    public gachaAjaxGachaExecApiPost(gachaId?: number, gachaBtnId?: number, playCount?: number, options?: AxiosRequestConfig) {
        return GachaApiFp(this.configuration).gachaAjaxGachaExecApiPost(gachaId, gachaBtnId, playCount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary gacha/ajax_gacha_exec
     * @param {number} [gachaId] 
     * @param {number} [gachaBtnId] 
     * @param {number} [playCount] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GachaApi
     */
    public gachaAjaxGachaExecPost(gachaId?: number, gachaBtnId?: number, playCount?: number, options?: AxiosRequestConfig) {
        return GachaApiFp(this.configuration).gachaAjaxGachaExecPost(gachaId, gachaBtnId, playCount, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary gacha/contents_api/:cacha_id
     * @param {string} gachaId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GachaApi
     */
    public gachaContentsApiGachaIdPost(gachaId: string, options?: AxiosRequestConfig) {
        return GachaApiFp(this.configuration).gachaContentsApiGachaIdPost(gachaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary gacha/detail_api/:gacha_id
     * @param {string} gachaId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GachaApi
     */
    public gachaDetailApiGachaIdPost(gachaId: string, options?: AxiosRequestConfig) {
        return GachaApiFp(this.configuration).gachaDetailApiGachaIdPost(gachaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary gacha/index_api
     * @param {string} [id] 
     * @param {string} [gachaId] 
     * @param {string} [tabIndex] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GachaApi
     */
    public gachaIndexApiPost(id?: string, gachaId?: string, tabIndex?: string, options?: AxiosRequestConfig) {
        return GachaApiFp(this.configuration).gachaIndexApiPost(id, gachaId, tabIndex, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary gacha/register_skip_flag
     * @param {number} [skipFlag] 0&#x3D;アニメーションをスキップする、1&#x3D;アニメーションをスキップしない
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GachaApi
     */
    public gachaRegisterSkipFlagPost(skipFlag?: number, options?: AxiosRequestConfig) {
        return GachaApiFp(this.configuration).gachaRegisterSkipFlagPost(skipFlag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary gacha/result_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GachaApi
     */
    public gachaResultApiPost(options?: AxiosRequestConfig) {
        return GachaApiFp(this.configuration).gachaResultApiPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary gacha/result_payment_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GachaApi
     */
    public gachaResultPaymentApiPost(options?: AxiosRequestConfig) {
        return GachaApiFp(this.configuration).gachaResultPaymentApiPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InformationApi - axios parameter creator
 * @export
 */
export const InformationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary information/json
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        informationJsonPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/information/json`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        informationPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/information`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary information/readed
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        informationReadedPost: async (id?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/information/readed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (id !== undefined) { 
                localVarFormParams.append('id', id as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InformationApi - functional programming interface
 * @export
 */
export const InformationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InformationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary information/json
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async informationJsonPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.informationJsonPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async informationPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.informationPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary information/readed
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async informationReadedPost(id?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.informationReadedPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InformationApi - factory interface
 * @export
 */
export const InformationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InformationApiFp(configuration)
    return {
        /**
         * 
         * @summary information/json
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        informationJsonPost(options?: any): AxiosPromise<void> {
            return localVarFp.informationJsonPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        informationPost(options?: any): AxiosPromise<void> {
            return localVarFp.informationPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary information/readed
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        informationReadedPost(id?: number, options?: any): AxiosPromise<void> {
            return localVarFp.informationReadedPost(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InformationApi - object-oriented interface
 * @export
 * @class InformationApi
 * @extends {BaseAPI}
 */
export class InformationApi extends BaseAPI {
    /**
     * 
     * @summary information/json
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InformationApi
     */
    public informationJsonPost(options?: AxiosRequestConfig) {
        return InformationApiFp(this.configuration).informationJsonPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InformationApi
     */
    public informationPost(options?: AxiosRequestConfig) {
        return InformationApiFp(this.configuration).informationPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary information/readed
     * @param {number} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InformationApi
     */
    public informationReadedPost(id?: number, options?: AxiosRequestConfig) {
        return InformationApiFp(this.configuration).informationReadedPost(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * JsMasterApi - axios parameter creator
 * @export
 */
export const JsMasterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary js_master/cards_json/:target_year
         * @param {number} targetYear 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jsMasterCardsJsonTargetYearGet: async (targetYear: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'targetYear' is not null or undefined
            assertParamExists('jsMasterCardsJsonTargetYearGet', 'targetYear', targetYear)
            const localVarPath = `/js_master/cards_json/{target_year}`
                .replace(`{${"target_year"}}`, encodeURIComponent(String(targetYear)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary js_master/cards_packed_ex/:target_year
         * @param {string} targetYear 入力なしだと最新年度のカード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jsMasterCardsPackedExTargetYearGet: async (targetYear: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'targetYear' is not null or undefined
            assertParamExists('jsMasterCardsPackedExTargetYearGet', 'targetYear', targetYear)
            const localVarPath = `/js_master/cards_packed_ex/{target_year}`
                .replace(`{${"target_year"}}`, encodeURIComponent(String(targetYear)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary js_master/cards_packed/:target_year
         * @param {string} targetYear 入力なしだと最新の年度のカード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jsMasterCardsPackedTargetYearGet: async (targetYear: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'targetYear' is not null or undefined
            assertParamExists('jsMasterCardsPackedTargetYearGet', 'targetYear', targetYear)
            const localVarPath = `/js_master/cards_packed/{target_year}`
                .replace(`{${"target_year"}}`, encodeURIComponent(String(targetYear)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary js_master/cards/:target_year
         * @param {string} targetYear 入力なしだと最新の年度のカード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jsMasterCardsTargetYearGet: async (targetYear: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'targetYear' is not null or undefined
            assertParamExists('jsMasterCardsTargetYearGet', 'targetYear', targetYear)
            const localVarPath = `/js_master/cards/{target_year}`
                .replace(`{${"target_year"}}`, encodeURIComponent(String(targetYear)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary js_master/nft_cards_json
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jsMasterNftCardsJsonGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/js_master/nft_cards_json`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JsMasterApi - functional programming interface
 * @export
 */
export const JsMasterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JsMasterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary js_master/cards_json/:target_year
         * @param {number} targetYear 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jsMasterCardsJsonTargetYearGet(targetYear: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jsMasterCardsJsonTargetYearGet(targetYear, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary js_master/cards_packed_ex/:target_year
         * @param {string} targetYear 入力なしだと最新年度のカード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jsMasterCardsPackedExTargetYearGet(targetYear: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jsMasterCardsPackedExTargetYearGet(targetYear, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary js_master/cards_packed/:target_year
         * @param {string} targetYear 入力なしだと最新の年度のカード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jsMasterCardsPackedTargetYearGet(targetYear: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jsMasterCardsPackedTargetYearGet(targetYear, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary js_master/cards/:target_year
         * @param {string} targetYear 入力なしだと最新の年度のカード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jsMasterCardsTargetYearGet(targetYear: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jsMasterCardsTargetYearGet(targetYear, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary js_master/nft_cards_json
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jsMasterNftCardsJsonGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jsMasterNftCardsJsonGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * JsMasterApi - factory interface
 * @export
 */
export const JsMasterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JsMasterApiFp(configuration)
    return {
        /**
         * 
         * @summary js_master/cards_json/:target_year
         * @param {number} targetYear 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jsMasterCardsJsonTargetYearGet(targetYear: number, options?: any): AxiosPromise<void> {
            return localVarFp.jsMasterCardsJsonTargetYearGet(targetYear, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary js_master/cards_packed_ex/:target_year
         * @param {string} targetYear 入力なしだと最新年度のカード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jsMasterCardsPackedExTargetYearGet(targetYear: string, options?: any): AxiosPromise<void> {
            return localVarFp.jsMasterCardsPackedExTargetYearGet(targetYear, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary js_master/cards_packed/:target_year
         * @param {string} targetYear 入力なしだと最新の年度のカード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jsMasterCardsPackedTargetYearGet(targetYear: string, options?: any): AxiosPromise<void> {
            return localVarFp.jsMasterCardsPackedTargetYearGet(targetYear, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary js_master/cards/:target_year
         * @param {string} targetYear 入力なしだと最新の年度のカード
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jsMasterCardsTargetYearGet(targetYear: string, options?: any): AxiosPromise<void> {
            return localVarFp.jsMasterCardsTargetYearGet(targetYear, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary js_master/nft_cards_json
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jsMasterNftCardsJsonGet(options?: any): AxiosPromise<void> {
            return localVarFp.jsMasterNftCardsJsonGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * JsMasterApi - object-oriented interface
 * @export
 * @class JsMasterApi
 * @extends {BaseAPI}
 */
export class JsMasterApi extends BaseAPI {
    /**
     * 
     * @summary js_master/cards_json/:target_year
     * @param {number} targetYear 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JsMasterApi
     */
    public jsMasterCardsJsonTargetYearGet(targetYear: number, options?: AxiosRequestConfig) {
        return JsMasterApiFp(this.configuration).jsMasterCardsJsonTargetYearGet(targetYear, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary js_master/cards_packed_ex/:target_year
     * @param {string} targetYear 入力なしだと最新年度のカード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JsMasterApi
     */
    public jsMasterCardsPackedExTargetYearGet(targetYear: string, options?: AxiosRequestConfig) {
        return JsMasterApiFp(this.configuration).jsMasterCardsPackedExTargetYearGet(targetYear, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary js_master/cards_packed/:target_year
     * @param {string} targetYear 入力なしだと最新の年度のカード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JsMasterApi
     */
    public jsMasterCardsPackedTargetYearGet(targetYear: string, options?: AxiosRequestConfig) {
        return JsMasterApiFp(this.configuration).jsMasterCardsPackedTargetYearGet(targetYear, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary js_master/cards/:target_year
     * @param {string} targetYear 入力なしだと最新の年度のカード
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JsMasterApi
     */
    public jsMasterCardsTargetYearGet(targetYear: string, options?: AxiosRequestConfig) {
        return JsMasterApiFp(this.configuration).jsMasterCardsTargetYearGet(targetYear, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary js_master/nft_cards_json
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JsMasterApi
     */
    public jsMasterNftCardsJsonGet(options?: AxiosRequestConfig) {
        return JsMasterApiFp(this.configuration).jsMasterNftCardsJsonGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LoginApi - axios parameter creator
 * @export
 */
export const LoginApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary login/app_complete
         * @param {string} [cd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAppCompletePost: async (cd?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/login/app_complete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (cd !== undefined) {
                localVarQueryParameter['cd'] = cd;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary login/app_linkage
         * @param {string} [cd] 
         * @param {string} [useId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAppLinkagePost: async (cd?: string, useId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/login/app_linkage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (cd !== undefined) { 
                localVarFormParams.append('cd', cd as any);
            }
    
            if (useId !== undefined) { 
                localVarFormParams.append('use_id', useId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary login/app_start
         * @param {string} [cd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAppStartPost: async (cd?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/login/app_start`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (cd !== undefined) {
                localVarQueryParameter['cd'] = cd;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary login/start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginStartPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/login/start`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoginApi - functional programming interface
 * @export
 */
export const LoginApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoginApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary login/app_complete
         * @param {string} [cd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginAppCompletePost(cd?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginAppCompletePost(cd, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary login/app_linkage
         * @param {string} [cd] 
         * @param {string} [useId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginAppLinkagePost(cd?: string, useId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginAppLinkagePost(cd, useId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary login/app_start
         * @param {string} [cd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginAppStartPost(cd?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginAppStartPost(cd, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary login/start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginStartPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginStartPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoginApi - factory interface
 * @export
 */
export const LoginApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoginApiFp(configuration)
    return {
        /**
         * 
         * @summary login/app_complete
         * @param {string} [cd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAppCompletePost(cd?: string, options?: any): AxiosPromise<void> {
            return localVarFp.loginAppCompletePost(cd, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary login/app_linkage
         * @param {string} [cd] 
         * @param {string} [useId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAppLinkagePost(cd?: string, useId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.loginAppLinkagePost(cd, useId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary login/app_start
         * @param {string} [cd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAppStartPost(cd?: string, options?: any): AxiosPromise<void> {
            return localVarFp.loginAppStartPost(cd, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary login/start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginStartPost(options?: any): AxiosPromise<void> {
            return localVarFp.loginStartPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoginApi - object-oriented interface
 * @export
 * @class LoginApi
 * @extends {BaseAPI}
 */
export class LoginApi extends BaseAPI {
    /**
     * 
     * @summary login/app_complete
     * @param {string} [cd] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public loginAppCompletePost(cd?: string, options?: AxiosRequestConfig) {
        return LoginApiFp(this.configuration).loginAppCompletePost(cd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary login/app_linkage
     * @param {string} [cd] 
     * @param {string} [useId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public loginAppLinkagePost(cd?: string, useId?: string, options?: AxiosRequestConfig) {
        return LoginApiFp(this.configuration).loginAppLinkagePost(cd, useId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary login/app_start
     * @param {string} [cd] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public loginAppStartPost(cd?: string, options?: AxiosRequestConfig) {
        return LoginApiFp(this.configuration).loginAppStartPost(cd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary login/start
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public loginStartPost(options?: AxiosRequestConfig) {
        return LoginApiFp(this.configuration).loginStartPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LotteryApi - axios parameter creator
 * @export
 */
export const LotteryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary ajax_check_login
         * @param {string} [campaignName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lotteryAjaxCheckLoginPost: async (campaignName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/lottery/ajax_check_login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (campaignName !== undefined) { 
                localVarFormParams.append('campaign_name', campaignName as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax_check_result
         * @param {string} [campaignName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lotteryAjaxCheckResultPost: async (campaignName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/lottery/ajax_check_result`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (campaignName !== undefined) { 
                localVarFormParams.append('campaign_name', campaignName as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax_exchange
         * @param {string} [campaignName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lotteryAjaxExchangePost: async (campaignName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/lottery/ajax_exchange`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (campaignName !== undefined) { 
                localVarFormParams.append('campaign_name', campaignName as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax_execute
         * @param {string} [campaignName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lotteryAjaxExecutePost: async (campaignName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/lottery/ajax_execute`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (campaignName !== undefined) { 
                localVarFormParams.append('campaign_name', campaignName as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary animation_api
         * @param {string} [campaignName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lotteryAnimationApiPost: async (campaignName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/lottery/animation_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (campaignName !== undefined) { 
                localVarFormParams.append('campaign_name', campaignName as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary index_api
         * @param {string} [campaignName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lotteryIndexApiPost: async (campaignName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/lottery/index_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (campaignName !== undefined) { 
                localVarFormParams.append('campaign_name', campaignName as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary prize_redemption_api
         * @param {string} [campaignName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lotteryPrizeRedemptionApiPost: async (campaignName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/lottery/prize_redemption_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (campaignName !== undefined) { 
                localVarFormParams.append('campaign_name', campaignName as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary result_api
         * @param {string} [campaignName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lotteryResultApiPost: async (campaignName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/lottery/result_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (campaignName !== undefined) { 
                localVarFormParams.append('campaign_name', campaignName as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary terms_of_service_api
         * @param {string} [campaignName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lotteryTermsOfServiceApiPost: async (campaignName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/lottery/terms_of_service_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (campaignName !== undefined) { 
                localVarFormParams.append('campaign_name', campaignName as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LotteryApi - functional programming interface
 * @export
 */
export const LotteryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LotteryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary ajax_check_login
         * @param {string} [campaignName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lotteryAjaxCheckLoginPost(campaignName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lotteryAjaxCheckLoginPost(campaignName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax_check_result
         * @param {string} [campaignName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lotteryAjaxCheckResultPost(campaignName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lotteryAjaxCheckResultPost(campaignName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax_exchange
         * @param {string} [campaignName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lotteryAjaxExchangePost(campaignName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lotteryAjaxExchangePost(campaignName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax_execute
         * @param {string} [campaignName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lotteryAjaxExecutePost(campaignName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lotteryAjaxExecutePost(campaignName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary animation_api
         * @param {string} [campaignName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lotteryAnimationApiPost(campaignName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lotteryAnimationApiPost(campaignName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary index_api
         * @param {string} [campaignName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lotteryIndexApiPost(campaignName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lotteryIndexApiPost(campaignName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary prize_redemption_api
         * @param {string} [campaignName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lotteryPrizeRedemptionApiPost(campaignName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lotteryPrizeRedemptionApiPost(campaignName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary result_api
         * @param {string} [campaignName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lotteryResultApiPost(campaignName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lotteryResultApiPost(campaignName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary terms_of_service_api
         * @param {string} [campaignName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lotteryTermsOfServiceApiPost(campaignName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lotteryTermsOfServiceApiPost(campaignName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LotteryApi - factory interface
 * @export
 */
export const LotteryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LotteryApiFp(configuration)
    return {
        /**
         * 
         * @summary ajax_check_login
         * @param {string} [campaignName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lotteryAjaxCheckLoginPost(campaignName?: string, options?: any): AxiosPromise<void> {
            return localVarFp.lotteryAjaxCheckLoginPost(campaignName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax_check_result
         * @param {string} [campaignName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lotteryAjaxCheckResultPost(campaignName?: string, options?: any): AxiosPromise<void> {
            return localVarFp.lotteryAjaxCheckResultPost(campaignName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax_exchange
         * @param {string} [campaignName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lotteryAjaxExchangePost(campaignName?: string, options?: any): AxiosPromise<void> {
            return localVarFp.lotteryAjaxExchangePost(campaignName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax_execute
         * @param {string} [campaignName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lotteryAjaxExecutePost(campaignName?: string, options?: any): AxiosPromise<void> {
            return localVarFp.lotteryAjaxExecutePost(campaignName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary animation_api
         * @param {string} [campaignName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lotteryAnimationApiPost(campaignName?: string, options?: any): AxiosPromise<void> {
            return localVarFp.lotteryAnimationApiPost(campaignName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary index_api
         * @param {string} [campaignName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lotteryIndexApiPost(campaignName?: string, options?: any): AxiosPromise<void> {
            return localVarFp.lotteryIndexApiPost(campaignName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary prize_redemption_api
         * @param {string} [campaignName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lotteryPrizeRedemptionApiPost(campaignName?: string, options?: any): AxiosPromise<void> {
            return localVarFp.lotteryPrizeRedemptionApiPost(campaignName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary result_api
         * @param {string} [campaignName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lotteryResultApiPost(campaignName?: string, options?: any): AxiosPromise<void> {
            return localVarFp.lotteryResultApiPost(campaignName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary terms_of_service_api
         * @param {string} [campaignName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lotteryTermsOfServiceApiPost(campaignName?: string, options?: any): AxiosPromise<void> {
            return localVarFp.lotteryTermsOfServiceApiPost(campaignName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LotteryApi - object-oriented interface
 * @export
 * @class LotteryApi
 * @extends {BaseAPI}
 */
export class LotteryApi extends BaseAPI {
    /**
     * 
     * @summary ajax_check_login
     * @param {string} [campaignName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LotteryApi
     */
    public lotteryAjaxCheckLoginPost(campaignName?: string, options?: AxiosRequestConfig) {
        return LotteryApiFp(this.configuration).lotteryAjaxCheckLoginPost(campaignName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax_check_result
     * @param {string} [campaignName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LotteryApi
     */
    public lotteryAjaxCheckResultPost(campaignName?: string, options?: AxiosRequestConfig) {
        return LotteryApiFp(this.configuration).lotteryAjaxCheckResultPost(campaignName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax_exchange
     * @param {string} [campaignName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LotteryApi
     */
    public lotteryAjaxExchangePost(campaignName?: string, options?: AxiosRequestConfig) {
        return LotteryApiFp(this.configuration).lotteryAjaxExchangePost(campaignName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax_execute
     * @param {string} [campaignName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LotteryApi
     */
    public lotteryAjaxExecutePost(campaignName?: string, options?: AxiosRequestConfig) {
        return LotteryApiFp(this.configuration).lotteryAjaxExecutePost(campaignName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary animation_api
     * @param {string} [campaignName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LotteryApi
     */
    public lotteryAnimationApiPost(campaignName?: string, options?: AxiosRequestConfig) {
        return LotteryApiFp(this.configuration).lotteryAnimationApiPost(campaignName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary index_api
     * @param {string} [campaignName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LotteryApi
     */
    public lotteryIndexApiPost(campaignName?: string, options?: AxiosRequestConfig) {
        return LotteryApiFp(this.configuration).lotteryIndexApiPost(campaignName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary prize_redemption_api
     * @param {string} [campaignName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LotteryApi
     */
    public lotteryPrizeRedemptionApiPost(campaignName?: string, options?: AxiosRequestConfig) {
        return LotteryApiFp(this.configuration).lotteryPrizeRedemptionApiPost(campaignName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary result_api
     * @param {string} [campaignName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LotteryApi
     */
    public lotteryResultApiPost(campaignName?: string, options?: AxiosRequestConfig) {
        return LotteryApiFp(this.configuration).lotteryResultApiPost(campaignName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary terms_of_service_api
     * @param {string} [campaignName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LotteryApi
     */
    public lotteryTermsOfServiceApiPost(campaignName?: string, options?: AxiosRequestConfig) {
        return LotteryApiFp(this.configuration).lotteryTermsOfServiceApiPost(campaignName, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MArenaApi - axios parameter creator
 * @export
 */
export const MArenaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary ajax/m_arena/answer_game
         * @param {string} [answer] answer id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxMArenaAnswerGamePost: async (answer?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/m_arena/answer_game`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (answer !== undefined) { 
                localVarFormParams.append('answer', answer as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/m_arena/clear_card
         * @param {string} [slot] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxMArenaClearCardPost: async (slot?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/m_arena/clear_card`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (slot !== undefined) { 
                localVarFormParams.append('slot', slot as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/m_arena/exchange_reward
         * @param {string} [rewardItemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxMArenaExchangeRewardPost: async (rewardItemId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/m_arena/exchange_reward`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (rewardItemId !== undefined) { 
                localVarFormParams.append('reward_item_id', rewardItemId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/m_arena/exec_auto_card_set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxMArenaExecAutoCardSetPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/m_arena/exec_auto_card_set`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/m_arena/get_game_schedule
         * @param {string} [gameId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxMArenaGetGameSchedulePost: async (gameId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/m_arena/get_game_schedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (gameId !== undefined) { 
                localVarFormParams.append('game_id', gameId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/m_arena/get_season_ranks
         * @param {string} [currentId] 
         * @param {string} [isGetUpper] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxMArenaGetSeasonRanksPost: async (currentId?: string, isGetUpper?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/m_arena/get_season_ranks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (currentId !== undefined) { 
                localVarFormParams.append('current_id', currentId as any);
            }
    
            if (isGetUpper !== undefined) { 
                localVarFormParams.append('is_get_upper', isGetUpper as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/m_arena/get_section_ranks
         * @param {string} [currentId] 
         * @param {string} [isGetUpper] 
         * @param {string} [section] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxMArenaGetSectionRanksPost: async (currentId?: string, isGetUpper?: string, section?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/m_arena/get_section_ranks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (currentId !== undefined) { 
                localVarFormParams.append('current_id', currentId as any);
            }
    
            if (isGetUpper !== undefined) { 
                localVarFormParams.append('is_get_upper', isGetUpper as any);
            }
    
            if (section !== undefined) { 
                localVarFormParams.append('section', section as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/m_arena/is_slot_editable
         * @param {string} [slot] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxMArenaIsSlotEditablePost: async (slot?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/m_arena/is_slot_editable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (slot !== undefined) { 
                localVarFormParams.append('slot', slot as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/m_arena/save_cards
         * @param {string} [isForce] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxMArenaSaveCardsPost: async (isForce?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/m_arena/save_cards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (isForce !== undefined) { 
                localVarFormParams.append('is_force', isForce as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ajax/m_arena/select_card
         * @param {string} [cardId] 
         * @param {string} [slot] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxMArenaSelectCardPost: async (cardId?: string, slot?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/m_arena/select_card`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (cardId !== undefined) { 
                localVarFormParams.append('card_id', cardId as any);
            }
    
            if (slot !== undefined) { 
                localVarFormParams.append('slot', slot as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary m_arena/bonus_detail_api
         * @param {number} [game] 
         * @param {string} [isHistory] 
         * @param {string} [isOpponent] 
         * @param {string} [uid] 
         * @param {string} [isRefSeason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mArenaBonusDetailApiPost: async (game?: number, isHistory?: string, isOpponent?: string, uid?: string, isRefSeason?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/m_arena/bonus_detail_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (game !== undefined) {
                localVarQueryParameter['game'] = game;
            }

            if (isHistory !== undefined) {
                localVarQueryParameter['is_history'] = isHistory;
            }

            if (isOpponent !== undefined) {
                localVarQueryParameter['is_opponent'] = isOpponent;
            }

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (isRefSeason !== undefined) {
                localVarQueryParameter['is_ref_season'] = isRefSeason;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary m_arena/deck_api
         * @param {string} [mode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mArenaDeckApiPost: async (mode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/m_arena/deck_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (mode !== undefined) {
                localVarQueryParameter['mode'] = mode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary m_arena/game_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mArenaGameApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/m_arena/game_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary m_arena/game_menu_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mArenaGameMenuApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/m_arena/game_menu_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary m_arena/game_result_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mArenaGameResultApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/m_arena/game_result_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary m_arena/history_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mArenaHistoryApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/m_arena/history_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary m_arena/index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mArenaIndexApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/m_arena/index_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary m_arena/ranking_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mArenaRankingApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/m_arena/ranking_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary m_arena/result_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mArenaResultApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/m_arena/result_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary m_arena/reward_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mArenaRewardApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/m_arena/reward_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary m_arena/score_api
         * @param {number} [game] 
         * @param {string} [uid] 
         * @param {string} [isRefSeason] 
         * @param {string} [isHistory] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mArenaScoreApiPost: async (game?: number, uid?: string, isRefSeason?: string, isHistory?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/m_arena/score_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (game !== undefined) {
                localVarQueryParameter['game'] = game;
            }

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (isRefSeason !== undefined) {
                localVarQueryParameter['is_ref_season'] = isRefSeason;
            }

            if (isHistory !== undefined) {
                localVarQueryParameter['is_history'] = isHistory;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary m_arena/score_detail_api
         * @param {string} [slot] 
         * @param {number} [game] 
         * @param {string} [isHistory] 
         * @param {string} [isOpponent] 
         * @param {string} [uid] 
         * @param {string} [isRefSeason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mArenaScoreDetailApiPost: async (slot?: string, game?: number, isHistory?: string, isOpponent?: string, uid?: string, isRefSeason?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/m_arena/score_detail_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (slot !== undefined) {
                localVarQueryParameter['slot'] = slot;
            }

            if (game !== undefined) {
                localVarQueryParameter['game'] = game;
            }

            if (isHistory !== undefined) {
                localVarQueryParameter['is_history'] = isHistory;
            }

            if (isOpponent !== undefined) {
                localVarQueryParameter['is_opponent'] = isOpponent;
            }

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (isRefSeason !== undefined) {
                localVarQueryParameter['is_ref_season'] = isRefSeason;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary m_arena/score_list_api
         * @param {number} [game] 
         * @param {string} [isHistory] 
         * @param {string} [isOpponent] 
         * @param {string} [uid] 
         * @param {string} [isRefSeason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mArenaScoreListApiPost: async (game?: number, isHistory?: string, isOpponent?: string, uid?: string, isRefSeason?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/m_arena/score_list_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (game !== undefined) {
                localVarQueryParameter['game'] = game;
            }

            if (isHistory !== undefined) {
                localVarQueryParameter['is_history'] = isHistory;
            }

            if (isOpponent !== undefined) {
                localVarQueryParameter['is_opponent'] = isOpponent;
            }

            if (uid !== undefined) {
                localVarQueryParameter['uid'] = uid;
            }

            if (isRefSeason !== undefined) {
                localVarQueryParameter['is_ref_season'] = isRefSeason;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary m_arena/season_ranking_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mArenaSeasonRankingApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/m_arena/season_ranking_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary m_arena/select_card_api
         * @param {number} [slot] 1, 2, 3, 4, 5(PG, SG, SF, PF, C)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mArenaSelectCardApiPost: async (slot?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/m_arena/select_card_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (slot !== undefined) {
                localVarQueryParameter['slot'] = slot;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary m_arena/select_mode_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mArenaSelectModeApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/m_arena/select_mode_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary m_arena/special_bonus_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mArenaSpecialBonusApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/m_arena/special_bonus_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary m_arena/start_game_api
         * @param {string} [r] return url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mArenaStartGameApiPost: async (r?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/m_arena/start_game_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (r !== undefined) {
                localVarQueryParameter['r'] = r;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary m_arena/tutorial_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mArenaTutorialApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/m_arena/tutorial_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary m_arena/unavailable_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mArenaUnavailableApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/m_arena/unavailable_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MArenaApi - functional programming interface
 * @export
 */
export const MArenaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MArenaApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary ajax/m_arena/answer_game
         * @param {string} [answer] answer id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxMArenaAnswerGamePost(answer?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxMArenaAnswerGamePost(answer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/m_arena/clear_card
         * @param {string} [slot] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxMArenaClearCardPost(slot?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxMArenaClearCardPost(slot, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/m_arena/exchange_reward
         * @param {string} [rewardItemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxMArenaExchangeRewardPost(rewardItemId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxMArenaExchangeRewardPost(rewardItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/m_arena/exec_auto_card_set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxMArenaExecAutoCardSetPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxMArenaExecAutoCardSetPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/m_arena/get_game_schedule
         * @param {string} [gameId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxMArenaGetGameSchedulePost(gameId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxMArenaGetGameSchedulePost(gameId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/m_arena/get_season_ranks
         * @param {string} [currentId] 
         * @param {string} [isGetUpper] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxMArenaGetSeasonRanksPost(currentId?: string, isGetUpper?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxMArenaGetSeasonRanksPost(currentId, isGetUpper, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/m_arena/get_section_ranks
         * @param {string} [currentId] 
         * @param {string} [isGetUpper] 
         * @param {string} [section] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxMArenaGetSectionRanksPost(currentId?: string, isGetUpper?: string, section?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxMArenaGetSectionRanksPost(currentId, isGetUpper, section, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/m_arena/is_slot_editable
         * @param {string} [slot] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxMArenaIsSlotEditablePost(slot?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxMArenaIsSlotEditablePost(slot, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/m_arena/save_cards
         * @param {string} [isForce] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxMArenaSaveCardsPost(isForce?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxMArenaSaveCardsPost(isForce, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ajax/m_arena/select_card
         * @param {string} [cardId] 
         * @param {string} [slot] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxMArenaSelectCardPost(cardId?: string, slot?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxMArenaSelectCardPost(cardId, slot, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary m_arena/bonus_detail_api
         * @param {number} [game] 
         * @param {string} [isHistory] 
         * @param {string} [isOpponent] 
         * @param {string} [uid] 
         * @param {string} [isRefSeason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mArenaBonusDetailApiPost(game?: number, isHistory?: string, isOpponent?: string, uid?: string, isRefSeason?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mArenaBonusDetailApiPost(game, isHistory, isOpponent, uid, isRefSeason, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary m_arena/deck_api
         * @param {string} [mode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mArenaDeckApiPost(mode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mArenaDeckApiPost(mode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary m_arena/game_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mArenaGameApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mArenaGameApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary m_arena/game_menu_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mArenaGameMenuApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mArenaGameMenuApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary m_arena/game_result_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mArenaGameResultApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mArenaGameResultApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary m_arena/history_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mArenaHistoryApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mArenaHistoryApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary m_arena/index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mArenaIndexApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mArenaIndexApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary m_arena/ranking_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mArenaRankingApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mArenaRankingApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary m_arena/result_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mArenaResultApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mArenaResultApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary m_arena/reward_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mArenaRewardApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mArenaRewardApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary m_arena/score_api
         * @param {number} [game] 
         * @param {string} [uid] 
         * @param {string} [isRefSeason] 
         * @param {string} [isHistory] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mArenaScoreApiPost(game?: number, uid?: string, isRefSeason?: string, isHistory?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mArenaScoreApiPost(game, uid, isRefSeason, isHistory, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary m_arena/score_detail_api
         * @param {string} [slot] 
         * @param {number} [game] 
         * @param {string} [isHistory] 
         * @param {string} [isOpponent] 
         * @param {string} [uid] 
         * @param {string} [isRefSeason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mArenaScoreDetailApiPost(slot?: string, game?: number, isHistory?: string, isOpponent?: string, uid?: string, isRefSeason?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mArenaScoreDetailApiPost(slot, game, isHistory, isOpponent, uid, isRefSeason, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary m_arena/score_list_api
         * @param {number} [game] 
         * @param {string} [isHistory] 
         * @param {string} [isOpponent] 
         * @param {string} [uid] 
         * @param {string} [isRefSeason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mArenaScoreListApiPost(game?: number, isHistory?: string, isOpponent?: string, uid?: string, isRefSeason?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mArenaScoreListApiPost(game, isHistory, isOpponent, uid, isRefSeason, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary m_arena/season_ranking_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mArenaSeasonRankingApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mArenaSeasonRankingApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary m_arena/select_card_api
         * @param {number} [slot] 1, 2, 3, 4, 5(PG, SG, SF, PF, C)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mArenaSelectCardApiPost(slot?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mArenaSelectCardApiPost(slot, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary m_arena/select_mode_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mArenaSelectModeApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mArenaSelectModeApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary m_arena/special_bonus_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mArenaSpecialBonusApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mArenaSpecialBonusApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary m_arena/start_game_api
         * @param {string} [r] return url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mArenaStartGameApiPost(r?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mArenaStartGameApiPost(r, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary m_arena/tutorial_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mArenaTutorialApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mArenaTutorialApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary m_arena/unavailable_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mArenaUnavailableApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mArenaUnavailableApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MArenaApi - factory interface
 * @export
 */
export const MArenaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MArenaApiFp(configuration)
    return {
        /**
         * 
         * @summary ajax/m_arena/answer_game
         * @param {string} [answer] answer id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxMArenaAnswerGamePost(answer?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxMArenaAnswerGamePost(answer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/m_arena/clear_card
         * @param {string} [slot] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxMArenaClearCardPost(slot?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxMArenaClearCardPost(slot, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/m_arena/exchange_reward
         * @param {string} [rewardItemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxMArenaExchangeRewardPost(rewardItemId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxMArenaExchangeRewardPost(rewardItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/m_arena/exec_auto_card_set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxMArenaExecAutoCardSetPost(options?: any): AxiosPromise<void> {
            return localVarFp.ajaxMArenaExecAutoCardSetPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/m_arena/get_game_schedule
         * @param {string} [gameId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxMArenaGetGameSchedulePost(gameId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxMArenaGetGameSchedulePost(gameId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/m_arena/get_season_ranks
         * @param {string} [currentId] 
         * @param {string} [isGetUpper] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxMArenaGetSeasonRanksPost(currentId?: string, isGetUpper?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxMArenaGetSeasonRanksPost(currentId, isGetUpper, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/m_arena/get_section_ranks
         * @param {string} [currentId] 
         * @param {string} [isGetUpper] 
         * @param {string} [section] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxMArenaGetSectionRanksPost(currentId?: string, isGetUpper?: string, section?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxMArenaGetSectionRanksPost(currentId, isGetUpper, section, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/m_arena/is_slot_editable
         * @param {string} [slot] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxMArenaIsSlotEditablePost(slot?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxMArenaIsSlotEditablePost(slot, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/m_arena/save_cards
         * @param {string} [isForce] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxMArenaSaveCardsPost(isForce?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxMArenaSaveCardsPost(isForce, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ajax/m_arena/select_card
         * @param {string} [cardId] 
         * @param {string} [slot] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxMArenaSelectCardPost(cardId?: string, slot?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxMArenaSelectCardPost(cardId, slot, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary m_arena/bonus_detail_api
         * @param {number} [game] 
         * @param {string} [isHistory] 
         * @param {string} [isOpponent] 
         * @param {string} [uid] 
         * @param {string} [isRefSeason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mArenaBonusDetailApiPost(game?: number, isHistory?: string, isOpponent?: string, uid?: string, isRefSeason?: string, options?: any): AxiosPromise<void> {
            return localVarFp.mArenaBonusDetailApiPost(game, isHistory, isOpponent, uid, isRefSeason, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary m_arena/deck_api
         * @param {string} [mode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mArenaDeckApiPost(mode?: string, options?: any): AxiosPromise<void> {
            return localVarFp.mArenaDeckApiPost(mode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary m_arena/game_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mArenaGameApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.mArenaGameApiPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary m_arena/game_menu_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mArenaGameMenuApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.mArenaGameMenuApiPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary m_arena/game_result_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mArenaGameResultApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.mArenaGameResultApiPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary m_arena/history_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mArenaHistoryApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.mArenaHistoryApiPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary m_arena/index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mArenaIndexApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.mArenaIndexApiPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary m_arena/ranking_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mArenaRankingApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.mArenaRankingApiPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary m_arena/result_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mArenaResultApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.mArenaResultApiPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary m_arena/reward_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mArenaRewardApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.mArenaRewardApiPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary m_arena/score_api
         * @param {number} [game] 
         * @param {string} [uid] 
         * @param {string} [isRefSeason] 
         * @param {string} [isHistory] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mArenaScoreApiPost(game?: number, uid?: string, isRefSeason?: string, isHistory?: string, options?: any): AxiosPromise<void> {
            return localVarFp.mArenaScoreApiPost(game, uid, isRefSeason, isHistory, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary m_arena/score_detail_api
         * @param {string} [slot] 
         * @param {number} [game] 
         * @param {string} [isHistory] 
         * @param {string} [isOpponent] 
         * @param {string} [uid] 
         * @param {string} [isRefSeason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mArenaScoreDetailApiPost(slot?: string, game?: number, isHistory?: string, isOpponent?: string, uid?: string, isRefSeason?: string, options?: any): AxiosPromise<void> {
            return localVarFp.mArenaScoreDetailApiPost(slot, game, isHistory, isOpponent, uid, isRefSeason, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary m_arena/score_list_api
         * @param {number} [game] 
         * @param {string} [isHistory] 
         * @param {string} [isOpponent] 
         * @param {string} [uid] 
         * @param {string} [isRefSeason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mArenaScoreListApiPost(game?: number, isHistory?: string, isOpponent?: string, uid?: string, isRefSeason?: string, options?: any): AxiosPromise<void> {
            return localVarFp.mArenaScoreListApiPost(game, isHistory, isOpponent, uid, isRefSeason, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary m_arena/season_ranking_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mArenaSeasonRankingApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.mArenaSeasonRankingApiPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary m_arena/select_card_api
         * @param {number} [slot] 1, 2, 3, 4, 5(PG, SG, SF, PF, C)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mArenaSelectCardApiPost(slot?: number, options?: any): AxiosPromise<void> {
            return localVarFp.mArenaSelectCardApiPost(slot, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary m_arena/select_mode_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mArenaSelectModeApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.mArenaSelectModeApiPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary m_arena/special_bonus_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mArenaSpecialBonusApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.mArenaSpecialBonusApiPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary m_arena/start_game_api
         * @param {string} [r] return url
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mArenaStartGameApiPost(r?: string, options?: any): AxiosPromise<void> {
            return localVarFp.mArenaStartGameApiPost(r, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary m_arena/tutorial_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mArenaTutorialApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.mArenaTutorialApiPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary m_arena/unavailable_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mArenaUnavailableApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.mArenaUnavailableApiPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MArenaApi - object-oriented interface
 * @export
 * @class MArenaApi
 * @extends {BaseAPI}
 */
export class MArenaApi extends BaseAPI {
    /**
     * 
     * @summary ajax/m_arena/answer_game
     * @param {string} [answer] answer id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MArenaApi
     */
    public ajaxMArenaAnswerGamePost(answer?: string, options?: AxiosRequestConfig) {
        return MArenaApiFp(this.configuration).ajaxMArenaAnswerGamePost(answer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/m_arena/clear_card
     * @param {string} [slot] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MArenaApi
     */
    public ajaxMArenaClearCardPost(slot?: string, options?: AxiosRequestConfig) {
        return MArenaApiFp(this.configuration).ajaxMArenaClearCardPost(slot, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/m_arena/exchange_reward
     * @param {string} [rewardItemId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MArenaApi
     */
    public ajaxMArenaExchangeRewardPost(rewardItemId?: string, options?: AxiosRequestConfig) {
        return MArenaApiFp(this.configuration).ajaxMArenaExchangeRewardPost(rewardItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/m_arena/exec_auto_card_set
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MArenaApi
     */
    public ajaxMArenaExecAutoCardSetPost(options?: AxiosRequestConfig) {
        return MArenaApiFp(this.configuration).ajaxMArenaExecAutoCardSetPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/m_arena/get_game_schedule
     * @param {string} [gameId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MArenaApi
     */
    public ajaxMArenaGetGameSchedulePost(gameId?: string, options?: AxiosRequestConfig) {
        return MArenaApiFp(this.configuration).ajaxMArenaGetGameSchedulePost(gameId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/m_arena/get_season_ranks
     * @param {string} [currentId] 
     * @param {string} [isGetUpper] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MArenaApi
     */
    public ajaxMArenaGetSeasonRanksPost(currentId?: string, isGetUpper?: string, options?: AxiosRequestConfig) {
        return MArenaApiFp(this.configuration).ajaxMArenaGetSeasonRanksPost(currentId, isGetUpper, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/m_arena/get_section_ranks
     * @param {string} [currentId] 
     * @param {string} [isGetUpper] 
     * @param {string} [section] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MArenaApi
     */
    public ajaxMArenaGetSectionRanksPost(currentId?: string, isGetUpper?: string, section?: string, options?: AxiosRequestConfig) {
        return MArenaApiFp(this.configuration).ajaxMArenaGetSectionRanksPost(currentId, isGetUpper, section, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/m_arena/is_slot_editable
     * @param {string} [slot] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MArenaApi
     */
    public ajaxMArenaIsSlotEditablePost(slot?: string, options?: AxiosRequestConfig) {
        return MArenaApiFp(this.configuration).ajaxMArenaIsSlotEditablePost(slot, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/m_arena/save_cards
     * @param {string} [isForce] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MArenaApi
     */
    public ajaxMArenaSaveCardsPost(isForce?: string, options?: AxiosRequestConfig) {
        return MArenaApiFp(this.configuration).ajaxMArenaSaveCardsPost(isForce, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ajax/m_arena/select_card
     * @param {string} [cardId] 
     * @param {string} [slot] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MArenaApi
     */
    public ajaxMArenaSelectCardPost(cardId?: string, slot?: string, options?: AxiosRequestConfig) {
        return MArenaApiFp(this.configuration).ajaxMArenaSelectCardPost(cardId, slot, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary m_arena/bonus_detail_api
     * @param {number} [game] 
     * @param {string} [isHistory] 
     * @param {string} [isOpponent] 
     * @param {string} [uid] 
     * @param {string} [isRefSeason] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MArenaApi
     */
    public mArenaBonusDetailApiPost(game?: number, isHistory?: string, isOpponent?: string, uid?: string, isRefSeason?: string, options?: AxiosRequestConfig) {
        return MArenaApiFp(this.configuration).mArenaBonusDetailApiPost(game, isHistory, isOpponent, uid, isRefSeason, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary m_arena/deck_api
     * @param {string} [mode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MArenaApi
     */
    public mArenaDeckApiPost(mode?: string, options?: AxiosRequestConfig) {
        return MArenaApiFp(this.configuration).mArenaDeckApiPost(mode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary m_arena/game_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MArenaApi
     */
    public mArenaGameApiPost(options?: AxiosRequestConfig) {
        return MArenaApiFp(this.configuration).mArenaGameApiPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary m_arena/game_menu_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MArenaApi
     */
    public mArenaGameMenuApiPost(options?: AxiosRequestConfig) {
        return MArenaApiFp(this.configuration).mArenaGameMenuApiPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary m_arena/game_result_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MArenaApi
     */
    public mArenaGameResultApiPost(options?: AxiosRequestConfig) {
        return MArenaApiFp(this.configuration).mArenaGameResultApiPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary m_arena/history_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MArenaApi
     */
    public mArenaHistoryApiPost(options?: AxiosRequestConfig) {
        return MArenaApiFp(this.configuration).mArenaHistoryApiPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary m_arena/index_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MArenaApi
     */
    public mArenaIndexApiPost(options?: AxiosRequestConfig) {
        return MArenaApiFp(this.configuration).mArenaIndexApiPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary m_arena/ranking_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MArenaApi
     */
    public mArenaRankingApiPost(options?: AxiosRequestConfig) {
        return MArenaApiFp(this.configuration).mArenaRankingApiPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary m_arena/result_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MArenaApi
     */
    public mArenaResultApiPost(options?: AxiosRequestConfig) {
        return MArenaApiFp(this.configuration).mArenaResultApiPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary m_arena/reward_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MArenaApi
     */
    public mArenaRewardApiPost(options?: AxiosRequestConfig) {
        return MArenaApiFp(this.configuration).mArenaRewardApiPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary m_arena/score_api
     * @param {number} [game] 
     * @param {string} [uid] 
     * @param {string} [isRefSeason] 
     * @param {string} [isHistory] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MArenaApi
     */
    public mArenaScoreApiPost(game?: number, uid?: string, isRefSeason?: string, isHistory?: string, options?: AxiosRequestConfig) {
        return MArenaApiFp(this.configuration).mArenaScoreApiPost(game, uid, isRefSeason, isHistory, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary m_arena/score_detail_api
     * @param {string} [slot] 
     * @param {number} [game] 
     * @param {string} [isHistory] 
     * @param {string} [isOpponent] 
     * @param {string} [uid] 
     * @param {string} [isRefSeason] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MArenaApi
     */
    public mArenaScoreDetailApiPost(slot?: string, game?: number, isHistory?: string, isOpponent?: string, uid?: string, isRefSeason?: string, options?: AxiosRequestConfig) {
        return MArenaApiFp(this.configuration).mArenaScoreDetailApiPost(slot, game, isHistory, isOpponent, uid, isRefSeason, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary m_arena/score_list_api
     * @param {number} [game] 
     * @param {string} [isHistory] 
     * @param {string} [isOpponent] 
     * @param {string} [uid] 
     * @param {string} [isRefSeason] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MArenaApi
     */
    public mArenaScoreListApiPost(game?: number, isHistory?: string, isOpponent?: string, uid?: string, isRefSeason?: string, options?: AxiosRequestConfig) {
        return MArenaApiFp(this.configuration).mArenaScoreListApiPost(game, isHistory, isOpponent, uid, isRefSeason, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary m_arena/season_ranking_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MArenaApi
     */
    public mArenaSeasonRankingApiPost(options?: AxiosRequestConfig) {
        return MArenaApiFp(this.configuration).mArenaSeasonRankingApiPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary m_arena/select_card_api
     * @param {number} [slot] 1, 2, 3, 4, 5(PG, SG, SF, PF, C)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MArenaApi
     */
    public mArenaSelectCardApiPost(slot?: number, options?: AxiosRequestConfig) {
        return MArenaApiFp(this.configuration).mArenaSelectCardApiPost(slot, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary m_arena/select_mode_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MArenaApi
     */
    public mArenaSelectModeApiPost(options?: AxiosRequestConfig) {
        return MArenaApiFp(this.configuration).mArenaSelectModeApiPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary m_arena/special_bonus_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MArenaApi
     */
    public mArenaSpecialBonusApiPost(options?: AxiosRequestConfig) {
        return MArenaApiFp(this.configuration).mArenaSpecialBonusApiPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary m_arena/start_game_api
     * @param {string} [r] return url
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MArenaApi
     */
    public mArenaStartGameApiPost(r?: string, options?: AxiosRequestConfig) {
        return MArenaApiFp(this.configuration).mArenaStartGameApiPost(r, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary m_arena/tutorial_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MArenaApi
     */
    public mArenaTutorialApiPost(options?: AxiosRequestConfig) {
        return MArenaApiFp(this.configuration).mArenaTutorialApiPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary m_arena/unavailable_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MArenaApi
     */
    public mArenaUnavailableApiPost(options?: AxiosRequestConfig) {
        return MArenaApiFp(this.configuration).mArenaUnavailableApiPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MascotVoteApi - axios parameter creator
 * @export
 */
export const MascotVoteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary mascot_vote/ajax_vote_api
         * @param {string} [cardId] 
         * @param {string} [deckId] 
         * @param {string} [voteIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mascotVoteAjaxVoteApiPost: async (cardId?: string, deckId?: string, voteIndex?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mascot_vote/ajax_vote_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (cardId !== undefined) { 
                localVarFormParams.append('card_id', cardId as any);
            }
    
            if (deckId !== undefined) { 
                localVarFormParams.append('deck_id', deckId as any);
            }
    
            if (voteIndex !== undefined) { 
                localVarFormParams.append('vote_index', voteIndex as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary mascot_vote/index_api
         * @param {string} [targetYear] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mascotVoteIndexApiPost: async (targetYear?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mascot_vote/index_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (targetYear !== undefined) {
                localVarQueryParameter['target_year'] = targetYear;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MascotVoteApi - functional programming interface
 * @export
 */
export const MascotVoteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MascotVoteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary mascot_vote/ajax_vote_api
         * @param {string} [cardId] 
         * @param {string} [deckId] 
         * @param {string} [voteIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mascotVoteAjaxVoteApiPost(cardId?: string, deckId?: string, voteIndex?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mascotVoteAjaxVoteApiPost(cardId, deckId, voteIndex, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary mascot_vote/index_api
         * @param {string} [targetYear] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mascotVoteIndexApiPost(targetYear?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mascotVoteIndexApiPost(targetYear, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MascotVoteApi - factory interface
 * @export
 */
export const MascotVoteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MascotVoteApiFp(configuration)
    return {
        /**
         * 
         * @summary mascot_vote/ajax_vote_api
         * @param {string} [cardId] 
         * @param {string} [deckId] 
         * @param {string} [voteIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mascotVoteAjaxVoteApiPost(cardId?: string, deckId?: string, voteIndex?: string, options?: any): AxiosPromise<void> {
            return localVarFp.mascotVoteAjaxVoteApiPost(cardId, deckId, voteIndex, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary mascot_vote/index_api
         * @param {string} [targetYear] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mascotVoteIndexApiPost(targetYear?: string, options?: any): AxiosPromise<void> {
            return localVarFp.mascotVoteIndexApiPost(targetYear, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MascotVoteApi - object-oriented interface
 * @export
 * @class MascotVoteApi
 * @extends {BaseAPI}
 */
export class MascotVoteApi extends BaseAPI {
    /**
     * 
     * @summary mascot_vote/ajax_vote_api
     * @param {string} [cardId] 
     * @param {string} [deckId] 
     * @param {string} [voteIndex] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MascotVoteApi
     */
    public mascotVoteAjaxVoteApiPost(cardId?: string, deckId?: string, voteIndex?: string, options?: AxiosRequestConfig) {
        return MascotVoteApiFp(this.configuration).mascotVoteAjaxVoteApiPost(cardId, deckId, voteIndex, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary mascot_vote/index_api
     * @param {string} [targetYear] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MascotVoteApi
     */
    public mascotVoteIndexApiPost(targetYear?: string, options?: AxiosRequestConfig) {
        return MascotVoteApiFp(this.configuration).mascotVoteIndexApiPost(targetYear, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MatchRewardApi - axios parameter creator
 * @export
 */
export const MatchRewardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary match_reward/index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matchRewardIndexApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/match_reward/index_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MatchRewardApi - functional programming interface
 * @export
 */
export const MatchRewardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MatchRewardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary match_reward/index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async matchRewardIndexApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.matchRewardIndexApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MatchRewardApi - factory interface
 * @export
 */
export const MatchRewardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MatchRewardApiFp(configuration)
    return {
        /**
         * 
         * @summary match_reward/index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matchRewardIndexApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.matchRewardIndexApiPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MatchRewardApi - object-oriented interface
 * @export
 * @class MatchRewardApi
 * @extends {BaseAPI}
 */
export class MatchRewardApi extends BaseAPI {
    /**
     * 
     * @summary match_reward/index_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatchRewardApi
     */
    public matchRewardIndexApiPost(options?: AxiosRequestConfig) {
        return MatchRewardApiFp(this.configuration).matchRewardIndexApiPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MemberApi - axios parameter creator
 * @export
 */
export const MemberApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary member/index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberIndexApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member/index_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MemberApi - functional programming interface
 * @export
 */
export const MemberApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MemberApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary member/index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberIndexApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberIndexApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MemberApi - factory interface
 * @export
 */
export const MemberApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MemberApiFp(configuration)
    return {
        /**
         * 
         * @summary member/index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberIndexApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.memberIndexApiPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MemberApi - object-oriented interface
 * @export
 * @class MemberApi
 * @extends {BaseAPI}
 */
export class MemberApi extends BaseAPI {
    /**
     * 
     * @summary member/index_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberApi
     */
    public memberIndexApiPost(options?: AxiosRequestConfig) {
        return MemberApiFp(this.configuration).memberIndexApiPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MemberAccountTransferApi - axios parameter creator
 * @export
 */
export const MemberAccountTransferApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary member_account_transfer/index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberAccountTransferIndexApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member_account_transfer/index_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary member_account_transfer/notes_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberAccountTransferNotesApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member_account_transfer/notes_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MemberAccountTransferApi - functional programming interface
 * @export
 */
export const MemberAccountTransferApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MemberAccountTransferApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary member_account_transfer/index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberAccountTransferIndexApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberAccountTransferIndexApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary member_account_transfer/notes_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberAccountTransferNotesApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberAccountTransferNotesApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MemberAccountTransferApi - factory interface
 * @export
 */
export const MemberAccountTransferApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MemberAccountTransferApiFp(configuration)
    return {
        /**
         * 
         * @summary member_account_transfer/index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberAccountTransferIndexApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.memberAccountTransferIndexApiPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary member_account_transfer/notes_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberAccountTransferNotesApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.memberAccountTransferNotesApiPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MemberAccountTransferApi - object-oriented interface
 * @export
 * @class MemberAccountTransferApi
 * @extends {BaseAPI}
 */
export class MemberAccountTransferApi extends BaseAPI {
    /**
     * 
     * @summary member_account_transfer/index_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberAccountTransferApi
     */
    public memberAccountTransferIndexApiPost(options?: AxiosRequestConfig) {
        return MemberAccountTransferApiFp(this.configuration).memberAccountTransferIndexApiPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary member_account_transfer/notes_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberAccountTransferApi
     */
    public memberAccountTransferNotesApiPost(options?: AxiosRequestConfig) {
        return MemberAccountTransferApiFp(this.configuration).memberAccountTransferNotesApiPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MemberRegisterApi - axios parameter creator
 * @export
 */
export const MemberRegisterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary member_register/index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberRegisterIndexApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member_register/index_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary member_register/notes_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberRegisterNotesApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/member_register/notes_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MemberRegisterApi - functional programming interface
 * @export
 */
export const MemberRegisterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MemberRegisterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary member_register/index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberRegisterIndexApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberRegisterIndexApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary member_register/notes_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async memberRegisterNotesApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.memberRegisterNotesApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MemberRegisterApi - factory interface
 * @export
 */
export const MemberRegisterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MemberRegisterApiFp(configuration)
    return {
        /**
         * 
         * @summary member_register/index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberRegisterIndexApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.memberRegisterIndexApiPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary member_register/notes_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        memberRegisterNotesApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.memberRegisterNotesApiPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MemberRegisterApi - object-oriented interface
 * @export
 * @class MemberRegisterApi
 * @extends {BaseAPI}
 */
export class MemberRegisterApi extends BaseAPI {
    /**
     * 
     * @summary member_register/index_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberRegisterApi
     */
    public memberRegisterIndexApiPost(options?: AxiosRequestConfig) {
        return MemberRegisterApiFp(this.configuration).memberRegisterIndexApiPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary member_register/notes_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MemberRegisterApi
     */
    public memberRegisterNotesApiPost(options?: AxiosRequestConfig) {
        return MemberRegisterApiFp(this.configuration).memberRegisterNotesApiPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MenuApi - axios parameter creator
 * @export
 */
export const MenuApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary menu/index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        menuIndexApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/menu/index_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MenuApi - functional programming interface
 * @export
 */
export const MenuApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MenuApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary menu/index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async menuIndexApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.menuIndexApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MenuApi - factory interface
 * @export
 */
export const MenuApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MenuApiFp(configuration)
    return {
        /**
         * 
         * @summary menu/index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        menuIndexApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.menuIndexApiPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MenuApi - object-oriented interface
 * @export
 * @class MenuApi
 * @extends {BaseAPI}
 */
export class MenuApi extends BaseAPI {
    /**
     * 
     * @summary menu/index_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MenuApi
     */
    public menuIndexApiPost(options?: AxiosRequestConfig) {
        return MenuApiFp(this.configuration).menuIndexApiPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MinimailApi - axios parameter creator
 * @export
 */
export const MinimailApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary minimail/index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        minimailIndexApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/minimail/index_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MinimailApi - functional programming interface
 * @export
 */
export const MinimailApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MinimailApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary minimail/index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async minimailIndexApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.minimailIndexApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MinimailApi - factory interface
 * @export
 */
export const MinimailApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MinimailApiFp(configuration)
    return {
        /**
         * 
         * @summary minimail/index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        minimailIndexApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.minimailIndexApiPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MinimailApi - object-oriented interface
 * @export
 * @class MinimailApi
 * @extends {BaseAPI}
 */
export class MinimailApi extends BaseAPI {
    /**
     * 
     * @summary minimail/index_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinimailApi
     */
    public minimailIndexApiPost(options?: AxiosRequestConfig) {
        return MinimailApiFp(this.configuration).minimailIndexApiPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MissionApi - axios parameter creator
 * @export
 */
export const MissionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary background_api
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionBackgroundApiPost: async (page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mission/background_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary mission/complete_api
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionCompleteApiPost: async (page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mission/complete_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary mission/index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionIndexApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mission/index_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary mission/monthly_api
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionMonthlyApiPost: async (page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mission/monthly_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary mission/receive_bonus_all_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionReceiveBonusAllApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mission/receive_bonus_all_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication noauthAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary mission/receive_bonus_api
         * @param {string} [missionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionReceiveBonusApiPost: async (missionId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mission/receive_bonus_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication noauthAuth required

            if (missionId !== undefined) {
                localVarQueryParameter['mission_id'] = missionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary mission/season_api
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionSeasonApiPost: async (page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mission/season_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary title_api
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionTitleApiPost: async (page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/mission/title_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MissionApi - functional programming interface
 * @export
 */
export const MissionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MissionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary background_api
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async missionBackgroundApiPost(page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.missionBackgroundApiPost(page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary mission/complete_api
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async missionCompleteApiPost(page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.missionCompleteApiPost(page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary mission/index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async missionIndexApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.missionIndexApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary mission/monthly_api
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async missionMonthlyApiPost(page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.missionMonthlyApiPost(page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary mission/receive_bonus_all_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async missionReceiveBonusAllApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.missionReceiveBonusAllApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary mission/receive_bonus_api
         * @param {string} [missionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async missionReceiveBonusApiPost(missionId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.missionReceiveBonusApiPost(missionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary mission/season_api
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async missionSeasonApiPost(page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.missionSeasonApiPost(page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary title_api
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async missionTitleApiPost(page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.missionTitleApiPost(page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MissionApi - factory interface
 * @export
 */
export const MissionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MissionApiFp(configuration)
    return {
        /**
         * 
         * @summary background_api
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionBackgroundApiPost(page?: number, options?: any): AxiosPromise<void> {
            return localVarFp.missionBackgroundApiPost(page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary mission/complete_api
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionCompleteApiPost(page?: number, options?: any): AxiosPromise<void> {
            return localVarFp.missionCompleteApiPost(page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary mission/index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionIndexApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.missionIndexApiPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary mission/monthly_api
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionMonthlyApiPost(page?: number, options?: any): AxiosPromise<void> {
            return localVarFp.missionMonthlyApiPost(page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary mission/receive_bonus_all_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionReceiveBonusAllApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.missionReceiveBonusAllApiPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary mission/receive_bonus_api
         * @param {string} [missionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionReceiveBonusApiPost(missionId?: string, options?: any): AxiosPromise<object> {
            return localVarFp.missionReceiveBonusApiPost(missionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary mission/season_api
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionSeasonApiPost(page?: number, options?: any): AxiosPromise<void> {
            return localVarFp.missionSeasonApiPost(page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary title_api
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        missionTitleApiPost(page?: number, options?: any): AxiosPromise<void> {
            return localVarFp.missionTitleApiPost(page, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MissionApi - object-oriented interface
 * @export
 * @class MissionApi
 * @extends {BaseAPI}
 */
export class MissionApi extends BaseAPI {
    /**
     * 
     * @summary background_api
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionApi
     */
    public missionBackgroundApiPost(page?: number, options?: AxiosRequestConfig) {
        return MissionApiFp(this.configuration).missionBackgroundApiPost(page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary mission/complete_api
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionApi
     */
    public missionCompleteApiPost(page?: number, options?: AxiosRequestConfig) {
        return MissionApiFp(this.configuration).missionCompleteApiPost(page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary mission/index_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionApi
     */
    public missionIndexApiPost(options?: AxiosRequestConfig) {
        return MissionApiFp(this.configuration).missionIndexApiPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary mission/monthly_api
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionApi
     */
    public missionMonthlyApiPost(page?: number, options?: AxiosRequestConfig) {
        return MissionApiFp(this.configuration).missionMonthlyApiPost(page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary mission/receive_bonus_all_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionApi
     */
    public missionReceiveBonusAllApiPost(options?: AxiosRequestConfig) {
        return MissionApiFp(this.configuration).missionReceiveBonusAllApiPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary mission/receive_bonus_api
     * @param {string} [missionId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionApi
     */
    public missionReceiveBonusApiPost(missionId?: string, options?: AxiosRequestConfig) {
        return MissionApiFp(this.configuration).missionReceiveBonusApiPost(missionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary mission/season_api
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionApi
     */
    public missionSeasonApiPost(page?: number, options?: AxiosRequestConfig) {
        return MissionApiFp(this.configuration).missionSeasonApiPost(page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary title_api
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MissionApi
     */
    public missionTitleApiPost(page?: number, options?: AxiosRequestConfig) {
        return MissionApiFp(this.configuration).missionTitleApiPost(page, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MypageApi - axios parameter creator
 * @export
 */
export const MypageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary user/mypage_api/:uid
         * @param {string} uid users.dispuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userMypageApiUidPost: async (uid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uid' is not null or undefined
            assertParamExists('userMypageApiUidPost', 'uid', uid)
            const localVarPath = `/user/mypage_api/{uid}`
                .replace(`{${"uid"}}`, encodeURIComponent(String(uid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MypageApi - functional programming interface
 * @export
 */
export const MypageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MypageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary user/mypage_api/:uid
         * @param {string} uid users.dispuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userMypageApiUidPost(uid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userMypageApiUidPost(uid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MypageApi - factory interface
 * @export
 */
export const MypageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MypageApiFp(configuration)
    return {
        /**
         * 
         * @summary user/mypage_api/:uid
         * @param {string} uid users.dispuid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userMypageApiUidPost(uid: string, options?: any): AxiosPromise<void> {
            return localVarFp.userMypageApiUidPost(uid, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MypageApi - object-oriented interface
 * @export
 * @class MypageApi
 * @extends {BaseAPI}
 */
export class MypageApi extends BaseAPI {
    /**
     * 
     * @summary user/mypage_api/:uid
     * @param {string} uid users.dispuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MypageApi
     */
    public userMypageApiUidPost(uid: string, options?: AxiosRequestConfig) {
        return MypageApiFp(this.configuration).userMypageApiUidPost(uid, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NewsApi - axios parameter creator
 * @export
 */
export const NewsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary news/index_api/:page_name
         * @param {string} pageName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsIndexApiPageNamePost: async (pageName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageName' is not null or undefined
            assertParamExists('newsIndexApiPageNamePost', 'pageName', pageName)
            const localVarPath = `/news/index_api/{page_name}`
                .replace(`{${"page_name"}}`, encodeURIComponent(String(pageName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NewsApi - functional programming interface
 * @export
 */
export const NewsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NewsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary news/index_api/:page_name
         * @param {string} pageName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newsIndexApiPageNamePost(pageName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newsIndexApiPageNamePost(pageName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NewsApi - factory interface
 * @export
 */
export const NewsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NewsApiFp(configuration)
    return {
        /**
         * 
         * @summary news/index_api/:page_name
         * @param {string} pageName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newsIndexApiPageNamePost(pageName: string, options?: any): AxiosPromise<object> {
            return localVarFp.newsIndexApiPageNamePost(pageName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NewsApi - object-oriented interface
 * @export
 * @class NewsApi
 * @extends {BaseAPI}
 */
export class NewsApi extends BaseAPI {
    /**
     * 
     * @summary news/index_api/:page_name
     * @param {string} pageName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public newsIndexApiPageNamePost(pageName: string, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).newsIndexApiPageNamePost(pageName, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PageApi - axios parameter creator
 * @export
 */
export const PageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary page/index_api/:page_name
         * @param {string} pageName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageIndexApiPageNamePost: async (pageName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageName' is not null or undefined
            assertParamExists('pageIndexApiPageNamePost', 'pageName', pageName)
            const localVarPath = `/page/index_api/{page_name}`
                .replace(`{${"page_name"}}`, encodeURIComponent(String(pageName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PageApi - functional programming interface
 * @export
 */
export const PageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary page/index_api/:page_name
         * @param {string} pageName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pageIndexApiPageNamePost(pageName: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pageIndexApiPageNamePost(pageName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PageApi - factory interface
 * @export
 */
export const PageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PageApiFp(configuration)
    return {
        /**
         * 
         * @summary page/index_api/:page_name
         * @param {string} pageName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pageIndexApiPageNamePost(pageName: string, options?: any): AxiosPromise<object> {
            return localVarFp.pageIndexApiPageNamePost(pageName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PageApi - object-oriented interface
 * @export
 * @class PageApi
 * @extends {BaseAPI}
 */
export class PageApi extends BaseAPI {
    /**
     * 
     * @summary page/index_api/:page_name
     * @param {string} pageName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageApi
     */
    public pageIndexApiPageNamePost(pageName: string, options?: AxiosRequestConfig) {
        return PageApiFp(this.configuration).pageIndexApiPageNamePost(pageName, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PanelMissionApi - axios parameter creator
 * @export
 */
export const PanelMissionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary ajax_check_mission
         * @param {string} [panelMissionHeaderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        panelMissionAjaxCheckMissionPost: async (panelMissionHeaderId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/panel_mission/ajax_check_mission`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (panelMissionHeaderId !== undefined) {
                localVarQueryParameter['panel_mission_header_id'] = panelMissionHeaderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary index_api
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        panelMissionIndexApiPost: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/panel_mission/index_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PanelMissionApi - functional programming interface
 * @export
 */
export const PanelMissionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PanelMissionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary ajax_check_mission
         * @param {string} [panelMissionHeaderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async panelMissionAjaxCheckMissionPost(panelMissionHeaderId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.panelMissionAjaxCheckMissionPost(panelMissionHeaderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary index_api
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async panelMissionIndexApiPost(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.panelMissionIndexApiPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PanelMissionApi - factory interface
 * @export
 */
export const PanelMissionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PanelMissionApiFp(configuration)
    return {
        /**
         * 
         * @summary ajax_check_mission
         * @param {string} [panelMissionHeaderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        panelMissionAjaxCheckMissionPost(panelMissionHeaderId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.panelMissionAjaxCheckMissionPost(panelMissionHeaderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary index_api
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        panelMissionIndexApiPost(id?: string, options?: any): AxiosPromise<void> {
            return localVarFp.panelMissionIndexApiPost(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PanelMissionApi - object-oriented interface
 * @export
 * @class PanelMissionApi
 * @extends {BaseAPI}
 */
export class PanelMissionApi extends BaseAPI {
    /**
     * 
     * @summary ajax_check_mission
     * @param {string} [panelMissionHeaderId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PanelMissionApi
     */
    public panelMissionAjaxCheckMissionPost(panelMissionHeaderId?: string, options?: AxiosRequestConfig) {
        return PanelMissionApiFp(this.configuration).panelMissionAjaxCheckMissionPost(panelMissionHeaderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary index_api
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PanelMissionApi
     */
    public panelMissionIndexApiPost(id?: string, options?: AxiosRequestConfig) {
        return PanelMissionApiFp(this.configuration).panelMissionIndexApiPost(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentApi - axios parameter creator
 * @export
 */
export const PaymentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary sbpayment/api (nppost)
         * @param {number} [userAgent] UserAgent
         * @param {string} [payMethod] 決済手段 - credit, nppost
         * @param {string} [payType] 認証タイプ - token, normal
         * @param {number} [itemId] 課金商品ID
         * @param {string} [itemName] 課金商品名
         * @param {number} [amount] 決済金額
         * @param {string} [tax] 税額
         * @param {string} [token] トークン
         * @param {string} [tokenKey] トークンキー
         * @param {string} [isCustManage] 
         * @param {string} [isCustIdPurchase] 
         * @param {string} [companyName] 会社名 - (NP後払い専用パラメータ)
         * @param {string} [department] 部署名 - (NP後払い専用パラメータ)
         * @param {string} [customerName] 氏名 - (NP後払い専用パラメータ)
         * @param {string} [customerNameKana] 氏名（カナ） - (NP後払い専用パラメータ)
         * @param {number} [zip] 郵便番号 - (NP後払い専用パラメータ)
         * @param {string} [address] 住所 - (NP後払い専用パラメータ)
         * @param {number} [tel] 電話番号 - (NP後払い専用パラメータ)
         * @param {string} [email] メールアドレス - (NP後払い専用パラメータ)
         * @param {string} [destCompanyName] 配送先会社名 - (NP後払い専用パラメータ)
         * @param {string} [destDepartment] 配送先部署名 - (NP後払い専用パラメータ)
         * @param {string} [destCustomerName] 配送先氏名 - (NP後払い専用パラメータ)
         * @param {string} [destCustomerNameKana] 配送先氏名（カナ） - (NP後払い専用パラメータ)
         * @param {number} [destZip] 配送先郵便番号 - (NP後払い専用パラメータ)
         * @param {string} [destAddress] 配送先住所 - (NP後払い専用パラメータ)
         * @param {number} [destTel] 配送先電話番号 - (NP後払い専用パラメータ)
         * @param {number} [pdCompanyCode] 運送会社コード - (NP後払い専用パラメータ)
         * @param {string} [slipNo] 配送伝票番号 - (NP後払い専用パラメータ)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxSbpaymentApiPost: async (userAgent?: number, payMethod?: string, payType?: string, itemId?: number, itemName?: string, amount?: number, tax?: string, token?: string, tokenKey?: string, isCustManage?: string, isCustIdPurchase?: string, companyName?: string, department?: string, customerName?: string, customerNameKana?: string, zip?: number, address?: string, tel?: number, email?: string, destCompanyName?: string, destDepartment?: string, destCustomerName?: string, destCustomerNameKana?: string, destZip?: number, destAddress?: string, destTel?: number, pdCompanyCode?: number, slipNo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/sbpayment/api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (userAgent != null) {
                localVarHeaderParameter['User-Agent'] = typeof userAgent === 'string' 
                    ? userAgent 
                    : JSON.stringify(userAgent);
            }


            if (payMethod !== undefined) { 
                localVarFormParams.append('pay_method', payMethod as any);
            }
    
            if (payType !== undefined) { 
                localVarFormParams.append('pay_type', payType as any);
            }
    
            if (itemId !== undefined) { 
                localVarFormParams.append('item_id', itemId as any);
            }
    
            if (itemName !== undefined) { 
                localVarFormParams.append('item_name', itemName as any);
            }
    
            if (amount !== undefined) { 
                localVarFormParams.append('amount', amount as any);
            }
    
            if (tax !== undefined) { 
                localVarFormParams.append('tax', tax as any);
            }
    
            if (token !== undefined) { 
                localVarFormParams.append('token', token as any);
            }
    
            if (tokenKey !== undefined) { 
                localVarFormParams.append('token_key', tokenKey as any);
            }
    
            if (isCustManage !== undefined) { 
                localVarFormParams.append('is_cust_manage', isCustManage as any);
            }
    
            if (isCustIdPurchase !== undefined) { 
                localVarFormParams.append('is_cust_id_purchase', isCustIdPurchase as any);
            }
    
            if (companyName !== undefined) { 
                localVarFormParams.append('company_name', companyName as any);
            }
    
            if (department !== undefined) { 
                localVarFormParams.append('department', department as any);
            }
    
            if (customerName !== undefined) { 
                localVarFormParams.append('customer_name', customerName as any);
            }
    
            if (customerNameKana !== undefined) { 
                localVarFormParams.append('customer_name_kana', customerNameKana as any);
            }
    
            if (zip !== undefined) { 
                localVarFormParams.append('zip', zip as any);
            }
    
            if (address !== undefined) { 
                localVarFormParams.append('address', address as any);
            }
    
            if (tel !== undefined) { 
                localVarFormParams.append('tel', tel as any);
            }
    
            if (email !== undefined) { 
                localVarFormParams.append('email', email as any);
            }
    
            if (destCompanyName !== undefined) { 
                localVarFormParams.append('dest_company_name', destCompanyName as any);
            }
    
            if (destDepartment !== undefined) { 
                localVarFormParams.append('dest_department', destDepartment as any);
            }
    
            if (destCustomerName !== undefined) { 
                localVarFormParams.append('dest_customer_name', destCustomerName as any);
            }
    
            if (destCustomerNameKana !== undefined) { 
                localVarFormParams.append('dest_customer_name_kana', destCustomerNameKana as any);
            }
    
            if (destZip !== undefined) { 
                localVarFormParams.append('dest_zip', destZip as any);
            }
    
            if (destAddress !== undefined) { 
                localVarFormParams.append('dest_address', destAddress as any);
            }
    
            if (destTel !== undefined) { 
                localVarFormParams.append('dest_tel', destTel as any);
            }
    
            if (pdCompanyCode !== undefined) { 
                localVarFormParams.append('pd_company_code', pdCompanyCode as any);
            }
    
            if (slipNo !== undefined) { 
                localVarFormParams.append('slip_no', slipNo as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sbpayment/link
         * @param {number} [userAgent] 
         * @param {string} [payMethod] 決済手段 - credit,nppost, docomo, softbank, auone, webmoney, np
         * @param {number} [itemId] 課金商品ID
         * @param {string} [itemName] 課金商品ID名
         * @param {number} [amount] 決済金額
         * @param {string} [tax] 税額
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxSbpaymentLinkPost: async (userAgent?: number, payMethod?: string, itemId?: number, itemName?: string, amount?: number, tax?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ajax/sbpayment/link`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (userAgent != null) {
                localVarHeaderParameter['User-Agent'] = typeof userAgent === 'string' 
                    ? userAgent 
                    : JSON.stringify(userAgent);
            }


            if (payMethod !== undefined) { 
                localVarFormParams.append('pay_method', payMethod as any);
            }
    
            if (itemId !== undefined) { 
                localVarFormParams.append('item_id', itemId as any);
            }
    
            if (itemName !== undefined) { 
                localVarFormParams.append('item_name', itemName as any);
            }
    
            if (amount !== undefined) { 
                localVarFormParams.append('amount', amount as any);
            }
    
            if (tax !== undefined) { 
                localVarFormParams.append('tax', tax as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sbpayment/webhook
         * @param {string} [orderId] ソフトバンクからの購入ID
         * @param {string} [itemId] ソフトバンクからの課金商品ID
         * @param {string} [resResult] ソフトバンクからの処理結果ステータス
         * @param {string} [payMethod] 決済手段 - credit,nppostpay, aupay, paypay
         * @param {string} [merchantId] マーチャント ID
         * @param {string} [serviceId] サービス ID
         * @param {string} [custCode] 顧客 ID
         * @param {string} [spsCustNo] SBPS 顧客 ID
         * @param {string} [spsPaymentNo] SBPS 支払方法管理番号
         * @param {string} [orderId2] 購入 ID
         * @param {string} [itemId2] 商品 ID
         * @param {string} [payItemId] 外部決済機関商品 ID
         * @param {string} [itemName] 商品名称
         * @param {string} [tax] 税額
         * @param {string} [amount] 金額（税込）
         * @param {string} [autoChargeType] 自動課金タイプ
         * @param {string} [serviceType] サービスタイプ
         * @param {string} [divSettele] 決済区分
         * @param {string} [lastChargeMonth] 最終課金月
         * @param {string} [campType] キャンペーンタイプ
         * @param {string} [trackingId] トラッキング ID
         * @param {string} [terminalType] 顧客利用端末タイプ
         * @param {string} [free1] 自由欄１
         * @param {string} [free2] 自由欄２
         * @param {string} [free3] 自由欄３
         * @param {string} [resResult2] 処理結果ステータス
         * @param {string} [resTrackingId] 処理トラッキング ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sbpaymentWebhookPost: async (orderId?: string, itemId?: string, resResult?: string, payMethod?: string, merchantId?: string, serviceId?: string, custCode?: string, spsCustNo?: string, spsPaymentNo?: string, orderId2?: string, itemId2?: string, payItemId?: string, itemName?: string, tax?: string, amount?: string, autoChargeType?: string, serviceType?: string, divSettele?: string, lastChargeMonth?: string, campType?: string, trackingId?: string, terminalType?: string, free1?: string, free2?: string, free3?: string, resResult2?: string, resTrackingId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sbpayment/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (orderId !== undefined) {
                localVarQueryParameter['order_id'] = orderId;
            }

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }

            if (resResult !== undefined) {
                localVarQueryParameter['res_result'] = resResult;
            }


            if (payMethod !== undefined) { 
                localVarFormParams.append('pay_method', payMethod as any);
            }
    
            if (merchantId !== undefined) { 
                localVarFormParams.append('merchant_id', merchantId as any);
            }
    
            if (serviceId !== undefined) { 
                localVarFormParams.append('service_id', serviceId as any);
            }
    
            if (custCode !== undefined) { 
                localVarFormParams.append('cust_code', custCode as any);
            }
    
            if (spsCustNo !== undefined) { 
                localVarFormParams.append('sps_cust_no', spsCustNo as any);
            }
    
            if (spsPaymentNo !== undefined) { 
                localVarFormParams.append('sps_payment_no', spsPaymentNo as any);
            }
    
            if (orderId2 !== undefined) { 
                localVarFormParams.append('order_id', orderId2 as any);
            }
    
            if (itemId2 !== undefined) { 
                localVarFormParams.append('item_id', itemId2 as any);
            }
    
            if (payItemId !== undefined) { 
                localVarFormParams.append('pay_item_id', payItemId as any);
            }
    
            if (itemName !== undefined) { 
                localVarFormParams.append('item_name', itemName as any);
            }
    
            if (tax !== undefined) { 
                localVarFormParams.append('tax', tax as any);
            }
    
            if (amount !== undefined) { 
                localVarFormParams.append('amount', amount as any);
            }
    
            if (autoChargeType !== undefined) { 
                localVarFormParams.append('auto_charge_type', autoChargeType as any);
            }
    
            if (serviceType !== undefined) { 
                localVarFormParams.append('service_type', serviceType as any);
            }
    
            if (divSettele !== undefined) { 
                localVarFormParams.append('div_settele', divSettele as any);
            }
    
            if (lastChargeMonth !== undefined) { 
                localVarFormParams.append('last_charge_month', lastChargeMonth as any);
            }
    
            if (campType !== undefined) { 
                localVarFormParams.append('camp_type', campType as any);
            }
    
            if (trackingId !== undefined) { 
                localVarFormParams.append('tracking_id', trackingId as any);
            }
    
            if (terminalType !== undefined) { 
                localVarFormParams.append('terminal_type', terminalType as any);
            }
    
            if (free1 !== undefined) { 
                localVarFormParams.append('free1', free1 as any);
            }
    
            if (free2 !== undefined) { 
                localVarFormParams.append('free2', free2 as any);
            }
    
            if (free3 !== undefined) { 
                localVarFormParams.append('free3', free3 as any);
            }
    
            if (resResult2 !== undefined) { 
                localVarFormParams.append('res_result', resResult2 as any);
            }
    
            if (resTrackingId !== undefined) { 
                localVarFormParams.append('res_tracking_id', resTrackingId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentApi - functional programming interface
 * @export
 */
export const PaymentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary sbpayment/api (nppost)
         * @param {number} [userAgent] UserAgent
         * @param {string} [payMethod] 決済手段 - credit, nppost
         * @param {string} [payType] 認証タイプ - token, normal
         * @param {number} [itemId] 課金商品ID
         * @param {string} [itemName] 課金商品名
         * @param {number} [amount] 決済金額
         * @param {string} [tax] 税額
         * @param {string} [token] トークン
         * @param {string} [tokenKey] トークンキー
         * @param {string} [isCustManage] 
         * @param {string} [isCustIdPurchase] 
         * @param {string} [companyName] 会社名 - (NP後払い専用パラメータ)
         * @param {string} [department] 部署名 - (NP後払い専用パラメータ)
         * @param {string} [customerName] 氏名 - (NP後払い専用パラメータ)
         * @param {string} [customerNameKana] 氏名（カナ） - (NP後払い専用パラメータ)
         * @param {number} [zip] 郵便番号 - (NP後払い専用パラメータ)
         * @param {string} [address] 住所 - (NP後払い専用パラメータ)
         * @param {number} [tel] 電話番号 - (NP後払い専用パラメータ)
         * @param {string} [email] メールアドレス - (NP後払い専用パラメータ)
         * @param {string} [destCompanyName] 配送先会社名 - (NP後払い専用パラメータ)
         * @param {string} [destDepartment] 配送先部署名 - (NP後払い専用パラメータ)
         * @param {string} [destCustomerName] 配送先氏名 - (NP後払い専用パラメータ)
         * @param {string} [destCustomerNameKana] 配送先氏名（カナ） - (NP後払い専用パラメータ)
         * @param {number} [destZip] 配送先郵便番号 - (NP後払い専用パラメータ)
         * @param {string} [destAddress] 配送先住所 - (NP後払い専用パラメータ)
         * @param {number} [destTel] 配送先電話番号 - (NP後払い専用パラメータ)
         * @param {number} [pdCompanyCode] 運送会社コード - (NP後払い専用パラメータ)
         * @param {string} [slipNo] 配送伝票番号 - (NP後払い専用パラメータ)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxSbpaymentApiPost(userAgent?: number, payMethod?: string, payType?: string, itemId?: number, itemName?: string, amount?: number, tax?: string, token?: string, tokenKey?: string, isCustManage?: string, isCustIdPurchase?: string, companyName?: string, department?: string, customerName?: string, customerNameKana?: string, zip?: number, address?: string, tel?: number, email?: string, destCompanyName?: string, destDepartment?: string, destCustomerName?: string, destCustomerNameKana?: string, destZip?: number, destAddress?: string, destTel?: number, pdCompanyCode?: number, slipNo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxSbpaymentApiPost(userAgent, payMethod, payType, itemId, itemName, amount, tax, token, tokenKey, isCustManage, isCustIdPurchase, companyName, department, customerName, customerNameKana, zip, address, tel, email, destCompanyName, destDepartment, destCustomerName, destCustomerNameKana, destZip, destAddress, destTel, pdCompanyCode, slipNo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sbpayment/link
         * @param {number} [userAgent] 
         * @param {string} [payMethod] 決済手段 - credit,nppost, docomo, softbank, auone, webmoney, np
         * @param {number} [itemId] 課金商品ID
         * @param {string} [itemName] 課金商品ID名
         * @param {number} [amount] 決済金額
         * @param {string} [tax] 税額
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ajaxSbpaymentLinkPost(userAgent?: number, payMethod?: string, itemId?: number, itemName?: string, amount?: number, tax?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ajaxSbpaymentLinkPost(userAgent, payMethod, itemId, itemName, amount, tax, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary sbpayment/webhook
         * @param {string} [orderId] ソフトバンクからの購入ID
         * @param {string} [itemId] ソフトバンクからの課金商品ID
         * @param {string} [resResult] ソフトバンクからの処理結果ステータス
         * @param {string} [payMethod] 決済手段 - credit,nppostpay, aupay, paypay
         * @param {string} [merchantId] マーチャント ID
         * @param {string} [serviceId] サービス ID
         * @param {string} [custCode] 顧客 ID
         * @param {string} [spsCustNo] SBPS 顧客 ID
         * @param {string} [spsPaymentNo] SBPS 支払方法管理番号
         * @param {string} [orderId2] 購入 ID
         * @param {string} [itemId2] 商品 ID
         * @param {string} [payItemId] 外部決済機関商品 ID
         * @param {string} [itemName] 商品名称
         * @param {string} [tax] 税額
         * @param {string} [amount] 金額（税込）
         * @param {string} [autoChargeType] 自動課金タイプ
         * @param {string} [serviceType] サービスタイプ
         * @param {string} [divSettele] 決済区分
         * @param {string} [lastChargeMonth] 最終課金月
         * @param {string} [campType] キャンペーンタイプ
         * @param {string} [trackingId] トラッキング ID
         * @param {string} [terminalType] 顧客利用端末タイプ
         * @param {string} [free1] 自由欄１
         * @param {string} [free2] 自由欄２
         * @param {string} [free3] 自由欄３
         * @param {string} [resResult2] 処理結果ステータス
         * @param {string} [resTrackingId] 処理トラッキング ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sbpaymentWebhookPost(orderId?: string, itemId?: string, resResult?: string, payMethod?: string, merchantId?: string, serviceId?: string, custCode?: string, spsCustNo?: string, spsPaymentNo?: string, orderId2?: string, itemId2?: string, payItemId?: string, itemName?: string, tax?: string, amount?: string, autoChargeType?: string, serviceType?: string, divSettele?: string, lastChargeMonth?: string, campType?: string, trackingId?: string, terminalType?: string, free1?: string, free2?: string, free3?: string, resResult2?: string, resTrackingId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sbpaymentWebhookPost(orderId, itemId, resResult, payMethod, merchantId, serviceId, custCode, spsCustNo, spsPaymentNo, orderId2, itemId2, payItemId, itemName, tax, amount, autoChargeType, serviceType, divSettele, lastChargeMonth, campType, trackingId, terminalType, free1, free2, free3, resResult2, resTrackingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentApi - factory interface
 * @export
 */
export const PaymentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentApiFp(configuration)
    return {
        /**
         * 
         * @summary sbpayment/api (nppost)
         * @param {number} [userAgent] UserAgent
         * @param {string} [payMethod] 決済手段 - credit, nppost
         * @param {string} [payType] 認証タイプ - token, normal
         * @param {number} [itemId] 課金商品ID
         * @param {string} [itemName] 課金商品名
         * @param {number} [amount] 決済金額
         * @param {string} [tax] 税額
         * @param {string} [token] トークン
         * @param {string} [tokenKey] トークンキー
         * @param {string} [isCustManage] 
         * @param {string} [isCustIdPurchase] 
         * @param {string} [companyName] 会社名 - (NP後払い専用パラメータ)
         * @param {string} [department] 部署名 - (NP後払い専用パラメータ)
         * @param {string} [customerName] 氏名 - (NP後払い専用パラメータ)
         * @param {string} [customerNameKana] 氏名（カナ） - (NP後払い専用パラメータ)
         * @param {number} [zip] 郵便番号 - (NP後払い専用パラメータ)
         * @param {string} [address] 住所 - (NP後払い専用パラメータ)
         * @param {number} [tel] 電話番号 - (NP後払い専用パラメータ)
         * @param {string} [email] メールアドレス - (NP後払い専用パラメータ)
         * @param {string} [destCompanyName] 配送先会社名 - (NP後払い専用パラメータ)
         * @param {string} [destDepartment] 配送先部署名 - (NP後払い専用パラメータ)
         * @param {string} [destCustomerName] 配送先氏名 - (NP後払い専用パラメータ)
         * @param {string} [destCustomerNameKana] 配送先氏名（カナ） - (NP後払い専用パラメータ)
         * @param {number} [destZip] 配送先郵便番号 - (NP後払い専用パラメータ)
         * @param {string} [destAddress] 配送先住所 - (NP後払い専用パラメータ)
         * @param {number} [destTel] 配送先電話番号 - (NP後払い専用パラメータ)
         * @param {number} [pdCompanyCode] 運送会社コード - (NP後払い専用パラメータ)
         * @param {string} [slipNo] 配送伝票番号 - (NP後払い専用パラメータ)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxSbpaymentApiPost(userAgent?: number, payMethod?: string, payType?: string, itemId?: number, itemName?: string, amount?: number, tax?: string, token?: string, tokenKey?: string, isCustManage?: string, isCustIdPurchase?: string, companyName?: string, department?: string, customerName?: string, customerNameKana?: string, zip?: number, address?: string, tel?: number, email?: string, destCompanyName?: string, destDepartment?: string, destCustomerName?: string, destCustomerNameKana?: string, destZip?: number, destAddress?: string, destTel?: number, pdCompanyCode?: number, slipNo?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxSbpaymentApiPost(userAgent, payMethod, payType, itemId, itemName, amount, tax, token, tokenKey, isCustManage, isCustIdPurchase, companyName, department, customerName, customerNameKana, zip, address, tel, email, destCompanyName, destDepartment, destCustomerName, destCustomerNameKana, destZip, destAddress, destTel, pdCompanyCode, slipNo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sbpayment/link
         * @param {number} [userAgent] 
         * @param {string} [payMethod] 決済手段 - credit,nppost, docomo, softbank, auone, webmoney, np
         * @param {number} [itemId] 課金商品ID
         * @param {string} [itemName] 課金商品ID名
         * @param {number} [amount] 決済金額
         * @param {string} [tax] 税額
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ajaxSbpaymentLinkPost(userAgent?: number, payMethod?: string, itemId?: number, itemName?: string, amount?: number, tax?: string, options?: any): AxiosPromise<void> {
            return localVarFp.ajaxSbpaymentLinkPost(userAgent, payMethod, itemId, itemName, amount, tax, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary sbpayment/webhook
         * @param {string} [orderId] ソフトバンクからの購入ID
         * @param {string} [itemId] ソフトバンクからの課金商品ID
         * @param {string} [resResult] ソフトバンクからの処理結果ステータス
         * @param {string} [payMethod] 決済手段 - credit,nppostpay, aupay, paypay
         * @param {string} [merchantId] マーチャント ID
         * @param {string} [serviceId] サービス ID
         * @param {string} [custCode] 顧客 ID
         * @param {string} [spsCustNo] SBPS 顧客 ID
         * @param {string} [spsPaymentNo] SBPS 支払方法管理番号
         * @param {string} [orderId2] 購入 ID
         * @param {string} [itemId2] 商品 ID
         * @param {string} [payItemId] 外部決済機関商品 ID
         * @param {string} [itemName] 商品名称
         * @param {string} [tax] 税額
         * @param {string} [amount] 金額（税込）
         * @param {string} [autoChargeType] 自動課金タイプ
         * @param {string} [serviceType] サービスタイプ
         * @param {string} [divSettele] 決済区分
         * @param {string} [lastChargeMonth] 最終課金月
         * @param {string} [campType] キャンペーンタイプ
         * @param {string} [trackingId] トラッキング ID
         * @param {string} [terminalType] 顧客利用端末タイプ
         * @param {string} [free1] 自由欄１
         * @param {string} [free2] 自由欄２
         * @param {string} [free3] 自由欄３
         * @param {string} [resResult2] 処理結果ステータス
         * @param {string} [resTrackingId] 処理トラッキング ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sbpaymentWebhookPost(orderId?: string, itemId?: string, resResult?: string, payMethod?: string, merchantId?: string, serviceId?: string, custCode?: string, spsCustNo?: string, spsPaymentNo?: string, orderId2?: string, itemId2?: string, payItemId?: string, itemName?: string, tax?: string, amount?: string, autoChargeType?: string, serviceType?: string, divSettele?: string, lastChargeMonth?: string, campType?: string, trackingId?: string, terminalType?: string, free1?: string, free2?: string, free3?: string, resResult2?: string, resTrackingId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.sbpaymentWebhookPost(orderId, itemId, resResult, payMethod, merchantId, serviceId, custCode, spsCustNo, spsPaymentNo, orderId2, itemId2, payItemId, itemName, tax, amount, autoChargeType, serviceType, divSettele, lastChargeMonth, campType, trackingId, terminalType, free1, free2, free3, resResult2, resTrackingId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentApi - object-oriented interface
 * @export
 * @class PaymentApi
 * @extends {BaseAPI}
 */
export class PaymentApi extends BaseAPI {
    /**
     * 
     * @summary sbpayment/api (nppost)
     * @param {number} [userAgent] UserAgent
     * @param {string} [payMethod] 決済手段 - credit, nppost
     * @param {string} [payType] 認証タイプ - token, normal
     * @param {number} [itemId] 課金商品ID
     * @param {string} [itemName] 課金商品名
     * @param {number} [amount] 決済金額
     * @param {string} [tax] 税額
     * @param {string} [token] トークン
     * @param {string} [tokenKey] トークンキー
     * @param {string} [isCustManage] 
     * @param {string} [isCustIdPurchase] 
     * @param {string} [companyName] 会社名 - (NP後払い専用パラメータ)
     * @param {string} [department] 部署名 - (NP後払い専用パラメータ)
     * @param {string} [customerName] 氏名 - (NP後払い専用パラメータ)
     * @param {string} [customerNameKana] 氏名（カナ） - (NP後払い専用パラメータ)
     * @param {number} [zip] 郵便番号 - (NP後払い専用パラメータ)
     * @param {string} [address] 住所 - (NP後払い専用パラメータ)
     * @param {number} [tel] 電話番号 - (NP後払い専用パラメータ)
     * @param {string} [email] メールアドレス - (NP後払い専用パラメータ)
     * @param {string} [destCompanyName] 配送先会社名 - (NP後払い専用パラメータ)
     * @param {string} [destDepartment] 配送先部署名 - (NP後払い専用パラメータ)
     * @param {string} [destCustomerName] 配送先氏名 - (NP後払い専用パラメータ)
     * @param {string} [destCustomerNameKana] 配送先氏名（カナ） - (NP後払い専用パラメータ)
     * @param {number} [destZip] 配送先郵便番号 - (NP後払い専用パラメータ)
     * @param {string} [destAddress] 配送先住所 - (NP後払い専用パラメータ)
     * @param {number} [destTel] 配送先電話番号 - (NP後払い専用パラメータ)
     * @param {number} [pdCompanyCode] 運送会社コード - (NP後払い専用パラメータ)
     * @param {string} [slipNo] 配送伝票番号 - (NP後払い専用パラメータ)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public ajaxSbpaymentApiPost(userAgent?: number, payMethod?: string, payType?: string, itemId?: number, itemName?: string, amount?: number, tax?: string, token?: string, tokenKey?: string, isCustManage?: string, isCustIdPurchase?: string, companyName?: string, department?: string, customerName?: string, customerNameKana?: string, zip?: number, address?: string, tel?: number, email?: string, destCompanyName?: string, destDepartment?: string, destCustomerName?: string, destCustomerNameKana?: string, destZip?: number, destAddress?: string, destTel?: number, pdCompanyCode?: number, slipNo?: string, options?: AxiosRequestConfig) {
        return PaymentApiFp(this.configuration).ajaxSbpaymentApiPost(userAgent, payMethod, payType, itemId, itemName, amount, tax, token, tokenKey, isCustManage, isCustIdPurchase, companyName, department, customerName, customerNameKana, zip, address, tel, email, destCompanyName, destDepartment, destCustomerName, destCustomerNameKana, destZip, destAddress, destTel, pdCompanyCode, slipNo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sbpayment/link
     * @param {number} [userAgent] 
     * @param {string} [payMethod] 決済手段 - credit,nppost, docomo, softbank, auone, webmoney, np
     * @param {number} [itemId] 課金商品ID
     * @param {string} [itemName] 課金商品ID名
     * @param {number} [amount] 決済金額
     * @param {string} [tax] 税額
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public ajaxSbpaymentLinkPost(userAgent?: number, payMethod?: string, itemId?: number, itemName?: string, amount?: number, tax?: string, options?: AxiosRequestConfig) {
        return PaymentApiFp(this.configuration).ajaxSbpaymentLinkPost(userAgent, payMethod, itemId, itemName, amount, tax, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary sbpayment/webhook
     * @param {string} [orderId] ソフトバンクからの購入ID
     * @param {string} [itemId] ソフトバンクからの課金商品ID
     * @param {string} [resResult] ソフトバンクからの処理結果ステータス
     * @param {string} [payMethod] 決済手段 - credit,nppostpay, aupay, paypay
     * @param {string} [merchantId] マーチャント ID
     * @param {string} [serviceId] サービス ID
     * @param {string} [custCode] 顧客 ID
     * @param {string} [spsCustNo] SBPS 顧客 ID
     * @param {string} [spsPaymentNo] SBPS 支払方法管理番号
     * @param {string} [orderId2] 購入 ID
     * @param {string} [itemId2] 商品 ID
     * @param {string} [payItemId] 外部決済機関商品 ID
     * @param {string} [itemName] 商品名称
     * @param {string} [tax] 税額
     * @param {string} [amount] 金額（税込）
     * @param {string} [autoChargeType] 自動課金タイプ
     * @param {string} [serviceType] サービスタイプ
     * @param {string} [divSettele] 決済区分
     * @param {string} [lastChargeMonth] 最終課金月
     * @param {string} [campType] キャンペーンタイプ
     * @param {string} [trackingId] トラッキング ID
     * @param {string} [terminalType] 顧客利用端末タイプ
     * @param {string} [free1] 自由欄１
     * @param {string} [free2] 自由欄２
     * @param {string} [free3] 自由欄３
     * @param {string} [resResult2] 処理結果ステータス
     * @param {string} [resTrackingId] 処理トラッキング ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public sbpaymentWebhookPost(orderId?: string, itemId?: string, resResult?: string, payMethod?: string, merchantId?: string, serviceId?: string, custCode?: string, spsCustNo?: string, spsPaymentNo?: string, orderId2?: string, itemId2?: string, payItemId?: string, itemName?: string, tax?: string, amount?: string, autoChargeType?: string, serviceType?: string, divSettele?: string, lastChargeMonth?: string, campType?: string, trackingId?: string, terminalType?: string, free1?: string, free2?: string, free3?: string, resResult2?: string, resTrackingId?: string, options?: AxiosRequestConfig) {
        return PaymentApiFp(this.configuration).sbpaymentWebhookPost(orderId, itemId, resResult, payMethod, merchantId, serviceId, custCode, spsCustNo, spsPaymentNo, orderId2, itemId2, payItemId, itemName, tax, amount, autoChargeType, serviceType, divSettele, lastChargeMonth, campType, trackingId, terminalType, free1, free2, free3, resResult2, resTrackingId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PresentApi - axios parameter creator
 * @export
 */
export const PresentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary present/index_api
         * @param {number} [page] 1ページ目
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        presentIndexApiPost: async (page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/present/index_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary present/receive_all_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        presentReceiveAllApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/present/receive_all_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary present/receive_api
         * @param {string} [presentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        presentReceiveApiPost: async (presentId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/present/receive_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (presentId !== undefined) { 
                localVarFormParams.append('present_id', presentId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary present/receive_finish_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        presentReceiveFinishApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/present/receive_finish_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PresentApi - functional programming interface
 * @export
 */
export const PresentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PresentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary present/index_api
         * @param {number} [page] 1ページ目
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async presentIndexApiPost(page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.presentIndexApiPost(page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary present/receive_all_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async presentReceiveAllApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.presentReceiveAllApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary present/receive_api
         * @param {string} [presentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async presentReceiveApiPost(presentId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.presentReceiveApiPost(presentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary present/receive_finish_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async presentReceiveFinishApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.presentReceiveFinishApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PresentApi - factory interface
 * @export
 */
export const PresentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PresentApiFp(configuration)
    return {
        /**
         * 
         * @summary present/index_api
         * @param {number} [page] 1ページ目
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        presentIndexApiPost(page?: number, options?: any): AxiosPromise<void> {
            return localVarFp.presentIndexApiPost(page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary present/receive_all_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        presentReceiveAllApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.presentReceiveAllApiPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary present/receive_api
         * @param {string} [presentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        presentReceiveApiPost(presentId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.presentReceiveApiPost(presentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary present/receive_finish_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        presentReceiveFinishApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.presentReceiveFinishApiPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PresentApi - object-oriented interface
 * @export
 * @class PresentApi
 * @extends {BaseAPI}
 */
export class PresentApi extends BaseAPI {
    /**
     * 
     * @summary present/index_api
     * @param {number} [page] 1ページ目
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PresentApi
     */
    public presentIndexApiPost(page?: number, options?: AxiosRequestConfig) {
        return PresentApiFp(this.configuration).presentIndexApiPost(page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary present/receive_all_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PresentApi
     */
    public presentReceiveAllApiPost(options?: AxiosRequestConfig) {
        return PresentApiFp(this.configuration).presentReceiveAllApiPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary present/receive_api
     * @param {string} [presentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PresentApi
     */
    public presentReceiveApiPost(presentId?: string, options?: AxiosRequestConfig) {
        return PresentApiFp(this.configuration).presentReceiveApiPost(presentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary present/receive_finish_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PresentApi
     */
    public presentReceiveFinishApiPost(options?: AxiosRequestConfig) {
        return PresentApiFp(this.configuration).presentReceiveFinishApiPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProfileApi - axios parameter creator
 * @export
 */
export const ProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary back_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileBackApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/back_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary background_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileBackgroundApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/background_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary front_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileFrontApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/front_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileIndexApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/index_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary title_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileTitleApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/title_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfileApi - functional programming interface
 * @export
 */
export const ProfileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary back_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileBackApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileBackApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary background_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileBackgroundApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileBackgroundApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary front_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileFrontApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileFrontApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileIndexApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileIndexApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary title_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profileTitleApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.profileTitleApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProfileApi - factory interface
 * @export
 */
export const ProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProfileApiFp(configuration)
    return {
        /**
         * 
         * @summary back_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileBackApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.profileBackApiPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary background_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileBackgroundApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.profileBackgroundApiPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary front_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileFrontApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.profileFrontApiPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileIndexApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.profileIndexApiPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary title_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileTitleApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.profileTitleApiPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProfileApi - object-oriented interface
 * @export
 * @class ProfileApi
 * @extends {BaseAPI}
 */
export class ProfileApi extends BaseAPI {
    /**
     * 
     * @summary back_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileBackApiPost(options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).profileBackApiPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary background_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileBackgroundApiPost(options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).profileBackgroundApiPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary front_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileFrontApiPost(options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).profileFrontApiPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary index_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileIndexApiPost(options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).profileIndexApiPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary title_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public profileTitleApiPost(options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).profileTitleApiPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * QuestionnaireApi - axios parameter creator
 * @export
 */
export const QuestionnaireApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary questionnaire/confirm_api
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionnaireConfirmApiPost: async (body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/questionnaire/confirm_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary questionnaire/finish_api
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionnaireFinishApiPost: async (body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/questionnaire/finish_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary questionnaire/index_api
         * @param {number} [id] アンケートID
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionnaireIndexApiPost: async (id?: number, page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/questionnaire/index_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuestionnaireApi - functional programming interface
 * @export
 */
export const QuestionnaireApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuestionnaireApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary questionnaire/confirm_api
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionnaireConfirmApiPost(body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionnaireConfirmApiPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary questionnaire/finish_api
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionnaireFinishApiPost(body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionnaireFinishApiPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary questionnaire/index_api
         * @param {number} [id] アンケートID
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionnaireIndexApiPost(id?: number, page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionnaireIndexApiPost(id, page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QuestionnaireApi - factory interface
 * @export
 */
export const QuestionnaireApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuestionnaireApiFp(configuration)
    return {
        /**
         * 
         * @summary questionnaire/confirm_api
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionnaireConfirmApiPost(body?: object, options?: any): AxiosPromise<void> {
            return localVarFp.questionnaireConfirmApiPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary questionnaire/finish_api
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionnaireFinishApiPost(body?: object, options?: any): AxiosPromise<void> {
            return localVarFp.questionnaireFinishApiPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary questionnaire/index_api
         * @param {number} [id] アンケートID
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionnaireIndexApiPost(id?: number, page?: number, options?: any): AxiosPromise<void> {
            return localVarFp.questionnaireIndexApiPost(id, page, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QuestionnaireApi - object-oriented interface
 * @export
 * @class QuestionnaireApi
 * @extends {BaseAPI}
 */
export class QuestionnaireApi extends BaseAPI {
    /**
     * 
     * @summary questionnaire/confirm_api
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnaireApi
     */
    public questionnaireConfirmApiPost(body?: object, options?: AxiosRequestConfig) {
        return QuestionnaireApiFp(this.configuration).questionnaireConfirmApiPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary questionnaire/finish_api
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnaireApi
     */
    public questionnaireFinishApiPost(body?: object, options?: AxiosRequestConfig) {
        return QuestionnaireApiFp(this.configuration).questionnaireFinishApiPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary questionnaire/index_api
     * @param {number} [id] アンケートID
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionnaireApi
     */
    public questionnaireIndexApiPost(id?: number, page?: number, options?: AxiosRequestConfig) {
        return QuestionnaireApiFp(this.configuration).questionnaireIndexApiPost(id, page, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RarerankApi - axios parameter creator
 * @export
 */
export const RarerankApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary rarerank/ajax_pull_rarerank_monthly
         * @param {string} [intDown] 
         * @param {string} [intLimit] 
         * @param {string} [rankType] 
         * @param {string} [firstRanksId] 
         * @param {string} [lastRanksId] 
         * @param {string} [archive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rarerankAjaxPullRarerankMonthlyPost: async (intDown?: string, intLimit?: string, rankType?: string, firstRanksId?: string, lastRanksId?: string, archive?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rarerank/ajax_pull_rarerank_monthly`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (intDown !== undefined) { 
                localVarFormParams.append('int_down', intDown as any);
            }
    
            if (intLimit !== undefined) { 
                localVarFormParams.append('int_limit', intLimit as any);
            }
    
            if (rankType !== undefined) { 
                localVarFormParams.append('rank_type', rankType as any);
            }
    
            if (firstRanksId !== undefined) { 
                localVarFormParams.append('first_ranks_id', firstRanksId as any);
            }
    
            if (lastRanksId !== undefined) { 
                localVarFormParams.append('last_ranks_id', lastRanksId as any);
            }
    
            if (archive !== undefined) { 
                localVarFormParams.append('archive', archive as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary rarerank/ajax_pull_rarerank
         * @param {string} [intDown] 
         * @param {string} [intLimit] 
         * @param {string} [rankType] 
         * @param {string} [lastRanksId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rarerankAjaxPullRarerankPost: async (intDown?: string, intLimit?: string, rankType?: string, lastRanksId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rarerank/ajax_pull_rarerank`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (intDown !== undefined) { 
                localVarFormParams.append('int_down', intDown as any);
            }
    
            if (intLimit !== undefined) { 
                localVarFormParams.append('int_limit', intLimit as any);
            }
    
            if (rankType !== undefined) { 
                localVarFormParams.append('rank_type', rankType as any);
            }
    
            if (lastRanksId !== undefined) { 
                localVarFormParams.append('last_ranks_id', lastRanksId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary rarerank/index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rarerankIndexApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rarerank/index_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary rarerank/monthly_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rarerankMonthlyApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rarerank/monthly_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RarerankApi - functional programming interface
 * @export
 */
export const RarerankApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RarerankApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary rarerank/ajax_pull_rarerank_monthly
         * @param {string} [intDown] 
         * @param {string} [intLimit] 
         * @param {string} [rankType] 
         * @param {string} [firstRanksId] 
         * @param {string} [lastRanksId] 
         * @param {string} [archive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rarerankAjaxPullRarerankMonthlyPost(intDown?: string, intLimit?: string, rankType?: string, firstRanksId?: string, lastRanksId?: string, archive?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rarerankAjaxPullRarerankMonthlyPost(intDown, intLimit, rankType, firstRanksId, lastRanksId, archive, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary rarerank/ajax_pull_rarerank
         * @param {string} [intDown] 
         * @param {string} [intLimit] 
         * @param {string} [rankType] 
         * @param {string} [lastRanksId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rarerankAjaxPullRarerankPost(intDown?: string, intLimit?: string, rankType?: string, lastRanksId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rarerankAjaxPullRarerankPost(intDown, intLimit, rankType, lastRanksId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary rarerank/index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rarerankIndexApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rarerankIndexApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary rarerank/monthly_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rarerankMonthlyApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rarerankMonthlyApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RarerankApi - factory interface
 * @export
 */
export const RarerankApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RarerankApiFp(configuration)
    return {
        /**
         * 
         * @summary rarerank/ajax_pull_rarerank_monthly
         * @param {string} [intDown] 
         * @param {string} [intLimit] 
         * @param {string} [rankType] 
         * @param {string} [firstRanksId] 
         * @param {string} [lastRanksId] 
         * @param {string} [archive] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rarerankAjaxPullRarerankMonthlyPost(intDown?: string, intLimit?: string, rankType?: string, firstRanksId?: string, lastRanksId?: string, archive?: string, options?: any): AxiosPromise<void> {
            return localVarFp.rarerankAjaxPullRarerankMonthlyPost(intDown, intLimit, rankType, firstRanksId, lastRanksId, archive, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary rarerank/ajax_pull_rarerank
         * @param {string} [intDown] 
         * @param {string} [intLimit] 
         * @param {string} [rankType] 
         * @param {string} [lastRanksId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rarerankAjaxPullRarerankPost(intDown?: string, intLimit?: string, rankType?: string, lastRanksId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.rarerankAjaxPullRarerankPost(intDown, intLimit, rankType, lastRanksId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary rarerank/index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rarerankIndexApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.rarerankIndexApiPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary rarerank/monthly_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rarerankMonthlyApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.rarerankMonthlyApiPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RarerankApi - object-oriented interface
 * @export
 * @class RarerankApi
 * @extends {BaseAPI}
 */
export class RarerankApi extends BaseAPI {
    /**
     * 
     * @summary rarerank/ajax_pull_rarerank_monthly
     * @param {string} [intDown] 
     * @param {string} [intLimit] 
     * @param {string} [rankType] 
     * @param {string} [firstRanksId] 
     * @param {string} [lastRanksId] 
     * @param {string} [archive] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RarerankApi
     */
    public rarerankAjaxPullRarerankMonthlyPost(intDown?: string, intLimit?: string, rankType?: string, firstRanksId?: string, lastRanksId?: string, archive?: string, options?: AxiosRequestConfig) {
        return RarerankApiFp(this.configuration).rarerankAjaxPullRarerankMonthlyPost(intDown, intLimit, rankType, firstRanksId, lastRanksId, archive, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary rarerank/ajax_pull_rarerank
     * @param {string} [intDown] 
     * @param {string} [intLimit] 
     * @param {string} [rankType] 
     * @param {string} [lastRanksId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RarerankApi
     */
    public rarerankAjaxPullRarerankPost(intDown?: string, intLimit?: string, rankType?: string, lastRanksId?: string, options?: AxiosRequestConfig) {
        return RarerankApiFp(this.configuration).rarerankAjaxPullRarerankPost(intDown, intLimit, rankType, lastRanksId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary rarerank/index_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RarerankApi
     */
    public rarerankIndexApiPost(options?: AxiosRequestConfig) {
        return RarerankApiFp(this.configuration).rarerankIndexApiPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary rarerank/monthly_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RarerankApi
     */
    public rarerankMonthlyApiPost(options?: AxiosRequestConfig) {
        return RarerankApiFp(this.configuration).rarerankMonthlyApiPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SerialApi - axios parameter creator
 * @export
 */
export const SerialApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary ajax_confirm
         * @param {string} [serialcode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serialAjaxConfirmPost: async (serialcode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/serial/ajax_confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (serialcode !== undefined) { 
                localVarFormParams.append('serialcode', serialcode as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serialIndexApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/serial/index_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SerialApi - functional programming interface
 * @export
 */
export const SerialApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SerialApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary ajax_confirm
         * @param {string} [serialcode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serialAjaxConfirmPost(serialcode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serialAjaxConfirmPost(serialcode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serialIndexApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serialIndexApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SerialApi - factory interface
 * @export
 */
export const SerialApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SerialApiFp(configuration)
    return {
        /**
         * 
         * @summary ajax_confirm
         * @param {string} [serialcode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serialAjaxConfirmPost(serialcode?: string, options?: any): AxiosPromise<void> {
            return localVarFp.serialAjaxConfirmPost(serialcode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serialIndexApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.serialIndexApiPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SerialApi - object-oriented interface
 * @export
 * @class SerialApi
 * @extends {BaseAPI}
 */
export class SerialApi extends BaseAPI {
    /**
     * 
     * @summary ajax_confirm
     * @param {string} [serialcode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SerialApi
     */
    public serialAjaxConfirmPost(serialcode?: string, options?: AxiosRequestConfig) {
        return SerialApiFp(this.configuration).serialAjaxConfirmPost(serialcode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary index_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SerialApi
     */
    public serialIndexApiPost(options?: AxiosRequestConfig) {
        return SerialApiFp(this.configuration).serialIndexApiPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SettingApi - axios parameter creator
 * @export
 */
export const SettingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary setting_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/setting_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingApi - functional programming interface
 * @export
 */
export const SettingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary setting_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async settingApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.settingApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SettingApi - factory interface
 * @export
 */
export const SettingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingApiFp(configuration)
    return {
        /**
         * 
         * @summary setting_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        settingApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.settingApiPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettingApi - object-oriented interface
 * @export
 * @class SettingApi
 * @extends {BaseAPI}
 */
export class SettingApi extends BaseAPI {
    /**
     * 
     * @summary setting_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingApi
     */
    public settingApiPost(options?: AxiosRequestConfig) {
        return SettingApiFp(this.configuration).settingApiPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TopApi - axios parameter creator
 * @export
 */
export const TopApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary top/get_top_post_list
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {string} [postId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        topGetTopPostListPost: async (limit?: string, offset?: string, postId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/top/get_top_post_list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (limit !== undefined) { 
                localVarFormParams.append('limit', limit as any);
            }
    
            if (offset !== undefined) { 
                localVarFormParams.append('offset', offset as any);
            }
    
            if (postId !== undefined) { 
                localVarFormParams.append('post_id', postId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary top/index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        topIndexApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/top/index_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TopApi - functional programming interface
 * @export
 */
export const TopApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TopApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary top/get_top_post_list
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {string} [postId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async topGetTopPostListPost(limit?: string, offset?: string, postId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.topGetTopPostListPost(limit, offset, postId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary top/index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async topIndexApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.topIndexApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TopApi - factory interface
 * @export
 */
export const TopApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TopApiFp(configuration)
    return {
        /**
         * 
         * @summary top/get_top_post_list
         * @param {string} [limit] 
         * @param {string} [offset] 
         * @param {string} [postId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        topGetTopPostListPost(limit?: string, offset?: string, postId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.topGetTopPostListPost(limit, offset, postId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary top/index_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        topIndexApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.topIndexApiPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TopApi - object-oriented interface
 * @export
 * @class TopApi
 * @extends {BaseAPI}
 */
export class TopApi extends BaseAPI {
    /**
     * 
     * @summary top/get_top_post_list
     * @param {string} [limit] 
     * @param {string} [offset] 
     * @param {string} [postId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopApi
     */
    public topGetTopPostListPost(limit?: string, offset?: string, postId?: string, options?: AxiosRequestConfig) {
        return TopApiFp(this.configuration).topGetTopPostListPost(limit, offset, postId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary top/index_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TopApi
     */
    public topIndexApiPost(options?: AxiosRequestConfig) {
        return TopApiFp(this.configuration).topIndexApiPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TradeApi - axios parameter creator
 * @export
 */
export const TradeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary koukan_api
         * @param {string} [userId] 
         * @param {string} [cardId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        koukanApiPost: async (userId?: string, cardId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/koukan_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (userId !== undefined) { 
                localVarFormParams.append('user_id', userId as any);
            }
    
            if (cardId !== undefined) { 
                localVarFormParams.append('card_id', cardId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary trade/ajax_cancel_trade
         * @param {string} [tradeId] トレードID(folder_apiから取得可能)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradeAjaxCancelTradePost: async (tradeId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trade/ajax_cancel_trade`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (tradeId !== undefined) { 
                localVarFormParams.append('trade_id', tradeId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary trade/ajax_card_lock
         * @param {string} [cardId] カードID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradeAjaxCardLockPost: async (cardId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trade/ajax_card_lock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (cardId !== undefined) { 
                localVarFormParams.append('card_id', cardId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary trade/ajax_card_unlock
         * @param {string} [cardId] カードID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradeAjaxCardUnlockPost: async (cardId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trade/ajax_card_unlock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (cardId !== undefined) { 
                localVarFormParams.append('card_id', cardId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary trade/ajax_solve_trade
         * @param {string} [answer] yes or no
         * @param {string} [tradeId] トレードID(folder_apiから取得可能)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradeAjaxSolveTradePost: async (answer?: string, tradeId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trade/ajax_solve_trade`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (answer !== undefined) { 
                localVarFormParams.append('answer', answer as any);
            }
    
            if (tradeId !== undefined) { 
                localVarFormParams.append('trade_id', tradeId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary trade/ajax_trade_check_user
         * @param {string} [userId] 相手userのid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradeAjaxTradeCheckUserPost: async (userId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trade/ajax_trade_check_user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (userId !== undefined) { 
                localVarFormParams.append('user_id', userId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary trade/ajax_trade
         * @param {string} [myCardId] 自分が差し出すカードID
         * @param {string} [selectFriendId] 相手ユーザのID
         * @param {string} [selectCardId] 相手のカードID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradeAjaxTradePost: async (myCardId?: string, selectFriendId?: string, selectCardId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trade/ajax_trade`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (myCardId !== undefined) { 
                localVarFormParams.append('my_card_id', myCardId as any);
            }
    
            if (selectFriendId !== undefined) { 
                localVarFormParams.append('select_friend_id', selectFriendId as any);
            }
    
            if (selectCardId !== undefined) { 
                localVarFormParams.append('select_card_id', selectCardId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TradeApi - functional programming interface
 * @export
 */
export const TradeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TradeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary koukan_api
         * @param {string} [userId] 
         * @param {string} [cardId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async koukanApiPost(userId?: string, cardId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.koukanApiPost(userId, cardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary trade/ajax_cancel_trade
         * @param {string} [tradeId] トレードID(folder_apiから取得可能)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tradeAjaxCancelTradePost(tradeId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tradeAjaxCancelTradePost(tradeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary trade/ajax_card_lock
         * @param {string} [cardId] カードID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tradeAjaxCardLockPost(cardId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tradeAjaxCardLockPost(cardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary trade/ajax_card_unlock
         * @param {string} [cardId] カードID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tradeAjaxCardUnlockPost(cardId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tradeAjaxCardUnlockPost(cardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary trade/ajax_solve_trade
         * @param {string} [answer] yes or no
         * @param {string} [tradeId] トレードID(folder_apiから取得可能)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tradeAjaxSolveTradePost(answer?: string, tradeId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tradeAjaxSolveTradePost(answer, tradeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary trade/ajax_trade_check_user
         * @param {string} [userId] 相手userのid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tradeAjaxTradeCheckUserPost(userId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tradeAjaxTradeCheckUserPost(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary trade/ajax_trade
         * @param {string} [myCardId] 自分が差し出すカードID
         * @param {string} [selectFriendId] 相手ユーザのID
         * @param {string} [selectCardId] 相手のカードID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tradeAjaxTradePost(myCardId?: string, selectFriendId?: string, selectCardId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tradeAjaxTradePost(myCardId, selectFriendId, selectCardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TradeApi - factory interface
 * @export
 */
export const TradeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TradeApiFp(configuration)
    return {
        /**
         * 
         * @summary koukan_api
         * @param {string} [userId] 
         * @param {string} [cardId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        koukanApiPost(userId?: string, cardId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.koukanApiPost(userId, cardId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary trade/ajax_cancel_trade
         * @param {string} [tradeId] トレードID(folder_apiから取得可能)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradeAjaxCancelTradePost(tradeId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.tradeAjaxCancelTradePost(tradeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary trade/ajax_card_lock
         * @param {string} [cardId] カードID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradeAjaxCardLockPost(cardId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.tradeAjaxCardLockPost(cardId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary trade/ajax_card_unlock
         * @param {string} [cardId] カードID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradeAjaxCardUnlockPost(cardId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.tradeAjaxCardUnlockPost(cardId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary trade/ajax_solve_trade
         * @param {string} [answer] yes or no
         * @param {string} [tradeId] トレードID(folder_apiから取得可能)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradeAjaxSolveTradePost(answer?: string, tradeId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.tradeAjaxSolveTradePost(answer, tradeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary trade/ajax_trade_check_user
         * @param {string} [userId] 相手userのid
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradeAjaxTradeCheckUserPost(userId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.tradeAjaxTradeCheckUserPost(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary trade/ajax_trade
         * @param {string} [myCardId] 自分が差し出すカードID
         * @param {string} [selectFriendId] 相手ユーザのID
         * @param {string} [selectCardId] 相手のカードID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tradeAjaxTradePost(myCardId?: string, selectFriendId?: string, selectCardId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.tradeAjaxTradePost(myCardId, selectFriendId, selectCardId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TradeApi - object-oriented interface
 * @export
 * @class TradeApi
 * @extends {BaseAPI}
 */
export class TradeApi extends BaseAPI {
    /**
     * 
     * @summary koukan_api
     * @param {string} [userId] 
     * @param {string} [cardId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeApi
     */
    public koukanApiPost(userId?: string, cardId?: string, options?: AxiosRequestConfig) {
        return TradeApiFp(this.configuration).koukanApiPost(userId, cardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary trade/ajax_cancel_trade
     * @param {string} [tradeId] トレードID(folder_apiから取得可能)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeApi
     */
    public tradeAjaxCancelTradePost(tradeId?: string, options?: AxiosRequestConfig) {
        return TradeApiFp(this.configuration).tradeAjaxCancelTradePost(tradeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary trade/ajax_card_lock
     * @param {string} [cardId] カードID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeApi
     */
    public tradeAjaxCardLockPost(cardId?: string, options?: AxiosRequestConfig) {
        return TradeApiFp(this.configuration).tradeAjaxCardLockPost(cardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary trade/ajax_card_unlock
     * @param {string} [cardId] カードID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeApi
     */
    public tradeAjaxCardUnlockPost(cardId?: string, options?: AxiosRequestConfig) {
        return TradeApiFp(this.configuration).tradeAjaxCardUnlockPost(cardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary trade/ajax_solve_trade
     * @param {string} [answer] yes or no
     * @param {string} [tradeId] トレードID(folder_apiから取得可能)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeApi
     */
    public tradeAjaxSolveTradePost(answer?: string, tradeId?: string, options?: AxiosRequestConfig) {
        return TradeApiFp(this.configuration).tradeAjaxSolveTradePost(answer, tradeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary trade/ajax_trade_check_user
     * @param {string} [userId] 相手userのid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeApi
     */
    public tradeAjaxTradeCheckUserPost(userId?: string, options?: AxiosRequestConfig) {
        return TradeApiFp(this.configuration).tradeAjaxTradeCheckUserPost(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary trade/ajax_trade
     * @param {string} [myCardId] 自分が差し出すカードID
     * @param {string} [selectFriendId] 相手ユーザのID
     * @param {string} [selectCardId] 相手のカードID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeApi
     */
    public tradeAjaxTradePost(myCardId?: string, selectFriendId?: string, selectCardId?: string, options?: AxiosRequestConfig) {
        return TradeApiFp(this.configuration).tradeAjaxTradePost(myCardId, selectFriendId, selectCardId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TrophyApi - axios parameter creator
 * @export
 */
export const TrophyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary trophy_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trophyApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/trophy_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrophyApi - functional programming interface
 * @export
 */
export const TrophyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TrophyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary trophy_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async trophyApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.trophyApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TrophyApi - factory interface
 * @export
 */
export const TrophyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TrophyApiFp(configuration)
    return {
        /**
         * 
         * @summary trophy_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trophyApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.trophyApiPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TrophyApi - object-oriented interface
 * @export
 * @class TrophyApi
 * @extends {BaseAPI}
 */
export class TrophyApi extends BaseAPI {
    /**
     * 
     * @summary trophy_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrophyApi
     */
    public trophyApiPost(options?: AxiosRequestConfig) {
        return TrophyApiFp(this.configuration).trophyApiPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TutorialApi - axios parameter creator
 * @export
 */
export const TutorialApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary tutorial/navi_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tutorialNaviApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tutorial/navi_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TutorialApi - functional programming interface
 * @export
 */
export const TutorialApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TutorialApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary tutorial/navi_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tutorialNaviApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tutorialNaviApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TutorialApi - factory interface
 * @export
 */
export const TutorialApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TutorialApiFp(configuration)
    return {
        /**
         * 
         * @summary tutorial/navi_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tutorialNaviApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.tutorialNaviApiPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TutorialApi - object-oriented interface
 * @export
 * @class TutorialApi
 * @extends {BaseAPI}
 */
export class TutorialApi extends BaseAPI {
    /**
     * 
     * @summary tutorial/navi_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TutorialApi
     */
    public tutorialNaviApiPost(options?: AxiosRequestConfig) {
        return TutorialApiFp(this.configuration).tutorialNaviApiPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary api/uploadprofileimage
         * @param {string} [contentType] 
         * @param {string} [accept] 
         * @param {File} [photo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUploadprofileimagePost: async (contentType?: string, accept?: string, photo?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/uploadprofileimage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication noauthAuth required

            if (contentType != null) {
                localVarHeaderParameter['Content-Type'] = String(contentType);
            }

            if (accept != null) {
                localVarHeaderParameter['Accept'] = String(accept);
            }


            if (photo !== undefined) { 
                localVarFormParams.append('photo', photo as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary user/ajax_generate_pin_for_leave
         * @param {string} [userId] 
         * @param {string} [blockId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAjaxGeneratePinForLeavePost: async (userId?: string, blockId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/ajax_generate_pin_for_leave`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (userId !== undefined) { 
                localVarFormParams.append('user_id', userId as any);
            }
    
            if (blockId !== undefined) { 
                localVarFormParams.append('block_id', blockId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary user/ajax_link_app_confirm
         * @param {string} [email] 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAjaxLinkAppConfirmPost: async (email?: string, password?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/ajax_link_app_confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (email !== undefined) { 
                localVarFormParams.append('email', email as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.append('password', password as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary user/ajax_link_app_execute
         * @param {string} [pinCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAjaxLinkAppExecutePost: async (pinCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/ajax_link_app_execute`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (pinCode !== undefined) { 
                localVarFormParams.append('pin_code', pinCode as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary user/ajax_link_nft_execute
         * @param {string} [pinCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAjaxLinkNftExecutePost: async (pinCode?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/ajax_link_nft_execute`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (pinCode !== undefined) { 
                localVarFormParams.append('pin_code', pinCode as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary user/ajax_save_profile_background
         * @param {string} [profileBackgroundId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAjaxSaveProfileBackgroundPost: async (profileBackgroundId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/ajax_save_profile_background`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (profileBackgroundId !== undefined) { 
                localVarFormParams.append('profile_background_id', profileBackgroundId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary user/ajax_save_profile_front
         * @param {string} [nickname] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAjaxSaveProfileFrontPost: async (nickname?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/ajax_save_profile_front`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (nickname !== undefined) { 
                localVarFormParams.append('nickname', nickname as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary user/ajax_save_profile_title
         * @param {string} [profileTitleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAjaxSaveProfileTitlePost: async (profileTitleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/ajax_save_profile_title`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (profileTitleId !== undefined) { 
                localVarFormParams.append('profile_title_id', profileTitleId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary user/ajax_unblock
         * @param {string} [userId] 
         * @param {string} [blockId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAjaxUnblockPost: async (userId?: string, blockId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/ajax_unblock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (userId !== undefined) { 
                localVarFormParams.append('user_id', userId as any);
            }
    
            if (blockId !== undefined) { 
                localVarFormParams.append('block_id', blockId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary user/ajax_user_card_meta
         * @param {string} [userId] 
         * @param {string} [cardId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAjaxUserCardMetaPost: async (userId?: string, cardId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/ajax_user_card_meta`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (userId !== undefined) { 
                localVarFormParams.append('user_id', userId as any);
            }
    
            if (cardId !== undefined) { 
                localVarFormParams.append('card_id', cardId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary user/crystal_purchase_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCrystalPurchaseApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/crystal_purchase_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary user/get_csrf_token_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetCsrfTokenApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/get_csrf_token_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary user/link_app_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userLinkAppApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/link_app_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary user/link_nft_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userLinkNftApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/link_nft_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary user/logout_api
         * @param {number} [uuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userLogoutApiPost: async (uuid?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/logout_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (uuid !== undefined) { 
                localVarFormParams.append('uuid', uuid as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary user/register_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRegisterApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/register_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary user/register_status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRegisterStatusPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/register_status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary user/save_profile
         * @param {string} [xCsrfToken] GET user/get_csrf_tokenから取得した値を入れる
         * @param {string} [profile] 自己紹介
         * @param {number} [nickname] ニックネーム(12文字まで)
         * @param {number} [sex] 性別(0&#x3D;男, 1&#x3D;女)
         * @param {number} [sexAccess] 性別公開(0&#x3D;非公開, 1&#x3D;公開)
         * @param {number} [hometown] 出身地(1&#x3D;北海道...)
         * @param {number} [hometownAccess] 出身地公開(0&#x3D;非公開, 1&#x3D;公開)
         * @param {string} [favoritePlayer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSaveProfilePost: async (xCsrfToken?: string, profile?: string, nickname?: number, sex?: number, sexAccess?: number, hometown?: number, hometownAccess?: number, favoritePlayer?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/save_profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (xCsrfToken != null) {
                localVarHeaderParameter['x-csrf-token'] = String(xCsrfToken);
            }


            if (profile !== undefined) { 
                localVarFormParams.append('profile', profile as any);
            }
    
            if (nickname !== undefined) { 
                localVarFormParams.append('nickname', nickname as any);
            }
    
            if (sex !== undefined) { 
                localVarFormParams.append('sex', sex as any);
            }
    
            if (sexAccess !== undefined) { 
                localVarFormParams.append('sex_access', sexAccess as any);
            }
    
            if (hometown !== undefined) { 
                localVarFormParams.append('hometown', hometown as any);
            }
    
            if (hometownAccess !== undefined) { 
                localVarFormParams.append('hometown_access', hometownAccess as any);
            }
    
            if (favoritePlayer !== undefined) { 
                localVarFormParams.append('favorite_player', favoritePlayer as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary user/user_info_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserInfoApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/user_info_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary api/uploadprofileimage
         * @param {string} [contentType] 
         * @param {string} [accept] 
         * @param {File} [photo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUploadprofileimagePost(contentType?: string, accept?: string, photo?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUploadprofileimagePost(contentType, accept, photo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary user/ajax_generate_pin_for_leave
         * @param {string} [userId] 
         * @param {string} [blockId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAjaxGeneratePinForLeavePost(userId?: string, blockId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAjaxGeneratePinForLeavePost(userId, blockId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary user/ajax_link_app_confirm
         * @param {string} [email] 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAjaxLinkAppConfirmPost(email?: string, password?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAjaxLinkAppConfirmPost(email, password, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary user/ajax_link_app_execute
         * @param {string} [pinCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAjaxLinkAppExecutePost(pinCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAjaxLinkAppExecutePost(pinCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary user/ajax_link_nft_execute
         * @param {string} [pinCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAjaxLinkNftExecutePost(pinCode?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAjaxLinkNftExecutePost(pinCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary user/ajax_save_profile_background
         * @param {string} [profileBackgroundId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAjaxSaveProfileBackgroundPost(profileBackgroundId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAjaxSaveProfileBackgroundPost(profileBackgroundId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary user/ajax_save_profile_front
         * @param {string} [nickname] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAjaxSaveProfileFrontPost(nickname?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAjaxSaveProfileFrontPost(nickname, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary user/ajax_save_profile_title
         * @param {string} [profileTitleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAjaxSaveProfileTitlePost(profileTitleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAjaxSaveProfileTitlePost(profileTitleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary user/ajax_unblock
         * @param {string} [userId] 
         * @param {string} [blockId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAjaxUnblockPost(userId?: string, blockId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAjaxUnblockPost(userId, blockId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary user/ajax_user_card_meta
         * @param {string} [userId] 
         * @param {string} [cardId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userAjaxUserCardMetaPost(userId?: string, cardId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userAjaxUserCardMetaPost(userId, cardId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary user/crystal_purchase_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userCrystalPurchaseApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userCrystalPurchaseApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary user/get_csrf_token_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetCsrfTokenApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGetCsrfTokenApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary user/link_app_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userLinkAppApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userLinkAppApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary user/link_nft_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userLinkNftApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userLinkNftApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary user/logout_api
         * @param {number} [uuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userLogoutApiPost(uuid?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userLogoutApiPost(uuid, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary user/register_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRegisterApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userRegisterApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary user/register_status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userRegisterStatusPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userRegisterStatusPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary user/save_profile
         * @param {string} [xCsrfToken] GET user/get_csrf_tokenから取得した値を入れる
         * @param {string} [profile] 自己紹介
         * @param {number} [nickname] ニックネーム(12文字まで)
         * @param {number} [sex] 性別(0&#x3D;男, 1&#x3D;女)
         * @param {number} [sexAccess] 性別公開(0&#x3D;非公開, 1&#x3D;公開)
         * @param {number} [hometown] 出身地(1&#x3D;北海道...)
         * @param {number} [hometownAccess] 出身地公開(0&#x3D;非公開, 1&#x3D;公開)
         * @param {string} [favoritePlayer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSaveProfilePost(xCsrfToken?: string, profile?: string, nickname?: number, sex?: number, sexAccess?: number, hometown?: number, hometownAccess?: number, favoritePlayer?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userSaveProfilePost(xCsrfToken, profile, nickname, sex, sexAccess, hometown, hometownAccess, favoritePlayer, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary user/user_info_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userUserInfoApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userUserInfoApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary api/uploadprofileimage
         * @param {string} [contentType] 
         * @param {string} [accept] 
         * @param {File} [photo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUploadprofileimagePost(contentType?: string, accept?: string, photo?: File, options?: any): AxiosPromise<void> {
            return localVarFp.apiUploadprofileimagePost(contentType, accept, photo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary user/ajax_generate_pin_for_leave
         * @param {string} [userId] 
         * @param {string} [blockId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAjaxGeneratePinForLeavePost(userId?: string, blockId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.userAjaxGeneratePinForLeavePost(userId, blockId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary user/ajax_link_app_confirm
         * @param {string} [email] 
         * @param {string} [password] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAjaxLinkAppConfirmPost(email?: string, password?: string, options?: any): AxiosPromise<void> {
            return localVarFp.userAjaxLinkAppConfirmPost(email, password, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary user/ajax_link_app_execute
         * @param {string} [pinCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAjaxLinkAppExecutePost(pinCode?: string, options?: any): AxiosPromise<void> {
            return localVarFp.userAjaxLinkAppExecutePost(pinCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary user/ajax_link_nft_execute
         * @param {string} [pinCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAjaxLinkNftExecutePost(pinCode?: string, options?: any): AxiosPromise<void> {
            return localVarFp.userAjaxLinkNftExecutePost(pinCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary user/ajax_save_profile_background
         * @param {string} [profileBackgroundId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAjaxSaveProfileBackgroundPost(profileBackgroundId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.userAjaxSaveProfileBackgroundPost(profileBackgroundId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary user/ajax_save_profile_front
         * @param {string} [nickname] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAjaxSaveProfileFrontPost(nickname?: string, options?: any): AxiosPromise<void> {
            return localVarFp.userAjaxSaveProfileFrontPost(nickname, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary user/ajax_save_profile_title
         * @param {string} [profileTitleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAjaxSaveProfileTitlePost(profileTitleId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.userAjaxSaveProfileTitlePost(profileTitleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary user/ajax_unblock
         * @param {string} [userId] 
         * @param {string} [blockId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAjaxUnblockPost(userId?: string, blockId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.userAjaxUnblockPost(userId, blockId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary user/ajax_user_card_meta
         * @param {string} [userId] 
         * @param {string} [cardId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userAjaxUserCardMetaPost(userId?: string, cardId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.userAjaxUserCardMetaPost(userId, cardId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary user/crystal_purchase_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userCrystalPurchaseApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.userCrystalPurchaseApiPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary user/get_csrf_token_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetCsrfTokenApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.userGetCsrfTokenApiPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary user/link_app_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userLinkAppApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.userLinkAppApiPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary user/link_nft_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userLinkNftApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.userLinkNftApiPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary user/logout_api
         * @param {number} [uuid] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userLogoutApiPost(uuid?: number, options?: any): AxiosPromise<void> {
            return localVarFp.userLogoutApiPost(uuid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary user/register_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRegisterApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.userRegisterApiPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary user/register_status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userRegisterStatusPost(options?: any): AxiosPromise<void> {
            return localVarFp.userRegisterStatusPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary user/save_profile
         * @param {string} [xCsrfToken] GET user/get_csrf_tokenから取得した値を入れる
         * @param {string} [profile] 自己紹介
         * @param {number} [nickname] ニックネーム(12文字まで)
         * @param {number} [sex] 性別(0&#x3D;男, 1&#x3D;女)
         * @param {number} [sexAccess] 性別公開(0&#x3D;非公開, 1&#x3D;公開)
         * @param {number} [hometown] 出身地(1&#x3D;北海道...)
         * @param {number} [hometownAccess] 出身地公開(0&#x3D;非公開, 1&#x3D;公開)
         * @param {string} [favoritePlayer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSaveProfilePost(xCsrfToken?: string, profile?: string, nickname?: number, sex?: number, sexAccess?: number, hometown?: number, hometownAccess?: number, favoritePlayer?: string, options?: any): AxiosPromise<void> {
            return localVarFp.userSaveProfilePost(xCsrfToken, profile, nickname, sex, sexAccess, hometown, hometownAccess, favoritePlayer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary user/user_info_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUserInfoApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.userUserInfoApiPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary api/uploadprofileimage
     * @param {string} [contentType] 
     * @param {string} [accept] 
     * @param {File} [photo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUploadprofileimagePost(contentType?: string, accept?: string, photo?: File, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUploadprofileimagePost(contentType, accept, photo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary user/ajax_generate_pin_for_leave
     * @param {string} [userId] 
     * @param {string} [blockId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userAjaxGeneratePinForLeavePost(userId?: string, blockId?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userAjaxGeneratePinForLeavePost(userId, blockId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary user/ajax_link_app_confirm
     * @param {string} [email] 
     * @param {string} [password] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userAjaxLinkAppConfirmPost(email?: string, password?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userAjaxLinkAppConfirmPost(email, password, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary user/ajax_link_app_execute
     * @param {string} [pinCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userAjaxLinkAppExecutePost(pinCode?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userAjaxLinkAppExecutePost(pinCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary user/ajax_link_nft_execute
     * @param {string} [pinCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userAjaxLinkNftExecutePost(pinCode?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userAjaxLinkNftExecutePost(pinCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary user/ajax_save_profile_background
     * @param {string} [profileBackgroundId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userAjaxSaveProfileBackgroundPost(profileBackgroundId?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userAjaxSaveProfileBackgroundPost(profileBackgroundId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary user/ajax_save_profile_front
     * @param {string} [nickname] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userAjaxSaveProfileFrontPost(nickname?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userAjaxSaveProfileFrontPost(nickname, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary user/ajax_save_profile_title
     * @param {string} [profileTitleId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userAjaxSaveProfileTitlePost(profileTitleId?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userAjaxSaveProfileTitlePost(profileTitleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary user/ajax_unblock
     * @param {string} [userId] 
     * @param {string} [blockId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userAjaxUnblockPost(userId?: string, blockId?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userAjaxUnblockPost(userId, blockId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary user/ajax_user_card_meta
     * @param {string} [userId] 
     * @param {string} [cardId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userAjaxUserCardMetaPost(userId?: string, cardId?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userAjaxUserCardMetaPost(userId, cardId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary user/crystal_purchase_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userCrystalPurchaseApiPost(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userCrystalPurchaseApiPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary user/get_csrf_token_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGetCsrfTokenApiPost(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userGetCsrfTokenApiPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary user/link_app_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userLinkAppApiPost(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userLinkAppApiPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary user/link_nft_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userLinkNftApiPost(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userLinkNftApiPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary user/logout_api
     * @param {number} [uuid] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userLogoutApiPost(uuid?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userLogoutApiPost(uuid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary user/register_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userRegisterApiPost(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userRegisterApiPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary user/register_status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userRegisterStatusPost(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userRegisterStatusPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary user/save_profile
     * @param {string} [xCsrfToken] GET user/get_csrf_tokenから取得した値を入れる
     * @param {string} [profile] 自己紹介
     * @param {number} [nickname] ニックネーム(12文字まで)
     * @param {number} [sex] 性別(0&#x3D;男, 1&#x3D;女)
     * @param {number} [sexAccess] 性別公開(0&#x3D;非公開, 1&#x3D;公開)
     * @param {number} [hometown] 出身地(1&#x3D;北海道...)
     * @param {number} [hometownAccess] 出身地公開(0&#x3D;非公開, 1&#x3D;公開)
     * @param {string} [favoritePlayer] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userSaveProfilePost(xCsrfToken?: string, profile?: string, nickname?: number, sex?: number, sexAccess?: number, hometown?: number, hometownAccess?: number, favoritePlayer?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userSaveProfilePost(xCsrfToken, profile, nickname, sex, sexAccess, hometown, hometownAccess, favoritePlayer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary user/user_info_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userUserInfoApiPost(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).userUserInfoApiPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserPolicyApi - axios parameter creator
 * @export
 */
export const UserPolicyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary user_policy/act_on_settlement_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPolicyActOnSettlementApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user_policy/act_on_settlement_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary user_policy/ajax_is_mainte
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPolicyAjaxIsMaintePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user_policy/ajax_is_mainte`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary user_policy/tokusho_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPolicyTokushoApiPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user_policy/tokusho_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserPolicyApi - functional programming interface
 * @export
 */
export const UserPolicyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserPolicyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary user_policy/act_on_settlement_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPolicyActOnSettlementApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPolicyActOnSettlementApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary user_policy/ajax_is_mainte
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPolicyAjaxIsMaintePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPolicyAjaxIsMaintePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary user_policy/tokusho_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userPolicyTokushoApiPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userPolicyTokushoApiPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserPolicyApi - factory interface
 * @export
 */
export const UserPolicyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserPolicyApiFp(configuration)
    return {
        /**
         * 
         * @summary user_policy/act_on_settlement_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPolicyActOnSettlementApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.userPolicyActOnSettlementApiPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary user_policy/ajax_is_mainte
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPolicyAjaxIsMaintePost(options?: any): AxiosPromise<void> {
            return localVarFp.userPolicyAjaxIsMaintePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary user_policy/tokusho_api
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userPolicyTokushoApiPost(options?: any): AxiosPromise<void> {
            return localVarFp.userPolicyTokushoApiPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserPolicyApi - object-oriented interface
 * @export
 * @class UserPolicyApi
 * @extends {BaseAPI}
 */
export class UserPolicyApi extends BaseAPI {
    /**
     * 
     * @summary user_policy/act_on_settlement_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPolicyApi
     */
    public userPolicyActOnSettlementApiPost(options?: AxiosRequestConfig) {
        return UserPolicyApiFp(this.configuration).userPolicyActOnSettlementApiPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary user_policy/ajax_is_mainte
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPolicyApi
     */
    public userPolicyAjaxIsMaintePost(options?: AxiosRequestConfig) {
        return UserPolicyApiFp(this.configuration).userPolicyAjaxIsMaintePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary user_policy/tokusho_api
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserPolicyApi
     */
    public userPolicyTokushoApiPost(options?: AxiosRequestConfig) {
        return UserPolicyApiFp(this.configuration).userPolicyTokushoApiPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * Valentine2021VoteApi - axios parameter creator
 * @export
 */
export const Valentine2021VoteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary valentine2021_vote/ajax_vote_api
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        valentine2021VoteAjaxVoteApiPost: async (body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/valentine2021_vote/ajax_vote_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary valentine2021_vote/index_api
         * @param {string} [userAgent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        valentine2021VoteIndexApiPost: async (userAgent?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/valentine2021_vote/index_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (userAgent != null) {
                localVarHeaderParameter['User-Agent'] = String(userAgent);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Valentine2021VoteApi - functional programming interface
 * @export
 */
export const Valentine2021VoteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = Valentine2021VoteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary valentine2021_vote/ajax_vote_api
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async valentine2021VoteAjaxVoteApiPost(body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.valentine2021VoteAjaxVoteApiPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary valentine2021_vote/index_api
         * @param {string} [userAgent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async valentine2021VoteIndexApiPost(userAgent?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.valentine2021VoteIndexApiPost(userAgent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * Valentine2021VoteApi - factory interface
 * @export
 */
export const Valentine2021VoteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = Valentine2021VoteApiFp(configuration)
    return {
        /**
         * 
         * @summary valentine2021_vote/ajax_vote_api
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        valentine2021VoteAjaxVoteApiPost(body?: object, options?: any): AxiosPromise<void> {
            return localVarFp.valentine2021VoteAjaxVoteApiPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary valentine2021_vote/index_api
         * @param {string} [userAgent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        valentine2021VoteIndexApiPost(userAgent?: string, options?: any): AxiosPromise<void> {
            return localVarFp.valentine2021VoteIndexApiPost(userAgent, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Valentine2021VoteApi - object-oriented interface
 * @export
 * @class Valentine2021VoteApi
 * @extends {BaseAPI}
 */
export class Valentine2021VoteApi extends BaseAPI {
    /**
     * 
     * @summary valentine2021_vote/ajax_vote_api
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Valentine2021VoteApi
     */
    public valentine2021VoteAjaxVoteApiPost(body?: object, options?: AxiosRequestConfig) {
        return Valentine2021VoteApiFp(this.configuration).valentine2021VoteAjaxVoteApiPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary valentine2021_vote/index_api
     * @param {string} [userAgent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Valentine2021VoteApi
     */
    public valentine2021VoteIndexApiPost(userAgent?: string, options?: AxiosRequestConfig) {
        return Valentine2021VoteApiFp(this.configuration).valentine2021VoteIndexApiPost(userAgent, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ValentineVoteApi - axios parameter creator
 * @export
 */
export const ValentineVoteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary valentine_vote/ajax_vote_api
         * @param {string} [cardId] 
         * @param {string} [deckId] 
         * @param {string} [voteIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        valentineVoteAjaxVoteApiPost: async (cardId?: string, deckId?: string, voteIndex?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/valentine_vote/ajax_vote_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)


            if (cardId !== undefined) { 
                localVarFormParams.append('card_id', cardId as any);
            }
    
            if (deckId !== undefined) { 
                localVarFormParams.append('deck_id', deckId as any);
            }
    
            if (voteIndex !== undefined) { 
                localVarFormParams.append('vote_index', voteIndex as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary valentine_vote/index_api
         * @param {string} [targetYear] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        valentineVoteIndexApiPost: async (targetYear?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/valentine_vote/index_api`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicAuth required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration)

            if (targetYear !== undefined) {
                localVarQueryParameter['target_year'] = targetYear;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ValentineVoteApi - functional programming interface
 * @export
 */
export const ValentineVoteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ValentineVoteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary valentine_vote/ajax_vote_api
         * @param {string} [cardId] 
         * @param {string} [deckId] 
         * @param {string} [voteIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async valentineVoteAjaxVoteApiPost(cardId?: string, deckId?: string, voteIndex?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.valentineVoteAjaxVoteApiPost(cardId, deckId, voteIndex, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary valentine_vote/index_api
         * @param {string} [targetYear] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async valentineVoteIndexApiPost(targetYear?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.valentineVoteIndexApiPost(targetYear, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ValentineVoteApi - factory interface
 * @export
 */
export const ValentineVoteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ValentineVoteApiFp(configuration)
    return {
        /**
         * 
         * @summary valentine_vote/ajax_vote_api
         * @param {string} [cardId] 
         * @param {string} [deckId] 
         * @param {string} [voteIndex] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        valentineVoteAjaxVoteApiPost(cardId?: string, deckId?: string, voteIndex?: string, options?: any): AxiosPromise<void> {
            return localVarFp.valentineVoteAjaxVoteApiPost(cardId, deckId, voteIndex, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary valentine_vote/index_api
         * @param {string} [targetYear] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        valentineVoteIndexApiPost(targetYear?: string, options?: any): AxiosPromise<void> {
            return localVarFp.valentineVoteIndexApiPost(targetYear, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ValentineVoteApi - object-oriented interface
 * @export
 * @class ValentineVoteApi
 * @extends {BaseAPI}
 */
export class ValentineVoteApi extends BaseAPI {
    /**
     * 
     * @summary valentine_vote/ajax_vote_api
     * @param {string} [cardId] 
     * @param {string} [deckId] 
     * @param {string} [voteIndex] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ValentineVoteApi
     */
    public valentineVoteAjaxVoteApiPost(cardId?: string, deckId?: string, voteIndex?: string, options?: AxiosRequestConfig) {
        return ValentineVoteApiFp(this.configuration).valentineVoteAjaxVoteApiPost(cardId, deckId, voteIndex, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary valentine_vote/index_api
     * @param {string} [targetYear] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ValentineVoteApi
     */
    public valentineVoteIndexApiPost(targetYear?: string, options?: AxiosRequestConfig) {
        return ValentineVoteApiFp(this.configuration).valentineVoteIndexApiPost(targetYear, options).then((request) => request(this.axios, this.basePath));
    }
}


