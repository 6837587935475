import { Configuration } from "../../plugins/openapi/configuration";
const runtimeConfig = useRuntimeConfig();

interface ConfigurationBuilderParams {
  basic_auth_username: string;
  basic_auth_password: string;
}

export default class ConfigurationBuilder {
  private readonly basic_auth_username: string;
  private readonly basic_auth_password: string;
  private readonly DEFAULT_BASE_PATH: string = runtimeConfig.public.FRONT_API_URL!.replace(/\/+$/, "");

  constructor(param: ConfigurationBuilderParams = {
    // @todo DA06003-211 環境変数を取得できなかった際にはエラーを吐くようにしたい
    basic_auth_username: process.env.BASIC_AUTH_USERNAME!,
    basic_auth_password: process.env.BASIC_AUTH_PASSWORD!,
  }) {
    this.basic_auth_username = param.basic_auth_username;
    this.basic_auth_password = param.basic_auth_password;
  }

  buildDefault(): Configuration {
    const config = new Configuration({
      username: this.basic_auth_username,
      password: this.basic_auth_password,
      basePath: this.DEFAULT_BASE_PATH,
      baseOptions: {withCredentials:true}
    });

    /**
     * @todo DA06003-381 Configurationオブジェクトの生成時にrejectedFuncの値を指定するようにして、オブジェクト生成後にプロパティを修正するようなmutableな実装は避ける。
     */
    this.setRejectedFunc(config);
    const token = localStorage.getItem("auth._token.local"); // keyはnuxt.config.jsのauth.strategiesと合わせること
    this.setAuthorization(config, token);

    return config;
  }

  private setRejectedFunc(config: Configuration): void {
    // ConfigurationにrejectedFuncが無くなっている
    // config.rejectedFunc = (error) => {
    //   console.error(error);
    //   return error;
    // };
  }

  /**
   * 共通ヘッダのAuthorizationにtoken設定
   * clearの場合、token:null, force:trueに
   * @param token
   * @param force
   */
  private setAuthorization(
    config: Configuration,
    token: string | null,
    force: boolean = false
  ) {
    if (!token) {
      if (
        force &&
        config.baseOptions &&
        config.baseOptions.headers
      ) {
        delete config.baseOptions.headers.Authorization;
      }
    } else {
      if (!config.baseOptions) {
        config.baseOptions = {};
      }
      if (!config.baseOptions.headers) {
        config.baseOptions.headers = {};
      }
      if (!config.baseOptions.headers) {
        config.baseOptions.headers = {};
      }
      config.baseOptions.headers.Authorization = token;
    }
  }
}